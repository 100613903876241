import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import theme from "../Theme";
import { ThemeProvider } from "@mui/material";

function CustomPopUpForm({ open, onClose, title, children, iconButtons }) {
  let content, action;

  if (React.Children.count(children) > 1) {
    [content, action] = children;
  } else {
    content = children;
  }
  const renderIconButtons = () =>
    iconButtons
      ? iconButtons.map((iconButton, index) => (
          <IconButton
            key={index}
            onClick={iconButton.handleClick}
            sx={{ position: "absolute", top: 5, right: (index + 1) * 40 }}
          >
            <Tooltip arrow title={iconButton.tooltip}>
              {iconButton.icon}
            </Tooltip>
          </IconButton>
        ))
      : null;
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          <Typography
            style={{
              color: theme.palette.primary.main,
              fontWeight: "bold",
              fontSize: "25px",
              display: "flex",
              justifyContent: "center",
            }}
            className="text-center"
          >
            {title}
          </Typography>
          <IconButton
            sx={{ position: "absolute", top: 5, right: 5 }}
            onClick={onClose}
          >
            <Tooltip arrow title="Close">
              <CloseIcon />
            </Tooltip>
          </IconButton>
          {iconButtons && renderIconButtons()}
        </DialogTitle>
        <DialogContent className="dialog-content-material" style={{ overflow: "visible" }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr",
              gap: "1rem",
              paddingTop: "0.5rem",
    
            }}
          >
            {content}
          </div>
        </DialogContent>
        {action && (
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {action}
          </DialogActions>
        )}
      </Dialog>
    </ThemeProvider>
  );
}

export default CustomPopUpForm;
