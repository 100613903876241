import React from "react";
import theme from "../Theme";
import "./LeftRegistrationFormComponent.css"; // Import a CSS file for styling

const listStyle = {
  listStyle: "none",
  paddingLeft: "0",
};

const blueDashStyle = {
  color: theme.palette.grants.secondary,
  marginRight: "8px", // Adjust the spacing between the dash and the text
};

const liStyle = {
  marginBottom: "16px", // Adjust the white space between li elements
};

function LeftRegistrationFormComponent({ mainHeading, listItems, image }) {
  return (
    <div className="registration-form-container">
      <img
        src={image}
        alt="Logo"
        className="registration-form-image"
      />
      <h1>{mainHeading}</h1>
      <ul style={listStyle}>
        {listItems.map((item, index) => (
          <li key={index} style={liStyle}>
            <span style={blueDashStyle}>&#8211;</span> {item}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default LeftRegistrationFormComponent;
