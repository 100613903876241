import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { v4 as uuid } from "uuid";

function DesktopPagesMenu({ pages, pagesPath }) {
 

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    localStorage.clear(); 
    navigate(path); 
  };

  return (
    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
      {pages.map((page, index) => (
        <Link
          key={uuid()}
          to={pagesPath[index]}
          style={{ textDecoration: "none" }}
          onClick={() => handleNavigation(pagesPath[index])}
        >
          <Button key={page} sx={{ my: 2, color: "white", display: "block" }}>
            {page}
          </Button>
        </Link>
      ))}
    </Box>
  );
}
export default DesktopPagesMenu;
