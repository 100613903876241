import React from "react";
import HeaderComponentCityOfLA from "../../cityOfLAComponents/HeaderComponentCityOfLA";
import RegistrationFormCityOfLA from "../../cityOfLAComponents/RegistrationFormCityOfLA";
function CityOfLARegistrationPage() {
  return (
    <div style={{ height: "100%" }}>
      <HeaderComponentCityOfLA />
      <RegistrationFormCityOfLA />
      {/* <Footer /> */}
    </div>
  );
}

export default CityOfLARegistrationPage;
