import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 830,
      lg: 1280,
      lm: 1350,
      xl: 1920,
    },
  },
  palette: {
    mode: "light",
    primary: {
      dark: window.palette.primary.dark,
      main: window.palette.primary.main,
      light: window.palette.primary.light,
      lightest: window.palette.primary.lightest,
    },
    secondary: {
      dark: window.palette.secondary.dark,
      main: window.palette.secondary.main,
      light: window.palette.secondary.light,
      lightest: window.palette.secondary.lightest,
    },
    background: {
      default: window.palette.background.default,
      blueishGray: window.palette.background.blueishGray,
    },
    white: {
      main: window.palette.white.main,
      dark: window.palette.white.dark,
    },
    black: {
      main: window.palette.black.main,
      light: window.palette.black.light,
    },
    gray: {
      main: window.palette.gray.main,
      light: window.palette.gray.light,
      dark: window.palette.gray.dark,
    },
    landingBg: {
      light: window.palette.landingBg.light,
      dark: window.palette.landingBg.dark,
      copyright: window.palette.landingBg.copyright,
    },
    successColor: {
      bg: window.palette.successColor.bg,
      content: window.palette.successColor.content,
    },
    warningColor: {
      bg: window.palette.warningColor.bg,
      content: window.palette.warningColor.content,
    },
    errorColor: {
      bg: window.palette.errorColor.bg,
      content: window.palette.errorColor.content,
    },
    infoColor: {
      bg: window.palette.infoColor.bg,
      content: window.palette.infoColor.content,
    },
    grants: {
      primary: window.palette.grants.primary,
      secondary: window.palette.grants.secondary,
      footerSecondary: window.palette.grants.footerSecondary,
    },
    cityLA: {
      primary: window.palette.cityLA.primary,
      secondary: window.palette.cityLA.secondary,
      secondaryHighlighted: window.palette.cityLA.secondaryHighlighted,
      textColor: window.palette.cityLA.textColor,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: window.navBar.backgroundColor,
          opacity: window.navBar.opacity,
        },
      },
    },
  },
});

export default theme;
