//Format date from Tue Jul 18 2023 11:42:21 GMT-0700
// to YYYY-MM-DD
export function getFormattedDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

// Format date from YYYY-MM-DD to MMM, DD format (e.g., Aug, 08)
export const formatDate = (date) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [year, month, day] = date.split("-");
  const monthIndex = parseInt(month, 10) - 1;
  const formattedMonth = months[monthIndex];
  const formattedDay = day.padStart(2, "0");
  return `${formattedMonth}, ${formattedDay}`;
};

// Format date from YYYY-MM-DD to Day,MMM, DD format (e.g., "Mon, Aug 7")
export const stateToDateCarrousel = (dateString) => {
  const options = { weekday: "short", month: "short", day: "numeric" };
  const date = new Date(`${dateString}T00:00:00Z`);
  const timeZoneOffset = date.getTimezoneOffset() * 60 * 1000; // in milliseconds
  const adjustedDate = new Date(date.getTime() + timeZoneOffset);
  return adjustedDate.toLocaleDateString("en-US", options);
};

// Format date from Day,MMM, DD format (e.g., "Mon, Aug 7") to YYYY-MM-DD
export const dateCarrouselToState = (dateString) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const parts = dateString.split(", ");
  const monthDay = parts[1].split(" ");
  const month = months.indexOf(monthDay[0]) + 1;
  const day = monthDay[1];
  const year = new Date().getFullYear();

  const formattedMonth = month.toString().padStart(2, "0");
  const formattedDay = day.toString().padStart(2, "0");

  return `${year}-${formattedMonth}-${formattedDay}`;
};

//Change Date Formating From YYYY-MM-DD To EX:Thu Aug 17 2023 00:00:00 GMT-0700 (Pacific Daylight Time)
export function transformDate(dateString) {
  const dateParts = dateString.split("-"); // Split the string into an array of parts
  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1; // Month is zero-based in JavaScript (0 - 11)
  const day = parseInt(dateParts[2]);

  const formattedDate = new Date(year, month, day);

  return formattedDate;
}

// Helper function to get the current date in YYYY-MM-DD format
export const getCurrentDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
