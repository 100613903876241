import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WarningBox from "./components/infoComponents/warning/WarningBox";
import { Button } from "@mui/material";
import styled from "styled-components";
const TripMessage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { messages, messageType } = location.state || {};
  const record = JSON.parse(localStorage.getItem("record"));

  const handleBackClick = () => {
    navigate("/");
  };

  const Wrapper = styled.div`
  width: 100%;
`;
  return (
    <>
    <Wrapper>
      <div>
        {messages ? (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            {record.roundTrip === true ? (
              <WarningBox message={messages.roundTripMessage} type={1} />
            ) : (
              <WarningBox message={messages.oneWayMessage} type={1} />
            )}
          </div>
        ) : (
          <h1>No message received</h1>
        )}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          position: "absolute",
        }}
      >
        <Button
          variant="outlined"
          onClick={handleBackClick}
          fullWidth
          style={{ maxWidth: "200px" }}
        >
          BACK
        </Button>
      </div>
      </Wrapper>
    </>
  );
};

export default TripMessage;
