import React from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import theme from "../../Theme";
import useNavItemStyles from "./SecondNavBarStyle";
import { useMediaQuery } from "@mui/material";
import { formatDate } from "../../functions/DateManipulationFunctions";

const MobileSecondNavBar = ({ state }) => {
  const initialRecord = {
    roundTrip: "",
    oneWay: "",
    from: "",
    fromName: "",
    to: "",
    toName: "",
    depart: "",
    return: "",
    passenger: "",
    TGUID: "",
    request: "",
    routeID: "",
    returnRouteID: "",
    tempID: "",
    returnTempID: "",
    tripID: "",
    returnTripID: "",
    seatType: "",
    seatTypeReturn: "",
    seatPrice: "",
    seatPriceReturn: "",
    terminalFees: "",
    terminalFeesReturn: "",
    otherFees: "",
    otherFeesReturn: "",
    other: "",
    otherReturn: "",
    acmID: "",
    acmIDReturn: "",
  };

  const record = JSON.parse(localStorage.getItem("record")) || initialRecord;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const styles = useNavItemStyles(isSmallScreen);
  const modifyLink = `/BookTicket?origin=${
    state.from || record.from
  }&destination=${state.to || record.to}&request=passenger&departDate=${
    state.depart || record.depart
  }&returnDate=${state.return || record.return}&request=${
    state.request || record.request
  }`;

  return (
    <AppBar
      position="fixed"
      // color="default"
      elevation={0}
      sx={styles.mobileBarStyle}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <div style={styles.MobileContainerDiv}>
            <div style={styles.mobileValueStyle}>
              {state.fromName || record.fromName}
            </div>
            <ArrowForwardIcon fontSize="small" color="primary" />
            <div style={styles.mobileValueStyle}>
              {state.toName || record.toName}
            </div>
          </div>

          <div style={styles.MobileContainerDiv}>
            <div style={styles.mobileDateStyle}>
              {formatDate(state.depart || record.depart)}
            </div>
            {(state.roundTrip || record.roundTrip) && (
              <div style={styles.mobileDateStyle}>
                {formatDate(state.return || record.return)}
              </div>
            )}

            <PersonIcon
              fontSize="small"
              style={{ marginLeft: "1rem", marginRight: "-5px" }}
            />
            <div style={styles.mobileDateStyle}>
              {state.passenger || record.passenger}
            </div>
          </div>
        </div>
        <Link to={modifyLink} style={styles.mobileLinkStyle}>
          <EditIcon style={{ fontSize: "20px", cursor: "pointer" }} />
        </Link>
      </div>
    </AppBar>
  );
};

export default MobileSecondNavBar;
