import React from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UploadDocument from "../../grantsComponents/UploadDocument";
import { ThemeProvider } from "@mui/material";
import theme from "../../Theme";

function RightRegistrationFormComponentCityOfLA({
  header,
  state,
  handleChange,
  uploadedFiles,
  setUploadedFiles,
  fileError,
  setFileError,
}) {
  const renderFormField = (fieldLabel, fieldName, value) => {
    console.log("fieldName ", fieldName);
    if (fieldName === "dob") {
      return (
        <div key={fieldName}>
          <TextField
            label={fieldLabel}
            fullWidth
            size="small"
            variant="standard"
            type="Date"
            InputLabelProps={{ shrink: true, placeholder: "" }}
            name={fieldName}
            value={value}
            onChange={(e) => handleChange(e)}
            sx={{ margin: "0.5rem 0" }}
          />
        </div>
      );
    } else if (typeof value === "boolean") {
      return (
        <div key={fieldName}>
          <FormControlLabel
            control={
              <Checkbox
                name={fieldName}
                checked={value}
                onChange={(e) => handleChange(e)}
              />
            }
            label={fieldLabel}
          />
        </div>
      );
    } else {
      return (
        <div key={fieldName}>
          <TextField
            multiline
            fullWidth
            required
            name={fieldName}
            value={value}
            onChange={(e) => handleChange(e)}
            variant="standard"
            label={fieldLabel}
            // type={typeof value === "number" ? "number" : "text"}
            sx={{ margin: "0.5rem 0" }}
          />
        </div>
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Typography variant="h3" gutterBottom>
          {header}
        </Typography>
        <Accordion style={{ marginBottom: "1rem" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              minHeight: 45,
              maxHeight: 45,
              // backgroundColor: theme.palette.cityLA.secondary,
              // "&:hover": {
              //   backgroundColor: theme.palette.cityLA.secondaryHighlighted,
              // },
              "&.Mui-expanded": {
                minHeight: 55,
                maxHeight: 55,
              },
            }}
          >
            <Typography variant="subtitle1">
              1. Basic Demographic Information
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {renderFormField("First Name", "firstName", state.firstName)}
              {renderFormField("Lastname", "lastName", state.lastName)}
              {renderFormField("Alias", "alias", state.alias)}
              {renderFormField("DOB", "dob", state.dob)}
              {renderFormField(
                "Last Known Address",
                "lastKnownAddress",
                state.lastKnownAddress
              )}
              {renderFormField(
                "Mother’s First Name",
                "motherFirstName",
                state.motherFirstName
              )}
              {renderFormField(
                "Mother’s Last Name",
                "motherLastName",
                state.motherLastName
              )}
              {renderFormField(
                "Father’s First Name",
                "fatherFirstName",
                state.fatherFirstName
              )}
              {renderFormField(
                "Father’s Last Name",
                "fatherLastName",
                state.fatherLastName
              )}
              {renderFormField(
                "What is your gender identity?",
                "genderIdentity",
                state.genderIdentity
              )}
              {renderFormField(
                "Do you identify as a young adult (18-24 years old)?",
                "isYoungAdult",
                state.isYoungAdult
              )}
              {renderFormField(
                "Native language",
                "nativeLanguage",
                state.nativeLanguage
              )}
              {renderFormField(
                "Other Language",
                "otherLanguage",
                state.otherLanguage
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ marginBottom: "1rem" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              minHeight: 45,
              maxHeight: 45,
              // backgroundColor: theme.palette.cityLA.secondary,
              // "&:hover": {
              //   backgroundColor: theme.palette.cityLA.secondaryHighlighted,
              // },
              "&.Mui-expanded": {
                minHeight: 55,
                maxHeight: 55,
              },
            }}
          >
            <Typography variant="subtitle1">2. Housing History</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {renderFormField(
                "How long have you been homeless?",
                "homelessDuration",
                state.homelessDuration
              )}
              {renderFormField(
                "Have you experienced homelessness before?",
                "experiencedHomelessnessBefore",
                state.experiencedHomelessnessBefore
              )}
              {state.experiencedHomelessnessBefore &&
                renderFormField(
                  "If yes, how many times have you experienced homelessness?",
                  "numTimesHomeless",
                  state.numTimesHomeless
                )}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ marginBottom: "1rem" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              minHeight: 45,
              maxHeight: 45,
              // backgroundColor: theme.palette.cityLA.secondary,
              // "&:hover": {
              //   backgroundColor: theme.palette.cityLA.secondaryHighlighted,
              // },
              "&.Mui-expanded": {
                minHeight: 55,
                maxHeight: 55,
              },
            }}
          >
            <Typography variant="subtitle1">3. Living Conditions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {renderFormField(
                "Where do you typically sleep or stay at night?",
                "sleepLocation",
                state.sleepLocation
              )}
              {renderFormField(
                "Do you have access to shelter or transitional housing?",
                "hasShelterAccess",
                state.hasShelterAccess
              )}
              {renderFormField(
                "Are you currently couch-surfing or staying with friends/family?",
                "isCouchSurfing",
                state.isCouchSurfing
              )}
              {renderFormField(
                "Are other people depending on you?",
                "dependents",
                state.dependents
              )}
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "1rem" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              minHeight: 45,
              maxHeight: 45,
              "&.Mui-expanded": {
                minHeight: 55,
                maxHeight: 55,
              },
            }}
          >
            <Typography variant="subtitle1">
              4. Educational Background
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {renderFormField(
                "What is your highest level of education completed?",
                "educationLevel",
                state.educationLevel
              )}
              {renderFormField(
                "Are you currently enrolled in school or pursuing further education?",
                "isEnrolledInSchool",
                state.isEnrolledInSchool
              )}
              {renderFormField(
                "Have you faced any challenges in accessing education due to homelessness?",
                "educationChallenges",
                state.educationChallenges
              )}
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "1rem" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              minHeight: 45,
              maxHeight: 45,
              "&.Mui-expanded": {
                minHeight: 55,
                maxHeight: 55,
              },
            }}
          >
            <Typography variant="subtitle1">
              5. Employment and Income
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {renderFormField(
                "Do you have a source of income, such as employment, government assistance, or financial support from family/friends?",
                "incomeSource",
                state.incomeSource
              )}
              {renderFormField(
                "Are you currently employed, underemployed, or seeking employment?",
                "employmentStatus",
                state.employmentStatus
              )}
              {renderFormField(
                "Have you faced any barriers to securing stable employment due to homelessness?",
                "employmentBarriers",
                state.employmentBarriers
              )}
              {renderFormField(
                "Do you want to learn a new trade?",
                "wantToLearnTrade",
                state.wantToLearnTrade
              )}
              {renderFormField(
                "Did you do any internship (date)?",
                "internshipDate",
                state.internshipDate
              )}
              {renderFormField(
                "Potential Job Classification",
                "potentialJobClassification",
                state.potentialJobClassification
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ marginBottom: "1rem" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              minHeight: 45,
              maxHeight: 45,
              "&.Mui-expanded": {
                minHeight: 55,
                maxHeight: 55,
              },
            }}
          >
            <Typography variant="subtitle1">
              6. Health and Well-being
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {renderFormField(
                "Do you have any chronic medical conditions or disabilities?",
                "chronicConditions",
                state.chronicConditions
              )}
              {renderFormField(
                "Are you currently receiving medical care or treatment?",
                "isReceivingMedicalCare",
                state.isReceivingMedicalCare
              )}
              {renderFormField(
                "Are you struggling with mental health issues, such as depression or anxiety?",
                "mentalHealthIssues",
                state.mentalHealthIssues
              )}
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginBottom: "1rem" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              minHeight: 45,
              maxHeight: 45,
              "&.Mui-expanded": {
                minHeight: 55,
                maxHeight: 55,
              },
            }}
          >
            <Typography variant="subtitle1">7. Support Systems</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {renderFormField(
                "Do you have a support network, such as friends, family, or mentors?",
                "supportNetwork",
                state.supportNetwork
              )}
              {renderFormField(
                "Have you accessed any support services or resources for homeless individuals?",
                "accessedSupportServices",
                state.accessedSupportServices
              )}
              {renderFormField(
                "Are you aware of available community resources for homeless young adults?",
                "awareOfResources",
                state.awareOfResources
              )}
              {renderFormField(
                "Do you have access to transportation?",
                "transportationAccess",
                state.transportationAccess
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ marginBottom: "1rem" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              minHeight: 45,
              maxHeight: 45,
              "&.Mui-expanded": {
                minHeight: 55,
                maxHeight: 55,
              },
            }}
          >
            <Typography variant="subtitle1">8. Safety and Security</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {renderFormField(
                "Have you experienced violence, harassment, or exploitation while homeless?",
                "experiencedViolence",
                state.experiencedViolence
              )}
              {renderFormField(
                "Do you feel safe in your current living situation?",
                "feelsSafe",
                state.feelsSafe
              )}
              {renderFormField(
                "Have you encountered any law enforcement interactions or legal issues related to homelessness?",
                "legalIssues",
                state.legalIssues
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ marginBottom: "1rem" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              minHeight: 45,
              maxHeight: 45,
              "&.Mui-expanded": {
                minHeight: 55,
                maxHeight: 55,
              },
            }}
          >
            <Typography variant="subtitle1">
              9. Future Goals and Aspirations
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {renderFormField(
                "What are your short-term goals?",
                "shortTermGoals",
                state.shortTermGoals
              )}
              {renderFormField(
                "What are your long-term goals?",
                "longTermGoals",
                state.longTermGoals
              )}
              {renderFormField(
                "Do you have any aspirations for education, employment, or housing stability?",
                "aspirations",
                state.aspirations
              )}
              {renderFormField(
                "Are there specific challenges or barriers you face in achieving your goals?",
                "goalChallenges",
                state.goalChallenges
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ marginBottom: "1rem" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              minHeight: 45,
              maxHeight: 45,
              "&.Mui-expanded": {
                minHeight: 55,
                maxHeight: 55,
              },
            }}
          >
            <Typography variant="subtitle1">
              10. Suggestions for Support and Improvement
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {renderFormField(
                "What types of support or services do you believe would be most helpful for homeless young adults?",
                "helpfulSupportServices",
                state.helpfulSupportServices
              )}
              {renderFormField(
                "Do you have any suggestions for community organizations, policymakers, or service providers to better address the needs of homeless young adults?",
                "suggestions",
                state.suggestions
              )}
              {renderFormField(
                "How can we improve outreach and support efforts for homeless individuals in your community?",
                "improvementSuggestions",
                state.improvementSuggestions
              )}
            </div>
          </AccordionDetails>
        </Accordion>

        {window.withUploadDocumentCityOfLA && (
          <UploadDocument
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            fileError={fileError}
            setFileError={setFileError}
          />
        )}
        <Button
          variant="contained"
          fullWidth
          style={{
            backgroundColor: theme.palette.cityLA.secondary,
            marginBottom: "1rem",
          }}
          // onClick={handleSubmit}
        >
          Sign up
        </Button>
      </div>
    </ThemeProvider>
  );
}

export default RightRegistrationFormComponentCityOfLA;
