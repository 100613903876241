import React, { useState } from "react";
import { Button, Box } from "@mui/material";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ClearIcon from "@mui/icons-material/Clear";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

// const excludedFileNames = ["mp4", "mp3", "lock", "CON", "PRN", "AUX", "NUL"];
// const excludedPrefixes = ["COM", "LPT", "_vti_", "~$"];
// const maxSize = 100000000; //100 MB
// const maxNumber = 1;
const excludedFileNames = window.excludedFileNames;
const excludedPrefixes = window.excludedPrefixes;
const maxSize = window.maxSize;
const maxNumber = window.maxNumber;

function UploadDocument({
  uploadedFiles,
  setUploadedFiles,
  fileError,
  setFileError,
}) {
  // function validations() {
  //   let validationErrors = {};

  //   if (uploadedFiles.length === 0) {
  //     validationErrors.requiredFile = "File is required";
  //   }

  //   setValidationErrors(validationErrors);
  //   console.log("error ", validationErrors);
  //   console.log("ret ", Object.keys(validationErrors).length === 0);
  //   return Object.keys(validationErrors).length === 0;
  // }

  function handleFileUpload(files) {
    setFileError("");
    const existingFileNames = uploadedFiles.map((file) =>
      file.name.toLowerCase()
    );
    const validFiles = Array.from(files).filter((file) => {
      const fileName = file.name.toLowerCase();
      const fileExtension = fileName.split(".").pop();
      if (
        excludedFileNames.includes(fileExtension) ||
        excludedPrefixes.some((prefix) => fileName.startsWith(prefix))
      ) {
        setFileError(`The File "${file.name}" Type Is Not Valid`);
      } else if (file.size > maxSize) {
        setFileError(`The File "${file.name}" Size Is Too Large`);
      } else if (existingFileNames.includes(fileName)) {
        setFileError(`The File "${file.name}" Has Already Been Uploaded`);
      } else {
        return (
          !excludedFileNames.includes(fileExtension) &&
          !excludedPrefixes.some((prefix) => fileName.startsWith(prefix))
        );
      }
    });
    if (validFiles.length + uploadedFiles.length > maxNumber) {
      setFileError(`You can only upload up to "${maxNumber}" files`);
    } else {
      setUploadedFiles((prevFiles) => [...prevFiles, ...validFiles]);
    }
  }
  const handleDeleteFile = (fileToDelete) => {
    setUploadedFiles((prevFiles) => {
      return prevFiles.filter((file) => file !== fileToDelete);
    });
  };
  function handleDrop(e) {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleFileUpload(files);
  }

  function handleFileInputChange(e) {
    const files = e.target.files;
    handleFileUpload(files);
  }

  function byteToSize(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
  }

  // async function handleSubmit() {
  //   console.log("uploadedFiles ", uploadedFiles);
  //   if (validations()) {
  //     const formData = new FormData();
  //     for (var i = 0; i < uploadedFiles.length; i++) {
  //       formData.append("files", uploadedFiles[i]);
  //     }
  //     //   const response = await PostDocuments(formData);
  //     //   console.log("response ", response);
  //     //   if (response.ok === true) {
  //     //     setError("");
  //     //     clearData();
  //     //     handleEditContactInfoClick();
  //     //     onClose();
  //     //   } else {
  //     //     if (response.statusText === "") {
  //     //       setError("Your Request Was Not Fulfilled");
  //     //     } else {
  //     //       setError(response.statusText);
  //     //     }
  //     //     handleEditContactInfoClick();
  //     //   }
  //   }
  // }

  // const getContrastingColor = (file) => {
  //   const img = new Image();
  //   img.src = URL.createObjectURL(file);

  //   img.onload = () => {
  //     const dominantColor = getDominantColor(img);
  //     console.log("dominantColor ", dominantColor);
  //     const isLight = isLightColor(dominantColor) ? "black" : "white"; // Adjust as needed
  //     console.log("isLight ", isLight);
  //     return isLight;
  //   };

  //   return "black"; // Default color before image loads
  // };

  // const getDominantColor = (img) => {
  //   const canvas = document.createElement("canvas");
  //   canvas.width = img.width;
  //   canvas.height = img.height;
  //   const ctx = canvas.getContext("2d");
  //   ctx.drawImage(img, 0, 0);

  //   const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
  //   const colorMap = {};
  //   for (let i = 0; i < imageData.length; i += 4) {
  //     const r = imageData[i];
  //     const g = imageData[i + 1];
  //     const b = imageData[i + 2];
  //     const hex =
  //       "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  //     colorMap[hex] = (colorMap[hex] || 0) + 1;
  //   }

  //   const mostCommonColor = Object.entries(colorMap).reduce(
  //     (max, [color, count]) => {
  //       return count > max[1] ? [color, count] : max;
  //     }
  //   )[0];

  //   return mostCommonColor; // Return the most common color as a hex string
  // };

  // // Function to determine if a color is light
  // const isLightColor = (color) => {
  //   const rgb = hexToRgb(color); // Convert hex color to RGB
  //   const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
  //   return brightness > 150; // Adjust threshold as needed
  // };

  // const hexToRgb = (hex) => {
  //   const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  //   return result
  //     ? {
  //         r: parseInt(result[1], 16),
  //         g: parseInt(result[2], 16),
  //         b: parseInt(result[3], 16),
  //       }
  //     : null;
  // };

  return (
    <>
      <input
        type="file"
        id="fileInput"
        style={{ display: "none" }}
        onChange={handleFileInputChange}
        multiple
      />
      <div
        onDragEnter={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDrop={handleDrop}
        style={{
          border: `2px dashed ${fileError ? "red" : "gray"}`,
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "auto",
          width: "100%",
          margin: "auto",
          marginBottom: "20px",
          backgroundColor: "#f1f1f1",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          paddingBottom: "10px",
          paddingTop: "10px",
        }}
      >
        <p style={{ margin: "0", fontSize: "18px" }}>
          Drag and drop files here
        </p>
        <p style={{ margin: "0", fontSize: "14px" }}>OR</p>

        <Button
          variant="contained"
          color={uploadedFiles.length === maxNumber ? "error" : "primary"}
          component="label"
          htmlFor="fileInput"
          size="small"
          startIcon={<CloudUploadIcon />}
          style={{ width: "200px", height: "40px", marginTop: "10px" }}
        >
          Browse
        </Button>
        <Box>
          {fileError && (
            <p
              style={{
                marginLeft: "10px",
                marginTop: "10px",
                color: "red",
              }}
            >
              {fileError}
            </p>
          )}

          {/* {validationErrors.requiredFile && (
            <p
              style={{
                marginLeft: "10px",
                marginTop: "10px",
                color: "red",
              }}
            >
              {validationErrors.requiredFile}
            </p>
          )} */}

          {uploadedFiles.map((file) => (
            <Card
              key={file.name}
              style={{
                height: "auto",
                width: "auto",
                margin: "5px",
                display: "inline-block",
              }}
            >
              {file.type.startsWith("image/") ? (
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  <button
                    onClick={() => handleDeleteFile(file)}
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    <ClearIcon
                      style={{
                        color: "darkblue",
                      }}
                    />
                  </button>
                  <CardMedia
                    component="img"
                    image={URL.createObjectURL(file)}
                    alt={file.name}
                    style={{ padding: 0, margin: 0 }}
                  />
                </div>
              ) : (
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <InsertDriveFileIcon />
                    <p>{file.name}</p>
                    <button
                      onClick={() => handleDeleteFile(file)}
                      style={{
                        marginLeft: "auto",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                    >
                      <ClearIcon />
                    </button>
                  </div>
                  <Typography
                    color="textSecondary"
                    style={{ marginLeft: "25px" }}
                  >
                    {byteToSize(file.size)}
                  </Typography>
                </CardContent>
              )}
            </Card>
          ))}
        </Box>
      </div>
    </>
  );
}

export default UploadDocument;
