import React, { useState, useEffect } from "react";
import {
  Box,
  List,
  IconButton,
  useMediaQuery,
  Paper,
  Divider,
} from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuCardComponent from "./MenuCardComponent";
import MiniCardComponent from "../landingPage/MiniCardComponent";
import MenuListComponent from "./MenuListComponent";
import {
  StyledContainerBox,
  listFlexBox,
  StyledBoxForCard,
  cardMobileContainer,
} from "./MenuComponentStyles";
import theme from "../../Theme";
import HomeIcon from "@mui/icons-material/Home";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import MenuNavigationComponent from "./MenuNavigationComponent";

let pageIcons = [
  <HomeIcon />,
  <ConfirmationNumberOutlinedIcon />,
  <DashboardIcon />,
  <PendingActionsIcon />,
];
export default function MenuComponent({ open, pageNames, pagePaths }) {
  //Getting data from public folder
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState(null);
  useEffect(() => {
    const scriptElement = document.createElement("script");
    scriptElement.src = `Menu.js?v=${Date.now()}`;

    // Append the script tag to the document's head or body
    document.head.appendChild(scriptElement);
    scriptElement.onload = () => {
      setData(window.MyVar);
      setLoaded(true);
    };
  }, []);

  //Mobile vars
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  // State to control whether to show the list or card component
  const [showList, setShowList] = useState(true);

  // Function to switch between list and card view
  const switchToCardView = () => {
    setShowList(false);
  };

  const switchToListView = () => {
    setShowList(true);
  };

  //To open and close the menu
  const [openDrawer, setOpenDrawer] = useState(open);
  const toggleDrawer = (state) => (event) => {
    setOpenDrawer(state);
  };

  //Index of the list item the user selected
  const [sectionClickedIndex, setSectionClickedIndex] = useState(0);
  function handleListClick(sectionIndex) {
    setSectionClickedIndex(sectionIndex);
  }
  const list = () => (
    <StyledContainerBox role="presentation" onKeyDown={toggleDrawer(false)}>
      <Box sx={listFlexBox}>
        <List>
          {data[0].sections.map((section, sectionIndex) => (
            <MenuListComponent
              key={sectionIndex}
              section={section}
              sectionIndex={sectionIndex}
              handleListClick={handleListClick}
              sectionClickedIndex={sectionClickedIndex}
            />
          ))}
        </List>
      </Box>
      {sectionClickedIndex !== -1 && (
        <StyledBoxForCard>
          {data[0].sections[sectionClickedIndex].items.map(
            (item, itemIndex) => (
              <MenuCardComponent
                key={item.feedID}
                data={item}
                size={290}
                textSize={20}
              />
            )
          )}
        </StyledBoxForCard>
      )}
    </StyledContainerBox>
  );
  const mobileList = () => (
    <>
      {showList ? ( // Render the list or card component based on showList state
        <StyledContainerBox role="presentation" onKeyDown={toggleDrawer(false)}>
          <Box sx={listFlexBox}>
            <List>
              {data[0].sections.map((section, sectionIndex) => (
                <MenuListComponent
                  key={sectionIndex}
                  section={section}
                  sectionIndex={sectionIndex}
                  handleListClick={() => {
                    handleListClick(sectionIndex);
                    switchToCardView(); // Switch to card view on list item click
                  }}
                  sectionClickedIndex={sectionClickedIndex}
                />
              ))}
              <Divider sx={{ margin: "1rem 0" }} />
              <MenuNavigationComponent
                pageNames={pageNames}
                pagePaths={pagePaths}
                pageIcons={pageIcons}
              />
            </List>
          </Box>
        </StyledContainerBox>
      ) : (
        <div style={cardMobileContainer}>
          {/* Back button to switch back to list view */}
          <Paper
            elevation={3}
            style={{
              borderRadius: "50%",
              display: "inline-block",
              marginTop: "0.5rem",
              marginLeft: "0.3rem",
            }}
          >
            <IconButton
              onClick={switchToListView}
              style={{ backgroundColor: "white" }} // Set the background color to white
            >
              <ArrowBackIcon color="primary" />
            </IconButton>
          </Paper>
          {/* Render the card component */}
          <StyledBoxForCard isMobile={true}>
            {data[0].sections[sectionClickedIndex].items.map(
              (item, itemIndex) => (
                <MenuCardComponent
                  key={item.feedID}
                  data={item}
                  size={290}
                  textSize={20}
                  isMobile={isMobile}
                />
              )
            )}
          </StyledBoxForCard>
        </div>
      )}
    </>
  );

  return (
    <SwipeableDrawer
      anchor={"top"}
      open={openDrawer}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      {loaded && isMobile ? mobileList() : loaded && list()}
    </SwipeableDrawer>
  );
}
