import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const CustomAlert = forwardRef(
  ({ title, type, content, duration, ancorVertical, ancorHorizontal }, ref) => {
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      showAlert: () => {
        setOpen(true);
      },
    }));

    const handleAlertClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpen(false);
    };

    return (
      <>
        <Snackbar
          open={open}
          autoHideDuration={duration}
          onClose={handleAlertClose}
          anchorOrigin={{
            vertical: ancorVertical,
            horizontal: ancorHorizontal,
          }}
          sx={{
            "&.MuiSvgIcon-root": {
              color: "white",
            },
          }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={type}
            sx={{
              width: "100%",
            }}
          >
            <AlertTitle>{title}</AlertTitle>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </Alert>
        </Snackbar>
        <Snackbar
          open={open}
          autoHideDuration={duration}
          onClose={handleAlertClose}
          anchorOrigin={{
            vertical: ancorVertical,
            horizontal: ancorHorizontal,
          }}
        >
          <Alert
            onClose={handleAlertClose}
            severity={type}
            sx={{
              width: "100%",
              "&.MuiAlert-root": {
                backgroundColor:
                  type === "success"
                    ? "green"
                    : type === "error"
                    ? "orange"
                    : "#ffc107",
                color: "#ffffff",
                fontSize: "1rem",
                lineHeight: "2",
              },
              "& .MuiAlert-icon": {
                color: "#ffffff",
              },
            }}
          >
            <AlertTitle>{title}</AlertTitle>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </Alert>
        </Snackbar>
      </>
    );
  }
);

export default CustomAlert;
