import React from 'react';
import ReactDOM from 'react-dom';
import "./index.css";
import "./index.css";
// import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from "./reportWebVitals";
import { GlobalProvider } from './GlobalState';


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GlobalProvider>
        <App />     
    </GlobalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();


reportWebVitals();

