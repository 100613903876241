export function validateFirstRecord(firstRecord) {
  const errors = {
    numberOfPassengers: "",
    mobileNumberError: "",
    emailError: "",
    confirmEmailError: "",
    requiredError: "",
  };

  // Check if all fields are filled in firstRecord
  const requiredFields = [
    "contactPerson",
    "mobileNumber",
    "email",
    "confirmEmail",
    "address",
    "areaCode",
  ];
  
  // Loop through required fields and set requiredError for the first empty field found
  for (const field of requiredFields) {
    if (!firstRecord[field] || firstRecord[field] === "") {
      errors.requiredError = `All fields are required, missing ${field}`;
      break;  // Exit the loop once an error is found
    }
  }


  // Check if email is a valid email address
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(firstRecord.email)) {
    errors.emailError = "Enter a valid email";
  }

  const mobileNumberRegex = /^\d{10}$/;
  if (!mobileNumberRegex.test(firstRecord.mobileNumber)) {
    errors.mobileNumberError = "Enter a valid 10-digit mobile number";
  }

  // Check if confirm email and email are the same
  if (firstRecord.email !== firstRecord.confirmEmail) {
    errors.confirmEmailError = "Confirm email address doesn't match";
  }

  // Check if length of persons array matches numberOfPassengers
  if (firstRecord.persons.length !== parseInt(firstRecord.numberOfPassengers)) {
    errors.numberOfPassengers = "Number of passengers does not match persons provided";
  }

  // Check if each object in persons has all the fields
  const personRequiredFields = [
    "firstName",
    "lastName",
    "gender",
    "type",
    "DOB",
    "nationality",
  ];

  for (const person of firstRecord.persons) {
    for (const field of personRequiredFields) {
      if (!person[field] || person[field] === "") {
        errors.requiredError = `All fields are required for person, missing ${field}`;
        break;  // Exit the loop once an error is found
      }
    }
  }

  console.log("Errors", errors);
  return { isValid: Object.values(errors).every((error) => !error), errors };
}


export const validateCardNumber = (cardNumber) => {
  // const digits = cardNumber.split("").map(Number);
  // let sum = 0;
  // let shouldDouble = false;

  // // Start from the rightmost digit
  // for (let i = digits.length - 1; i >= 0; i--) {
  //   let digit = digits[i];

  //   if (shouldDouble) {
  //     digit *= 2;
  //     if (digit > 9) {
  //       digit -= 9;
  //     }
  //   }

  //   sum += digit;
  //   shouldDouble = !shouldDouble;
  // }

  // return sum % 10 === 0;
  if (cardNumber.length < 16 || cardNumber.length > 16) {
    return false;
  } else {
    return true;
  }
};

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

export function validateSecondRecord(secondRecord) {
  let errors = {};
  if (!secondRecord.nameOnCard) {
    errors.nameOnCard = "Enter Name On Card";
  }
  if (!secondRecord.cardNumber) {
    errors.cardNumber = "Enter Card Number";
  }
  if (validateCardNumber(secondRecord.cardNumber)) {
    errors.cardNumber = "Enter A Valid Card Number";
  }
  if (!secondRecord.securityCode) {
    errors.securityCode = "Enter Security Code";
  }
  if (secondRecord.securityCode && secondRecord.securityCode.length !== 3) {
    errors.securityCode = "CVV is 3 digits";
  }
  if (!secondRecord.expirationMonth) {
    errors.expirationMonth = "Enter Expiration Month";
  }
  if (!secondRecord.expirationYear) {
    errors.expirationYear = "Enter Expiration Year";
  }
  if (secondRecord.expirationYear && secondRecord.expirationMonth) {
    if (
      parseInt(secondRecord.expirationYear) < currentYear ||
      (parseInt(secondRecord.expirationYear) === currentYear &&
        parseInt(secondRecord.expirationMonth) < currentMonth)
    ) {
      errors.expirationYear = "Your card is expired";
      errors.expirationMonth = "Your card is expired";
    }
  }
  if (!secondRecord.creditCardAddress) {
    errors.address = "Please Enter Address";
  }
  console.log("Errors", errors);
  return { isValid: Object.values(errors).every((error) => !error), errors };
}
