import { styled } from "@mui/material/styles";
import theme from "../../../Theme";

export const WarningBoxDiv = styled("div")(({ theme, type }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  backgroundColor:
    type === 1
      ?" #D1E8A1"
      : type === 2
      ? theme.palette.warningColor.bg
      : type === 3
      ? theme.palette.errorColor.bg
      : theme.palette.infoColor.bg,
  padding: theme.spacing(2),
  margin: "1rem 0",
  [theme.breakpoints.down("sm")]: {
    margin: "1rem 0.1rem",
  },
}));

export const successIcon = {
  marginRight: theme.spacing(1),
  color: theme.palette.successColor.content,
};

export const successText = {
  color: theme.palette.successColor.content,
  fontWeight: "bold",
  fontSize: "14px",
};
export const warningIcon = {
  marginRight: theme.spacing(1),
  color: theme.palette.warningColor.content,
};

export const warningText = {
  color: theme.palette.warningColor.content,
  fontWeight: "bold",
  fontSize: "14px",
};
export const errorIcon = {
  marginRight: theme.spacing(1),
  color: theme.palette.errorColor.content,
};

export const errorText = {
  color: theme.palette.errorColor.content,
  fontWeight: "bold",
  fontSize: "14px",
};
export const infoIcon = {
  marginRight: theme.spacing(1),
  color: theme.palette.infoColor.content,
};

export const infoText = {
  color: theme.palette.infoColor.content,
  fontWeight: "bold",
  fontSize: "14px",
};
