import React, { useState } from "react";
import RightRegistrationFormComponent from "./RightRegistrationFormComponent";
import {
  PostGrantsRegistration,
  CheckEmailIsOnFile,
  CheckEINIsOnFile,
} from "../../apiCalls/GrantsAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const requiredFields = [
  "email",
  "password",
  "confirmPassword",
  "firstName",
  "lastName",
  "title",
  "interestArea",
  "organizationType",
  "country",
];
const requiredFieldsShortForm = [
  "email",
  "password",
  "confirmPassword",
  "firstName",
  "lastName",
  "title",
];

function FormBusiness({ shortForm }) {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileError, setFileError] = useState("");
  const [submit, setSubmit] = React.useState(false);
  const [state, setState] = React.useState({
    email: "",
    password: "",
    confirmPassword: "",
    showPassword: false,
    firstName: "",
    lastName: "",
    title: "",
    isIndividual: false,
    organizationName: "",
    organizationType: "",
    country: "",
    state: "",
    ein: "",
    interestArea: "",
    agreeTerms: false,
    receiveUpdates: false,
    yearEstablished: "",
    legalStatus: "",
    missionStatement: "",
    visionStatement: "",
    organizationFocus: "",
    purposeOfOrganization: "",
    strategicGoals: "",
    accreditations: "",
    targetPopulations: "",
    servicesProvided: "",
    geographicCoverage: "",
    ageGroupsServed: "",
    incomeLevelServed: "",
    householdServed: "",
    challenges: "",
    annualBudget: "",
    grossReceipts: "",
    keyFundingSources: "",
    percentageOfBudgetForServices: "",
    additionalInformation: "",
    congressionalDistrict: "",
    stateSenateDistrict: "",
    facebook: "",
    linkedin: "",
    instagram: "",
    subjectArea: [],
    populationServed: [],
    NTEE: [],
    address: "",
    city: "",
    addressComp: "",
    zipCode: "",
    webPages: "",
  });
  const [errors, setErrors] = React.useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    title: "",
    organizationName: "",
    organizationType: "",
    country: "",
    state: "",
    ein: "",
    interestArea: "",
    agreeTerms: "",
    yearEstablished: "",
    legalStatus: "",
    missionStatement: "",
    visionStatement: "",
    organizationFocus: "",
    purposeOfOrganization: "",
    strategicGoals: "",
    accreditations: "",
    targetPopulations: "",
    servicesProvided: "",
    geographicCoverage: "",
    ageGroupsServed: "",
    incomeLevelServed: "",
    householdServed: "",
    challenges: "",
    annualBudget: "",
    grossReceipts: "",
    keyFundingSources: "",
    percentageOfBudgetForServices: "",
    additionalInformation: "",
    congressionalDistrict: "",
    stateSenateDistrict: "",
    facebook: "",
    linkedin: "",
    instagram: "",
    subjectArea: "",
    populationServed: "",
    NTEE: "",
    address: "",
    city: "",
    addressComp: "",
    zipCode: "",
    webPages: "",
  });

  const [emailExists, setEmailExists] = useState(false);
  const [einExists, setEinExists] = useState(false);
  const isEINValid = (ein) => {
    const isValid = /^\d{2}-\d{7}$/.test(ein);
    console.log("isValid Ein ", isValid);
    setErrors((prevErrors) => ({
      ...prevErrors,
      ein: isValid ? "" : "EIN should be xx-xxxxxxx",
    }));
    return isValid;
  };
  async function checkEmailExists() {
    console.log("state.email ", state.email);
    const response = await CheckEmailIsOnFile(state.email);
    console.log("response ", response);
    if (response) {
      setEmailExists(true);
    } else {
      setEmailExists(false);
    }
    return response;
  }
  async function checkEINExists() {
    console.log("state.ein ", state.ein);
    const response = await CheckEINIsOnFile(state.ein);
    console.log("response ", response);
    if (response) {
      setEinExists(true);
    } else {
      setEinExists(false);
    }
    return response;
  }
  const isEmailValid = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: isValid ? "" : "Please enter a valid email",
    }));
    return isValid;
  };

  const isPasswordValid = (password) => {
    // Define your password criteria
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    // Check if the password meets all criteria
    const isValid =
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasDigit &&
      hasSpecialChar;

    let errorMessage = "";

    if (password.length < minLength) {
      errorMessage = "Password must be at least 8 characters long";
    } else if (!hasUpperCase) {
      errorMessage = "Password must contain at least one uppercase letter";
    } else if (!hasLowerCase) {
      errorMessage = "Password must contain at least one lowercase letter";
    } else if (!hasDigit) {
      errorMessage = "Password must contain at least one digit";
    } else if (!hasSpecialChar) {
      errorMessage = "Password must contain at least one special character";
    }

    if (password === state.confirmPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Password do not match",
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      password: errorMessage,
    }));
    return isValid;
  };

  const isConfirmPasswordValid = (confirmPassword) => {
    const isValid = confirmPassword === state.password;
    setErrors((prevErrors) => ({
      ...prevErrors,
      confirmPassword: isValid ? "" : "Password do not match",
    }));
    return isValid;
  };

  const handleValidation = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (name) {
      case "email":
        return isEmailValid(value);
      case "password":
        return isPasswordValid(value);
      case "confirmPassword":
        return isConfirmPasswordValid(value);
      case "ein":
        return isEINValid(value);
      default:
        return;
    }
  };

  const handleRequiredValidation = (event) => {
    const errors = {};

    if (shortForm) {
      requiredFieldsShortForm.forEach((fieldName) => {
        const value = state[fieldName].trim();
        errors[fieldName] = value
          ? ""
          : `${
              fieldName.charAt(0).toUpperCase() + fieldName.slice(1)
            } is required`;
      });
    } else {
      requiredFields.forEach((fieldName) => {
        const value = state[fieldName].trim();
        errors[fieldName] = value
          ? ""
          : `${
              fieldName.charAt(0).toUpperCase() + fieldName.slice(1)
            } is required`;
      });
    }

    // Additional validation for state if the country is United States
    if (state.country === "United States" && !shortForm) {
      const isValidState = state.state.trim() !== "";
      errors.state = isValidState ? "" : "State is required for United States";
    }

    // Additional validation for organizationName if isIndividual is true
    if (!state.isIndividual) {
      const isValidOrgName = state.organizationName.trim() !== "";
      errors.organizationName = isValidOrgName
        ? ""
        : "Organization Name is required";
      const isValidEin = state.ein.trim() !== "";
      errors.ein = isValidEin ? "" : "EIN is required";
    }
    if (!shortForm) {
      errors.agreeTerms = state.agreeTerms
        ? ""
        : "Please agree to terms and conditions";
    }

    console.log("errors ", errors);
    setErrors(errors);
    const nonEmptyErrors = Object.fromEntries(
      Object.entries(errors).filter(([key, value]) => value !== "")
    );
    console.log(
      "Object.keys(nonEmptyErrors).length === 0",
      Object.keys(nonEmptyErrors).length === 0
    );
    // return Object.keys(errors).length === 0;
    return Object.keys(nonEmptyErrors).length === 0;
  };

  function convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Check if the file was successfully read
        if (reader.result) {
          resolve(reader.result.split(",")[1]); // Extract the base64 data (after the comma)
        } else {
          reject(new Error("Failed to read the file as base64."));
        }
      };

      reader.readAsDataURL(file);
    });
  }

  async function handleSubmit(event) {
    setSubmit(true);
    if (handleRequiredValidation(event)) {
      const emailValid = isEmailValid(state.email);
      const passwordValid = isPasswordValid(state.password);
      const confirmPasswordValid = isConfirmPasswordValid(
        state.confirmPassword
      );
      let EINValid = true;
      if (state.isIndividual) {
        const EINValid = isEINValid(state.ein);
        console.log("emailValid ", emailValid);
        console.log("passwordValid ", passwordValid);
        console.log("confirmPasswordValid ", confirmPasswordValid);
        console.log("EINValid ", EINValid);
      }

      if (
        emailValid &&
        passwordValid &&
        confirmPasswordValid &&
        EINValid &&
        !emailExists &&
        !einExists
      ) {
        console.log("HI");
        console.log("state ", state);
        let registryRecord;
        if (uploadedFiles.length > 0 && !shortForm) {
          registryRecord = {
            Accreditations: state.accreditations,
            Additional_Information: state.additionalInformation,
            Address1: state.address,
            Address2: state.addressComp,
            Age_Groups_Served: state.ageGroupsServed,
            Annual_Budget: state.annualBudget,
            Challenges: state.challenges,
            City: state.city,
            Congressional_District: state.congressionalDistrict,
            Country: state.country,
            EIN: state.ein,
            EmailName: state.email,
            FaceBook: state.facebook,
            FirstName: state.firstName,
            Geographic_coverage: state.geographicCoverage,
            Gross_Receipts: state.grossReceipts,
            Household_served: state.householdServed,
            Income_level_Served: state.incomeLevelServed,
            Instagram: state.instagram,
            Keywords: state.interestArea,
            isIndividual: state.isIndividual ? 1 : 0,
            Key_funding_Sources: state.keyFundingSources,
            LastName: state.lastName,
            LegalStatus: state.legalStatus,
            LinkedIn: state.linkedin,
            Mission_Statement: state.missionStatement,
            NTEE: state.NTEE.join(";"),
            Organization_Focus: state.organizationFocus,
            CompanyName: state.organizationName,
            OrganizationType: state.organizationType,
            Password: state.password,
            Percentage_Budget_on_services: state.percentageOfBudgetForServices,
            Population_served: state.populationServed.join(";"),
            Purpose_Of_Organization: state.purposeOfOrganization,
            Receive_updates: state.receiveUpdates ? 1 : 0,
            Services_Provided: state.servicesProvided,
            State: state.state,
            State_Senate_District: state.stateSenateDistrict,
            Strategic_Goals: state.strategicGoals,
            Subject_Area: state.subjectArea.join(";"),
            Target_Populations: state.targetPopulations,
            Title: state.title,
            Vision_Statement: state.visionStatement,
            YearEstablished: state.yearEstablished,
            PostalCode: state.zipCode,
            WebPage: state.webPage,
            QinfoLibDetails: {
              Createby: "User",
              CreateDate: new Date().toLocaleDateString(),
              FileSize: uploadedFiles[0].size.toString(),
              FileType: "",
              FileName: uploadedFiles[0].name,
              Header: "Registry Document",
              QLibID: "8",
              isLink: 1,
              image: await convertFileToBase64(uploadedFiles[0]),
              CustomFieldName: "ProjectID",
              CustomFieldValue: state.email,
            },
          };
        } else if (uploadedFiles.length <= 0 && !shortForm) {
          registryRecord = {
            Accreditations: state.accreditations,
            Additional_Information: state.additionalInformation,
            Address1: state.address,
            Address2: state.addressComp,
            Age_Groups_Served: state.ageGroupsServed,
            Annual_Budget: state.annualBudget,
            Challenges: state.challenges,
            City: state.city,
            Congressional_District: state.congressionalDistrict,
            Country: state.country,
            EIN: state.ein,
            EmailName: state.email,
            FaceBook: state.facebook,
            FirstName: state.firstName,
            Geographic_coverage: state.geographicCoverage,
            Gross_Receipts: state.grossReceipts,
            Household_served: state.householdServed,
            Income_level_Served: state.incomeLevelServed,
            Instagram: state.instagram,
            Keywords: state.interestArea,
            isIndividual: state.isIndividual ? 1 : 0,
            Key_funding_Sources: state.keyFundingSources,
            LastName: state.lastName,
            LegalStatus: state.legalStatus,
            LinkedIn: state.linkedin,
            Mission_Statement: state.missionStatement,
            NTEE: state.NTEE.join(";"),
            Organization_Focus: state.organizationFocus,
            CompanyName: state.organizationName,
            OrganizationType: state.organizationType,
            Password: state.password,
            Percentage_Budget_on_services: state.percentageOfBudgetForServices,
            Population_served: state.populationServed.join(";"),
            Purpose_Of_Organization: state.purposeOfOrganization,
            Receive_updates: state.receiveUpdates ? 1 : 0,
            Services_Provided: state.servicesProvided,
            State: state.state,
            State_Senate_District: state.stateSenateDistrict,
            Strategic_Goals: state.strategicGoals,
            Subject_Area: state.subjectArea.join(";"),
            Target_Populations: state.targetPopulations,
            Title: state.title,
            Vision_Statement: state.visionStatement,
            YearEstablished: state.yearEstablished,
            PostalCode: state.zipCode,
            WebPage: state.webPage,
          };
        } else {
          registryRecord = {
            EmailName: state.email,
            Password: state.password,
            FirstName: state.firstName,
            LastName: state.lastName,
            Title: state.title,
            isIndividual: state.isIndividual ? 1 : 0,
            CompanyName: state.organizationName,
            EIN: state.ein,
            City: state.city,
            WebPage: state.webPage,
          };
        }
        console.log("registryRecord ", registryRecord);
        const response = await PostGrantsRegistration(registryRecord);
        console.log("response ", response);
        if (response.ok === true) {
          window.open("/ThankYou");
          //   setError("");
          //   clearData();
          //   handleEditContactInfoClick();
          //   onClose();
        }
        // else{
        //   if(response.statusText === ""){
        //     setError("Your Request Was Not Fulfilled")
        //   }
        //   else{
        //     setError(response.statusText);
        //   }
        //   handleEditContactInfoClick();
        // }
      } else {
        console.log("errors ", errors);
        if (!emailValid) {
          toast.error("Please enter a valid email");
        }
        if (!passwordValid) {
          toast.error(errors.password);
        }
        if (!confirmPasswordValid) {
          toast.error(errors.confirmPassword);
        }
        if (!EINValid) {
          toast.error(errors.ein);
        }
      }
    } else {
      toast.error("Please enter all required fields");
      // Map through errors and show toast for each non-empty error
      // Object.entries(errors).forEach(([field, error]) => {
      //   if (error !== "") {
      //     toast.error(`${error}`);
      //   }
      // });
    }
  }

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    handleValidation(event);
    // if (submit) {
    //   handleRequiredValidation(event);
    // }
    setState({
      ...state,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleMultiSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setState({
      ...state,
      [event.target.name]: typeof value === "string" ? value.split(",") : value,
    });
  };
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <RightRegistrationFormComponent
        header={window.headerRightForm}
        state={state}
        errors={errors}
        handleChange={handleChange}
        handleMultiSelectChange={handleMultiSelectChange}
        checkEmailExists={checkEmailExists}
        checkEINExists={checkEINExists}
        emailExists={emailExists}
        einExists={einExists}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
        fileError={fileError}
        setFileError={setFileError}
        handleSubmit={handleSubmit}
        shortForm={shortForm}
      />
    </>
  );
}

export default FormBusiness;
