// import React from "react";
// import { Typography } from "@mui/material";
// import {
//   WarningBoxDiv,
//   successIcon,
//   successText,
//   warningIcon,
//   warningText,
//   errorIcon,
//   errorText,
//   infoIcon,
//   infoText,
// } from "./WarningBoxStyles";
// import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
// import ThumbUpIcon from "@mui/icons-material/ThumbUp";
// import ErrorIcon from "@mui/icons-material/Error";
// import InfoIcon from "@mui/icons-material/Info";
// import theme from "../../../Theme";
// function WarningBox({ message, type }) {
//   const getIconByType = (type) => {
//     switch (type) {
//       case 1:
//         return <ThumbUpIcon style={successIcon} />;
//       case 2:
//         return <NotificationImportantIcon style={warningIcon} />;
//       case 3:
//         return <ErrorIcon style={errorIcon} />;
//       case 4:
//         return <InfoIcon style={infoIcon} />;
//       default:
//         return <InfoIcon style={infoIcon} />;
//     }
//   };

//   return (
//     <WarningBoxDiv type={type}>
//       {getIconByType(type)}
//       <Typography
//         style={
//           type === 1
//             ? successText
//             : type === 2
//             ? warningText
//             : type === 3
//             ? errorText
//             : infoText
//         }
//       >
//         {message}
//       </Typography>
//     </WarningBoxDiv>
//   );
// }
// export default WarningBox;

import React from 'react';
import { Typography } from '@mui/material';
import {
  WarningBoxDiv,
  successIcon,
  successText,
  warningIcon,
  warningText,
  errorIcon,
  errorText,
  infoIcon,
  infoText,
} from './WarningBoxStyles'; // Make sure your styles are correctly imported
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';

function WarningBox({ message, type }) {
  const getIconByType = (type) => {
    switch (type) {
      case 1:
        return <ThumbUpIcon style={successIcon} />;
      case 2:
        return <NotificationImportantIcon style={warningIcon} />;
      case 3:
        return <ErrorIcon style={errorIcon} />;
      case 4:
        return <InfoIcon style={infoIcon} />;
      default:
        return <InfoIcon style={infoIcon} />;
    }
  };

  return (
    <WarningBoxDiv type={type}>
      {getIconByType(type)}
      <Typography style={
          type === 1
            ? successText
            : type === 2
            ? warningText
            : type === 3
            ? errorText
            : infoText
        }>
        {message}
      </Typography>
    </WarningBoxDiv>
  );
}

export default WarningBox;

