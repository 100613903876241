//import APIProviders from "../data/data.json";

let APIProvider = window.APIProvider;

const DEFAULT_USERID = "-1";
let POST_HEADER = {
  method: "POST",
  headers: {
    "Content-type": "application/json",
    Accept: "application/json, text/plain, */*",
  },
  mode: "cors",
};

let GET_HEADER = {
  method: "GET",
  headers: {},
  mode: "cors",
};
function GetRequestHeader() {
  return GET_HEADER;
}

function PostRequestHeader() {
  return POST_HEADER;
}

// function GetAPI() {
//   return AquariusAPI;
// }

function GetDefaultUserID() {
  return DEFAULT_USERID;
}

async function GetOriginPorts() {
  console.log("start origin");
  const response = await fetch(
    APIProvider.baseURL + APIProvider.recordPath.GetOriginPorts,
    GET_HEADER
  );
  const origin = await response.json();
  console.log("origin ", origin);
  return origin;
}
async function GetDestinationPorts(value) {
  const response = await fetch(
    APIProvider.baseURL +
      APIProvider.recordPath.GetDestinationPorts +
      "?originPortID=" +
      value,
    GET_HEADER
  );
  const dest = await response.json();
  return dest;
}
async function GetTrips(origin, destination, date, passengerCount) {
  const response = await fetch(
    APIProvider.baseURL +
      APIProvider.recordPath.GetTrips +
      "?originPortID=" +
      origin +
      "&destinationPortID=" +
      destination +
      "&date=" +
      date +
      "&passengerCount=" +
      passengerCount,

    GET_HEADER
  );
  return response;
}
// async function GetRoundTrips(record) {
//   console.log("GET ROUND TRIP");
//   try {
//     const request = {
//       method: "POST",
//       headers: {
//         "Content-type": "application/json",
//         Accept: "application/json, text/plain, */*",
//       },
//       mode: "cors",
//       body: JSON.stringify(record),
//     };
//     const response = await fetch(
//       APIProvider.baseURL + APIProvider.recordPath.GetRoundTrips,
//       request
//     );
//     console.log("response ", await response);
//     return response;
//   } catch (error) {
//     console.error("Error ", error);
//   }
// }

// async function GetRoundTrips(record) {
//   try {
//     const stackTrace = new Error().stack;
//     // Parse stack trace to extract caller function information
//     const callerFunction = stackTrace.split("\n")[2].trim(); // Adjust the index based on the stack trace structure
//     console.log("Caller function: ", callerFunction);

//     const request = {
//       method: "POST",
//       headers: {
//         "Content-type": "application/json",
//         Accept: "application/json, text/plain, */*",
//       },
//       mode: "cors",
//       body: JSON.stringify(record),
//     };
//     const response = await fetch(
//       APIProvider.baseURL + APIProvider.recordPath.GetRoundTrips,
//       request
//     );
//     console.log("response ", await response);
//     return response.json();
//   } catch (error) {
//     console.error("Error ", error);
//   }
// }

async function GetRoundTrips(record) {
  try {
    const stackTrace = new Error().stack;
    // Parse stack trace to extract caller function information
    const callerFunction = stackTrace.split("\n")[2].trim(); // Adjust the index based on the stack trace structure
    console.log("Caller function: ", callerFunction);

    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      mode: "cors",
      body: JSON.stringify(record),
    };
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.GetRoundTrips,
      request
    );
    console.log("response ", await response);
    return response;
  } catch (error) {
    console.error("Error ", error);
  }
}

async function PurchaseTickets(ticketRequest) {
  try {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      mode: "cors",
      body: JSON.stringify(ticketRequest),
    };
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.PurchaseTickets,
      request
    );
    return response;
  } catch (error) {
    console.error("Error ", error);
  }
}

async function GetRequestTypes(type) {
  try {
    const response = await fetch(
      APIProvider.baseURL +
        APIProvider.recordPath.GetRequestTypes +
        "?type=" +
        type,
      GET_HEADER
    );
    const dest = await response.json();
    return dest;
  } catch (error) {
    console.error("Error ", error);
  }
}
async function GetTicketByID(TGUID, TripID) {
  try {
    const response = await fetch(
      APIProvider.baseURL +
        APIProvider.recordPath.GetTicketByID +
        "?ticketID=" +
        TGUID +
        "&tripID=" +
        TripID,
      GET_HEADER
    );
    const dest = await response.json();
    return dest;
  } catch (error) {
    console.error("Error ", error);
  }
}
async function GetPassengerInfoForTicket(ticketID) {
  try {
    const response = await fetch(
      APIProvider.baseURL +
        APIProvider.recordPath.GetPassengerInfo +
        "?ticketID=" +
        ticketID,
      GET_HEADER
    );
    const passInfo = await response.json();
    return passInfo;
  } catch (error) {
    console.error("Error ", error);
  }
}

async function PostTickets(ticketRequests) {
  console.log("ticketRequests ", ticketRequests);
  try {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      mode: "cors",
      body: JSON.stringify(ticketRequests),
    };
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.PostTicket,
      request
    );
    console.log("Post Tickets Response ", response);
    return await response.json();
  } catch (error) {
    console.error("Error ", error);
  }
}

//temp until return bug is fixed
async function GetTicketIDs(TGUID) {
  const response = await fetch(
    APIProvider.baseURL +
      APIProvider.recordPath.GetTicketIDs +
      "?TGUID=" +
      TGUID,
    GET_HEADER
  );
  const ticketIDs = await response.json();
  return ticketIDs;
}

async function PostTicketsComplement(postTicketComplementInfo) {
  try {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      mode: "cors",
      body: JSON.stringify(postTicketComplementInfo),
    };
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.PostTicketComplement,
      request
    );
    console.log("Post Tickets Complement Response ", response);
    return response;
  } catch (error) {
    console.error("Error ", error);
  }
}

async function PostPassengerContacts(contacts) {
  console.log("contacts ", contacts);
  try {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      mode: "cors",
      body: JSON.stringify(contacts),
    };
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.PostPassengerContacts,
      request
    );
    console.log("Post Passenger Contacts Response ", response);
    return await response.json();
  } catch (error) {
    console.error("Error ", error);
  }
}

async function GetRecieptInfo(TGUID) {
  const response = await fetch(
    APIProvider.baseURL +
      APIProvider.recordPath.GetRecieptInfo +
      "?TGUID=" +
      TGUID,
    GET_HEADER
  );
  const recieptInfo = await response.json();
  return recieptInfo;
}

async function GetCheckoutPopup() {
  try {
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.GetCheckoutPopup,
      GET_HEADER
    );
    const message = await response.json();
    return message;
  } catch (error) {
    console.error("Error ", error);
  }
}

async function PostMainPassengerContacts(contacts) {
  console.log("main contacts ", contacts);
  try {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      mode: "cors",
      body: JSON.stringify(contacts),
    };
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.PostMainPassengerContacts,
      request
    );
    console.log("Post Passenger Contacts Response ", response);
    return await response.json();
  } catch (error) {
    console.error("Error ", error);
  }
}

export {
  GetOriginPorts,
  GetDestinationPorts,
  GetTrips,
  GetRoundTrips,
  GetRequestTypes,
  PurchaseTickets,
  GetTicketByID,
  PostTickets,
  GetTicketIDs,
  PostTicketsComplement,
  GetRecieptInfo,
  PostPassengerContacts,
  GetCheckoutPopup,
  PostMainPassengerContacts,
};
