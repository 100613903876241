import React from "react";

function Terms() {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      This is the Terms and Conditions Page
    </div>
  );
}

export default Terms;
