import { Card, CardContent, ThemeProvider, Box, Link } from "@mui/material";
import React from "react";
import theme from "../../Theme";
export default function MenuCardComponent({ data, size, textSize, isMobile }) {
  const screenHeight = window.innerHeight;
  const cardHeight = isMobile ? "11rem" : "10rem";

  const handleCardClick = () => {
    if (data.link) {
      // If a link is provided in data, navigate to that link
      window.location.href = data.link;
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Card
        elevation={3}
        sx={{
          width: `${size}px`,
          height: cardHeight,
          marginBottom: "1rem",
          marginRight: "1rem",
          [theme.breakpoints.down("sm")]: {
            marginRight: 0,
          },
          cursor: data.link ? "pointer" : "default",
        }}
        onClick={handleCardClick}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <img
              src={data.mediaURL} // Use the mediaURL as the icon source
              alt={data.header} // Provide alt text for accessibility
              style={{
                width: "2rem",
                height: "2rem",
                marginRight: "0.5rem",
              }} // Adjust the icon size as needed
            />
            <p
              style={{
                color: theme.palette.primary.main,
                fontWeight: "bold",
                fontSize: textSize,
              }}
            >
              {data.header}
            </p>
          </Box>
          <Box>
            <div
              style={{ fontSize: textSize * 0.75 }}
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
          </Box>
        </CardContent>
      </Card>
    </ThemeProvider>
  );
}
