export const areaCodes = [
  {
    country: "Afghanistan",
    prefix: "+93",
    flag: "https://flagicons.lipis.dev/flags/4x3/af.svg",
  },
  {
    country: "Albania",
    prefix: "+355",
    flag: "https://flagicons.lipis.dev/flags/4x3/al.svg",
  },
  {
    country: "Algeria",
    prefix: "+213",
    flag: "https://flagicons.lipis.dev/flags/4x3/dz.svg",
  },
  {
    country: "Andorra",
    prefix: "+376",
    flag: "https://flagicons.lipis.dev/flags/4x3/ad.svg",
  },
  {
    country: "Angola",
    prefix: "+244",
    flag: "https://flagicons.lipis.dev/flags/4x3/ao.svg",
  },
  {
    country: "Antigua",
    prefix: "+1-268",
    flag: "https://flagicons.lipis.dev/flags/4x3/ai.svg",
  },
  {
    country: "Argentina",
    prefix: "+54",
    flag: "https://flagicons.lipis.dev/flags/4x3/ar.svg",
  },
  {
    country: "Armenia",
    prefix: "+374",
    flag: "https://flagicons.lipis.dev/flags/4x3/am.svg",
  },
  {
    country: "Australia",
    prefix: "+61",
    flag: "https://flagicons.lipis.dev/flags/4x3/au.svg",
  },
  {
    country: "Austria",
    prefix: "+43",
    flag: "https://flagicons.lipis.dev/flags/4x3/at.svg",
  },
  {
    country: "Azerbaijan",
    prefix: "+994",
    flag: "https://flagicons.lipis.dev/flags/4x3/az.svg",
  },
  {
    country: "Bahamas",
    prefix: "+1-242",
    flag: "https://flagicons.lipis.dev/flags/4x3/bs.svg",
  },
  {
    country: "Bahrain",
    prefix: "+973",
    flag: "https://flagicons.lipis.dev/flags/4x3/bh.svg",
  },
  {
    country: "Bangladesh",
    prefix: "+880",
    flag: "https://flagicons.lipis.dev/flags/4x3/bd.svg",
  },
  {
    country: "Barbados",
    prefix: "+1-246",
    flag: "https://flagicons.lipis.dev/flags/4x3/bb.svg",
  },
  {
    country: "Barbuda",
    prefix: "+1-268",
    flag: "https://flagicons.lipis.dev/flags/4x3/ag.svg",
  },
  {
    country: "Belarus",
    prefix: "+375",
    flag: "https://flagicons.lipis.dev/flags/4x3/by.svg",
  },
  {
    country: "Belgium",
    prefix: "+32",
    flag: "https://flagicons.lipis.dev/flags/4x3/be.svg",
  },
  {
    country: "Belize",
    prefix: "+501",
    flag: "https://flagicons.lipis.dev/flags/4x3/bz.svg",
  },
  {
    country: "Benin",
    prefix: "+229",
    flag: "https://flagicons.lipis.dev/flags/4x3/bj.svg",
  },
  {
    country: "Bhutan",
    prefix: "+975",
    flag: "https://flagicons.lipis.dev/flags/4x3/bt.svg",
  },
  {
    country: "Bolivia",
    prefix: "+591",
    flag: "https://flagicons.lipis.dev/flags/4x3/bo.svg",
  },
  {
    country: "Bosnia and Herzegovina",
    prefix: "+387",
    flag: "https://flagicons.lipis.dev/flags/4x3/ba.svg",
  },
  {
    country: "Botswana",
    prefix: "+267",
    flag: "https://flagicons.lipis.dev/flags/4x3/bw.svg",
  },
  {
    country: "Brazil",
    prefix: "+55",
    flag: "https://flagicons.lipis.dev/flags/4x3/br.svg",
  },
  {
    country: "Brunei",
    prefix: "+673",
    flag: "https://flagicons.lipis.dev/flags/4x3/bn.svg",
  },
  {
    country: "Bulgaria",
    prefix: "+359",
    flag: "https://flagicons.lipis.dev/flags/4x3/bg.svg",
  },
  {
    country: "Burkina Faso",
    prefix: "+226",
    flag: "https://flagicons.lipis.dev/flags/4x3/bf.svg",
  },
  {
    country: "Burundi",
    prefix: "+257",
    flag: "https://flagicons.lipis.dev/flags/4x3/bi.svg",
  },
  {
    country: "Cambodia",
    prefix: "+855",
    flag: "https://flagicons.lipis.dev/flags/4x3/kh.svg",
  },
  {
    country: "Cameroon",
    prefix: "+237",
    flag: "https://flagicons.lipis.dev/flags/4x3/cm.svg",
  },
  {
    country: "Canada",
    prefix: "+1",
    flag: "https://flagicons.lipis.dev/flags/4x3/ca.svg",
  },
  {
    country: "Cape Verde",
    prefix: "+238",
    flag: "https://flagicons.lipis.dev/flags/4x3/cv.svg",
  },
  {
    country: "Central African Republic",
    prefix: "+236",
    flag: "https://flagicons.lipis.dev/flags/4x3/cf.svg",
  },
  {
    country: "Chad",
    prefix: "+235",
    flag: "https://flagicons.lipis.dev/flags/4x3/td.svg",
  },
  {
    country: "Chile",
    prefix: "+56",
    flag: "https://flagicons.lipis.dev/flags/4x3/cl.svg",
  },
  {
    country: "China",
    prefix: "+86",
    flag: "https://flagicons.lipis.dev/flags/4x3/cn.svg",
  },
  {
    country: "Colombia",
    prefix: "+57",
    flag: "https://flagicons.lipis.dev/flags/4x3/co.svg",
  },
  {
    country: "Comoros",
    prefix: "+269",
    flag: "https://flagicons.lipis.dev/flags/4x3/km.svg",
  },
  {
    country: "Congo",
    prefix: "+242",
    flag: "https://flagicons.lipis.dev/flags/4x3/cg.svg",
  },
  {
    country: "Costa Rica",
    prefix: "+506",
    flag: "https://flagicons.lipis.dev/flags/4x3/cr.svg",
  },
  {
    country: "Croatia",
    prefix: "+385",
    flag: "https://flagicons.lipis.dev/flags/4x3/hr.svg",
  },
  {
    country: "Cuba",
    prefix: "+53",
    flag: "https://flagicons.lipis.dev/flags/4x3/cu.svg",
  },
  {
    country: "Cyprus",
    prefix: "+357",
    flag: "https://flagicons.lipis.dev/flags/4x3/cy.svg",
  },
  {
    country: "Czech Republic",
    prefix: "+420",
    flag: "https://flagicons.lipis.dev/flags/4x3/cz.svg",
  },
  {
    country: "Denmark",
    prefix: "+45",
    flag: "https://flagicons.lipis.dev/flags/4x3/dk.svg",
  },
  {
    country: "Djibouti",
    prefix: "+253",
    flag: "https://flagicons.lipis.dev/flags/4x3/dj.svg",
  },
  {
    country: "Dominica",
    prefix: "+1-767",
    flag: "https://flagicons.lipis.dev/flags/4x3/dm.svg",
  },
  {
    country: "Dominican Republic",
    prefix: "+1-809",
    flag: "https://flagicons.lipis.dev/flags/4x3/do.svg",
  },
  {
    country: "Dominican Republic",
    prefix: "+1-829",
    flag: "https://flagicons.lipis.dev/flags/4x3/do.svg",
  },
  {
    country: "Dominican Republic",
    prefix: "+1-849",
    flag: "https://flagicons.lipis.dev/flags/4x3/do.svg",
  },
  {
    country: "Fiji",
    prefix: "+679",
    flag: "https://flagicons.lipis.dev/flags/4x3/fj.svg",
  },
  {
    country: "Finland",
    prefix: "+358",
    flag: "https://flagicons.lipis.dev/flags/4x3/fi.svg",
  },
  {
    country: "France",
    prefix: "+33",
    flag: "https://flagicons.lipis.dev/flags/4x3/fr.svg",
  },
  {
    country: "Haiti",
    prefix: "+509",
    flag: "https://flagicons.lipis.dev/flags/4x3/ht.svg",
  },
  {
    country: "Honduras",
    prefix: "+504",
    flag: "https://flagicons.lipis.dev/flags/4x3/hn.svg",
  },
  {
    country: "Hungary",
    prefix: "+36",
    flag: "https://flagicons.lipis.dev/flags/4x3/hu.svg",
  },
  {
    country: "Iceland",
    prefix: "+354",
    flag: "https://flagicons.lipis.dev/flags/4x3/is.svg",
  },
  {
    country: "India",
    prefix: "+91",
    flag: "https://flagicons.lipis.dev/flags/4x3/in.svg",
  },
  {
    country: "Indonesia",
    prefix: "+62",
    flag: "https://flagicons.lipis.dev/flags/4x3/id.svg",
  },
  {
    country: "Iran",
    prefix: "+98",
    flag: "https://flagicons.lipis.dev/flags/4x3/ir.svg",
  },
  {
    country: "Iraq",
    prefix: "+964",
    flag: "https://flagicons.lipis.dev/flags/4x3/iq.svg",
  },
  {
    country: "Ireland",
    prefix: "+353",
    flag: "https://flagicons.lipis.dev/flags/4x3/ie.svg",
  },
  {
    country: "Israel",
    prefix: "+972",
    flag: "https://flagicons.lipis.dev/flags/4x3/il.svg",
  },
  {
    country: "Italy",
    prefix: "+39",
    flag: "https://flagicons.lipis.dev/flags/4x3/it.svg",
  },
  {
    country: "Jamaica",
    prefix: "+1-876",
    flag: "https://flagicons.lipis.dev/flags/4x3/jm.svg",
  },
  {
    country: "Japan",
    prefix: "+81",
    flag: "https://flagicons.lipis.dev/flags/4x3/jp.svg",
  },
  {
    country: "Jordan",
    prefix: "+962",
    flag: "https://flagicons.lipis.dev/flags/4x3/jo.svg",
  },
  {
    country: "Kazakhstan",
    prefix: "+7",
    flag: "https://flagicons.lipis.dev/flags/4x3/kz.svg",
  },
  {
    country: "Kenya",
    prefix: "+254",
    flag: "https://flagicons.lipis.dev/flags/4x3/ke.svg",
  },
  {
    country: "Kiribati",
    prefix: "+686",
    flag: "https://flagicons.lipis.dev/flags/4x3/ki.svg",
  },
  {
    country: "Korea, North",
    prefix: "+850",
    flag: "https://flagicons.lipis.dev/flags/4x3/kp.svg",
  },
  {
    country: "Korea, South",
    prefix: "+82",
    flag: "https://flagicons.lipis.dev/flags/4x3/kr.svg",
  },
  {
    country: "Kosovo",
    prefix: "+383",
    flag: "https://flagicons.lipis.dev/flags/4x3/xk.svg",
  },
  {
    country: "Kuwait",
    prefix: "+965",
    flag: "https://flagicons.lipis.dev/flags/4x3/kw.svg",
  },
  {
    country: "Kyrgyzstan",
    prefix: "+996",
    flag: "https://flagicons.lipis.dev/flags/4x3/kg.svg",
  },
  {
    country: "Laos",
    prefix: "+856",
    flag: "https://flagicons.lipis.dev/flags/4x3/la.svg",
  },
  {
    country: "Latvia",
    prefix: "+371",
    flag: "https://flagicons.lipis.dev/flags/4x3/lv.svg",
  },
  {
    country: "Lebanon",
    prefix: "+961",
    flag: "https://flagicons.lipis.dev/flags/4x3/lb.svg",
  },
  {
    country: "Lesotho",
    prefix: "+266",
    flag: "https://flagicons.lipis.dev/flags/4x3/ls.svg",
  },
  {
    country: "Liberia",
    prefix: "+231",
    flag: "https://flagicons.lipis.dev/flags/4x3/lr.svg",
  },
  {
    country: "Libya",
    prefix: "+218",
    flag: "https://flagicons.lipis.dev/flags/4x3/ly.svg",
  },
  {
    country: "Liechtenstein",
    prefix: "+423",
    flag: "https://flagicons.lipis.dev/flags/4x3/li.svg",
  },
  {
    country: "Lithuania",
    prefix: "+370",
    flag: "https://flagicons.lipis.dev/flags/4x3/lt.svg",
  },
  {
    country: "Luxembourg",
    prefix: "+352",
    flag: "https://flagicons.lipis.dev/flags/4x3/lu.svg",
  },
  {
    country: "Madagascar",
    prefix: "+261",
    flag: "https://flagicons.lipis.dev/flags/4x3/mg.svg",
  },
  {
    country: "Malawi",
    prefix: "+265",
    flag: "https://flagicons.lipis.dev/flags/4x3/mw.svg",
  },
  {
    country: "Malaysia",
    prefix: "+60",
    flag: "https://flagicons.lipis.dev/flags/4x3/my.svg",
  },
  {
    country: "Maldives",
    prefix: "+960",
    flag: "https://flagicons.lipis.dev/flags/4x3/mv.svg",
  },
  {
    country: "Mali",
    prefix: "+223",
    flag: "https://flagicons.lipis.dev/flags/4x3/ml.svg",
  },
  {
    country: "Malta",
    prefix: "+356",
    flag: "https://flagicons.lipis.dev/flags/4x3/mt.svg",
  },
  {
    country: "Marshall Islands",
    prefix: "+692",
    flag: "https://flagicons.lipis.dev/flags/4x3/mh.svg",
  },
  {
    country: "Mauritania",
    prefix: "+222",
    flag: "https://flagicons.lipis.dev/flags/4x3/mr.svg",
  },
  {
    country: "Mauritius",
    prefix: "+230",
    flag: "https://flagicons.lipis.dev/flags/4x3/mu.svg",
  },
  {
    country: "Mexico",
    prefix: "+52",
    flag: "https://flagicons.lipis.dev/flags/4x3/mx.svg",
  },
  {
    country: "Micronesia",
    prefix: "+691",
    flag: "https://flagicons.lipis.dev/flags/4x3/fm.svg",
  },
  {
    country: "Moldova",
    prefix: "+373",
    flag: "https://flagicons.lipis.dev/flags/4x3/md.svg",
  },
  {
    country: "Monaco",
    prefix: "+377",
    flag: "https://flagicons.lipis.dev/flags/4x3/mc.svg",
  },
  {
    country: "Mongolia",
    prefix: "+976",
    flag: "https://flagicons.lipis.dev/flags/4x3/mn.svg",
  },
  {
    country: "Montenegro",
    prefix: "+382",
    flag: "https://flagicons.lipis.dev/flags/4x3/me.svg",
  },
  {
    country: "Morocco",
    prefix: "+212",
    flag: "https://flagicons.lipis.dev/flags/4x3/ma.svg",
  },
  {
    country: "Mozambique",
    prefix: "+258",
    flag: "https://flagicons.lipis.dev/flags/4x3/mz.svg",
  },
  {
    country: "Myanmar",
    prefix: "+95",
    flag: "https://flagicons.lipis.dev/flags/4x3/mm.svg",
  },
  {
    country: "Namibia",
    prefix: "+264",
    flag: "https://flagicons.lipis.dev/flags/4x3/na.svg",
  },
  {
    country: "Nauru",
    prefix: "+674",
    flag: "https://flagicons.lipis.dev/flags/4x3/nr.svg",
  },
  {
    country: "Nepal",
    prefix: "+977",
    flag: "https://flagicons.lipis.dev/flags/4x3/np.svg",
  },
  {
    country: "Netherlands",
    prefix: "+31",
    flag: "https://flagicons.lipis.dev/flags/4x3/nl.svg",
  },
  {
    country: "New Zealand",
    prefix: "+64",
    flag: "https://flagicons.lipis.dev/flags/4x3/nz.svg",
  },
  {
    country: "Nicaragua",
    prefix: "+505",
    flag: "https://flagicons.lipis.dev/flags/4x3/ni.svg",
  },
  {
    country: "Niger",
    prefix: "+227",
    flag: "https://flagicons.lipis.dev/flags/4x3/ne.svg",
  },
  {
    country: "Nigeria",
    prefix: "+234",
    flag: "https://flagicons.lipis.dev/flags/4x3/ng.svg",
  },
  {
    country: "North Korea",
    prefix: "+850",
    flag: "https://flagicons.lipis.dev/flags/4x3/kp.svg",
  },
  {
    country: "North Macedonia",
    prefix: "+389",
    flag: "https://flagicons.lipis.dev/flags/4x3/mk.svg",
  },
  {
    country: "Norway",
    prefix: "+47",
    flag: "https://flagicons.lipis.dev/flags/4x3/no.svg",
  },
  {
    country: "Oman",
    prefix: "+968",
    flag: "https://flagicons.lipis.dev/flags/4x3/om.svg",
  },
  {
    country: "Pakistan",
    prefix: "+92",
    flag: "https://flagicons.lipis.dev/flags/4x3/pk.svg",
  },
  {
    country: "Palau",
    prefix: "+680",
    flag: "https://flagicons.lipis.dev/flags/4x3/pw.svg",
  },
  {
    country: "Palestine",
    prefix: "+970",
    flag: "https://flagicons.lipis.dev/flags/4x3/ps.svg",
  },
  {
    country: "Panama",
    prefix: "+507",
    flag: "https://flagicons.lipis.dev/flags/4x3/pa.svg",
  },
  {
    country: "Papua New Guinea",
    prefix: "+675",
    flag: "https://flagicons.lipis.dev/flags/4x3/pg.svg",
  },
  {
    country: "Paraguay",
    prefix: "+595",
    flag: "https://flagicons.lipis.dev/flags/4x3/py.svg",
  },
  {
    country: "Peru",
    prefix: "+51",
    flag: "https://flagicons.lipis.dev/flags/4x3/pe.svg",
  },
  {
    country: "Philippines",
    prefix: "+63",
    flag: "https://flagicons.lipis.dev/flags/4x3/ph.svg",
  },
  {
    country: "Poland",
    prefix: "+48",
    flag: "https://flagicons.lipis.dev/flags/4x3/pl.svg",
  },
  {
    country: "Portugal",
    prefix: "+351",
    flag: "https://flagicons.lipis.dev/flags/4x3/pt.svg",
  },
  {
    country: "Qatar",
    prefix: "+974",
    flag: "https://flagicons.lipis.dev/flags/4x3/qa.svg",
  },
  {
    country: "Romania",
    prefix: "+40",
    flag: "https://flagicons.lipis.dev/flags/4x3/ro.svg",
  },
  {
    country: "Russia",
    prefix: "+7",
    flag: "https://flagicons.lipis.dev/flags/4x3/ru.svg",
  },
  {
    country: "Rwanda",
    prefix: "+250",
    flag: "https://flagicons.lipis.dev/flags/4x3/rw.svg",
  },
  {
    country: "Saint Kitts and Nevis",
    prefix: "+1-869",
    flag: "https://flagicons.lipis.dev/flags/4x3/kn.svg",
  },
  {
    country: "Saint Lucia",
    prefix: "+1-758",
    flag: "https://flagicons.lipis.dev/flags/4x3/lc.svg",
  },
  {
    country: "Saint Vincent and the Grenadines",
    prefix: "+1-784",
    flag: "https://flagicons.lipis.dev/flags/4x3/vc.svg",
  },
  {
    country: "Samoa",
    prefix: "+685",
    flag: "https://flagicons.lipis.dev/flags/4x3/ws.svg",
  },
  {
    country: "San Marino",
    prefix: "+378",
    flag: "https://flagicons.lipis.dev/flags/4x3/sm.svg",
  },
  {
    country: "Sao Tome and Principe",
    prefix: "+239",
    flag: "https://flagicons.lipis.dev/flags/4x3/st.svg",
  },
  {
    country: "Saudi Arabia",
    prefix: "+966",
    flag: "https://flagicons.lipis.dev/flags/4x3/sa.svg",
  },
  {
    country: "Senegal",
    prefix: "+221",
    flag: "https://flagicons.lipis.dev/flags/4x3/sn.svg",
  },
  {
    country: "Serbia",
    prefix: "+381",
    flag: "https://flagicons.lipis.dev/flags/4x3/rs.svg",
  },
  {
    country: "Seychelles",
    prefix: "+248",
    flag: "https://flagicons.lipis.dev/flags/4x3/sc.svg",
  },
  {
    country: "Sierra Leone",
    prefix: "+232",
    flag: "https://flagicons.lipis.dev/flags/4x3/sl.svg",
  },
  {
    country: "Singapore",
    prefix: "+65",
    flag: "https://flagicons.lipis.dev/flags/4x3/sg.svg",
  },
  {
    country: "Slovakia",
    prefix: "+421",
    flag: "https://flagicons.lipis.dev/flags/4x3/sk.svg",
  },
  {
    country: "Slovenia",
    prefix: "+386",
    flag: "https://flagicons.lipis.dev/flags/4x3/si.svg",
  },
  {
    country: "Solomon Islands",
    prefix: "+677",
    flag: "https://flagicons.lipis.dev/flags/4x3/sb.svg",
  },
  {
    country: "Somalia",
    prefix: "+252",
    flag: "https://flagicons.lipis.dev/flags/4x3/so.svg",
  },
  {
    country: "South Africa",
    prefix: "+27",
    flag: "https://flagicons.lipis.dev/flags/4x3/za.svg",
  },
  {
    country: "South Sudan",
    prefix: "+211",
    flag: "https://flagicons.lipis.dev/flags/4x3/ss.svg",
  },
  {
    country: "Spain",
    prefix: "+34",
    flag: "https://flagicons.lipis.dev/flags/4x3/es.svg",
  },
  {
    country: "Sri Lanka",
    prefix: "+94",
    flag: "https://flagicons.lipis.dev/flags/4x3/lk.svg",
  },
  {
    country: "Sudan",
    prefix: "+249",
    flag: "https://flagicons.lipis.dev/flags/4x3/sd.svg",
  },
  {
    country: "Suriname",
    prefix: "+597",
    flag: "https://flagicons.lipis.dev/flags/4x3/sr.svg",
  },
  {
    country: "Swaziland",
    prefix: "+268",
    flag: "https://flagicons.lipis.dev/flags/4x3/sz.svg",
  },
  {
    country: "Sweden",
    prefix: "+46",
    flag: "https://flagicons.lipis.dev/flags/4x3/se.svg",
  },
  {
    country: "Switzerland",
    prefix: "+41",
    flag: "https://flagicons.lipis.dev/flags/4x3/ch.svg",
  },
  {
    country: "Syria",
    prefix: "+963",
    flag: "https://flagicons.lipis.dev/flags/4x3/sy.svg",
  },
  {
    country: "Taiwan",
    prefix: "+886",
    flag: "https://flagicons.lipis.dev/flags/4x3/tw.svg",
  },
  {
    country: "Tajikistan",
    prefix: "+992",
    flag: "https://flagicons.lipis.dev/flags/4x3/tj.svg",
  },
  {
    country: "Tanzania",
    prefix: "+255",
    flag: "https://flagicons.lipis.dev/flags/4x3/tz.svg",
  },
  {
    country: "Thailand",
    prefix: "+66",
    flag: "https://flagicons.lipis.dev/flags/4x3/th.svg",
  },
  {
    country: "Timor-Leste",
    prefix: "+670",
    flag: "https://flagicons.lipis.dev/flags/4x3/tl.svg",
  },
  {
    country: "Togo",
    prefix: "+228",
    flag: "https://flagicons.lipis.dev/flags/4x3/tg.svg",
  },
  {
    country: "Tonga",
    prefix: "+676",
    flag: "https://flagicons.lipis.dev/flags/4x3/to.svg",
  },
  {
    country: "Trinidad and Tobago",
    prefix: "+1-868",
    flag: "https://flagicons.lipis.dev/flags/4x3/tt.svg",
  },
  {
    country: "Tunisia",
    prefix: "+216",
    flag: "https://flagicons.lipis.dev/flags/4x3/tn.svg",
  },
  {
    country: "Turkey",
    prefix: "+90",
    flag: "https://flagicons.lipis.dev/flags/4x3/tr.svg",
  },
  {
    country: "Turkmenistan",
    prefix: "+993",
    flag: "https://flagicons.lipis.dev/flags/4x3/tm.svg",
  },
  {
    country: "Tuvalu",
    prefix: "+688",
    flag: "https://flagicons.lipis.dev/flags/4x3/tv.svg",
  },
  {
    country: "Uganda",
    prefix: "+256",
    flag: "https://flagicons.lipis.dev/flags/4x3/ug.svg",
  },
  {
    country: "Ukraine",
    prefix: "+380",
    flag: "https://flagicons.lipis.dev/flags/4x3/ua.svg",
  },
  {
    country: "United Arab Emirates",
    prefix: "+971",
    flag: "https://flagicons.lipis.dev/flags/4x3/ae.svg",
  },
  {
    country: "United Kingdom",
    prefix: "+44",
    flag: "https://flagicons.lipis.dev/flags/4x3/gb.svg",
  },
  {
    country: "United States",
    prefix: "+1",
    flag: "https://flagicons.lipis.dev/flags/4x3/us.svg",
  },
  {
    country: "Uruguay",
    prefix: "+598",
    flag: "https://flagicons.lipis.dev/flags/4x3/uy.svg",
  },
  {
    country: "Uzbekistan",
    prefix: "+998",
    flag: "https://flagicons.lipis.dev/flags/4x3/uz.svg",
  },
  {
    country: "Vanuatu",
    prefix: "+678",
    flag: "https://flagicons.lipis.dev/flags/4x3/vu.svg",
  },
  {
    country: "Vatican City",
    prefix: "+379",
    flag: "https://flagicons.lipis.dev/flags/4x3/va.svg",
  },
  {
    country: "Venezuela",
    prefix: "+58",
    flag: "https://flagicons.lipis.dev/flags/4x3/ve.svg",
  },
  {
    country: "Vietnam",
    prefix: "+84",
    flag: "https://flagicons.lipis.dev/flags/4x3/vn.svg",
  },
  {
    country: "Wallis and Futuna",
    prefix: "+681",
    flag: "https://flagicons.lipis.dev/flags/4x3/wf.svg",
  },
  {
    country: "Yemen",
    prefix: "+967",
    flag: "https://flagicons.lipis.dev/flags/4x3/ye.svg",
  },
  {
    country: "Zambia",
    prefix: "+260",
    flag: "https://flagicons.lipis.dev/flags/4x3/zm.svg",
  },
  {
    country: "Zimbabwe",
    prefix: "+263",
    flag: "https://flagicons.lipis.dev/flags/4x3/zw.svg",
  },
];
