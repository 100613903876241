import { styled } from "@mui/material/styles";
import theme from "../../Theme";
import FerryBG from "../../assets/FerryBG.jpg";

export const FormField = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  height: "",
}));
export const FormLabel = {
  marginRight: theme.spacing(2),
};

export const StyledDiv = styled("div")({
  backgroundImage: `url(${FerryBG})`,
  backgroundSize: "cover",
  minHeight: "100vh",
  minWidth: "100vw",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "-5.313rem", //margin top from layout = 5.313
});
// export const StyledDiv = styled("div")({
//   backgroundImage: `url(${FerryBG})`,
//   backgroundSize: "cover",
//   minHeight: "100vh",
//   minWidth: "100vw",
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   justifyContent: "center",
//   marginTop: "-5.313rem", // margin top from layout
// });
export const StyledCard = styled("div")({
  // width: 1200,
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  borderRadius: 10,
  padding: theme.spacing(2),
  marginTop: "2.313rem", // nav bar height =4.625 half of it is 2.313
});
