import React, { useState } from "react";
import { Box, ThemeProvider, Typography, Stack } from "@mui/material";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import theme from "../Theme";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Chat from "../pages/Chat";
import Addons from "../pages/Addons";
const ShowTreeComponent = (props) => {
  const { children, activeTree, selectedTree } = props;
  return (
    <div hidden={activeTree !== selectedTree}>
      {activeTree === selectedTree && <Box mx={2}>{children}</Box>}
    </div>
  );
};
function TreeViewComponent(jsonData) {
  const [activeTree, setActiveTree] = useState();
  const treeHeading = ["Slider", "Portal", "Stepper", "Modal"];
  const treeSubHeading = [
    "Slider One",
    "Portal One",
    "Stepper One",
    "Modal One",
  ];
  const handleSelect = (event, selectedTree) => {
    setActiveTree(selectedTree);
  };
  return (
    <ThemeProvider theme={theme}>
      <Box width="100%">
        <Typography variant="h5" color="secondary" align="center">
          Tree View
        </Typography>
        <Stack spacing={3} direction="row">
          <Box width="10%">
            <TreeView
              defaultExpandIcon={<ExpandMoreIcon />}
              defaultCollapseIcon={<ChevronRightIcon />}
              onNodeSelect={handleSelect}
            >
              {treeHeading.map((treeItem, index) => (
                <TreeItem nodeId={index} label={treeItem}>
                  <TreeItem
                    nodeId={treeSubHeading[index]}
                    label={treeSubHeading[index]}
                  ></TreeItem>
                </TreeItem>
              ))}
            </TreeView>
          </Box>
          <Box with="20%">
            <ShowTreeComponent
              activeTree={activeTree}
              selectedTree="Slider One"
            >
              <Addons />
            </ShowTreeComponent>
            <ShowTreeComponent
              activeTree={activeTree}
              selectedTree="Portal One"
            >
              <Chat />
            </ShowTreeComponent>
            <ShowTreeComponent
              activeTree={activeTree}
              selectedTree="Stepper One"
            ></ShowTreeComponent>
            <ShowTreeComponent
              activeTree={activeTree}
              selectedTree="Modal One"
            ></ShowTreeComponent>
          </Box>
        </Stack>
      </Box>
    </ThemeProvider>
  );
}
export default TreeViewComponent;
