import React, { useState } from "react";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Button, ThemeProvider } from "@mui/material";
import PopUp from "./PopUp";
import DynamicPage from "./DynamicPage";
import theme from "../Theme";
import { WriteArray } from "../apiCalls/EditorAPI";
import jsonData from "../data/treeView.json";

function RecursiveTreeView({ data }) {
  console.log("data ", data);
  const [choice, setChoice] = useState("");
  //   const [items, setItems] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedParentItem, setSelectedParentItem] = useState(null);

  const handleTreeItemClick = (item, parent) => {
    setSelectedItem(item);
    setSelectedParentItem(parent);
    console.log("item clicked on ", item);
  };

  const addChildToSelectedItem = (item, choice) => {
    if (item && item.type === "Container") {
      const newItem = {
        id: `${item.id}.${item.control ? item.control.length + 1 : 1}`,
        type: choice,
        name: choice.toLowerCase(),
        data: choice.toLowerCase(),
        parentid: item.id,
      };

      item.control = [...(item.control || []), newItem];
    } else if (item) {
      const newItem = {
        id: `${selectedParentItem ? selectedParentItem.id : 0}.${
          selectedParentItem
            ? selectedParentItem.control
              ? selectedParentItem.control.length + 1
              : 1
            : data.control
            ? data.control.length + 1
            : 1
        }`,
        type: choice,
        name: choice.toLowerCase(),
        data: choice.toLowerCase(),
        parentid: selectedParentItem ? selectedParentItem.id : 0,
      };
      const parentControl =
        item.parentid !== 0 ? selectedParentItem.control : data.control;
      const selectedIndex = parentControl.findIndex(
        (control) => control.id === item.id
      );

      // Insert the new item immediately after the selected item
      parentControl.splice(selectedIndex + 1, 0, newItem);
    }

    setChoice("");
  };

  const renderTreeItems = (items, parent = 0) => {
    console.log("items ", items);
    return items.map((item) => (
      <TreeItem
        key={item.id}
        nodeId={item.id}
        label={item.type}
        onClick={() => handleTreeItemClick(item, parent)}
        draggable // Add draggable attribute
        onDragStart={(e) => {
          e.dataTransfer.setData("text/plain", item.id);
        }}
      >
        {item.control && renderTreeItems(item.control, item)}
      </TreeItem>
    ));
  };
  // const renderTreeItems = (items, parent = 0) => {
  //   console.log("items ", items);
  //   return items.map((item) => (
  //     <TreeItem
  //       key={item.id}
  //       nodeId={item.id}
  //       label={item.type}
  //       onClick={() => handleTreeItemClick(item, parent)}
  //     >
  //       {item.control && renderTreeItems(item.control, item)}
  //     </TreeItem>
  //   ));
  // };

  function onClose() {
    setOpen(false);
  }

  function onClick(event) {
    setOpen(true);
  }

  async function onSaveClick(event) {
    let array = {
      ArID: "1",
      ArrayName: "Test Array",
      Description: "This is the array being used to develop this feature",
      source: JSON.stringify(data, null, 2).replace(/"/g, "~"),
    };
    console.log("array ", array);
    const response = await WriteArray(array);
  }
  function exportJSON() {
    // Convert the updated data to a JSON string
    const updatedDataJson = JSON.stringify(data);

    // Create a Blob with the JSON data
    const blob = new Blob([updatedDataJson], { type: "application/json" });

    // Create an object URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a hidden <a> element for downloading
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = "updatedData.json";

    // Simulate a click event to trigger the download
    downloadLink.click();

    // Clean up the object URL
    URL.revokeObjectURL(url);
  }

  const handleDrop = (draggedItemId, targetItemId) => {
    // Find the source and target items in your data structure
    const sourceItem = findItemById(data, draggedItemId);
    const targetItem = findItemById(data, targetItemId);

    if (!sourceItem || !targetItem) {
      return;
    }

    // Remove the source item from its parent control
    const sourceParentControl = findParentControl(data, sourceItem);
    const sourceIndex = sourceParentControl.findIndex(
      (item) => item.id === sourceItem.id
    );
    sourceParentControl.splice(sourceIndex, 1);

    // Insert the source item as a child of the target item
    targetItem.control = targetItem.control || [];
    targetItem.control.push(sourceItem);

    // Update the state to trigger a re-render
    // setData({ ...data });
  };

  // Helper function to find an item by ID
  const findItemById = (item, id) => {
    if (item.id === id) {
      return item;
    }

    if (item.control) {
      for (const child of item.control) {
        const found = findItemById(child, id);
        if (found) {
          return found;
        }
      }
    }

    return null;
  };

  // Helper function to find the parent control of an item
  const findParentControl = (root, item) => {
    if (!root.control) {
      return null;
    }

    if (root.control.includes(item)) {
      return root.control;
    }

    for (const child of root.control) {
      const parentControl = findParentControl(child, item);
      if (parentControl) {
        return parentControl;
      }
    }

    return null;
  };

  return (
    <ThemeProvider theme={theme}>
      <Box ml={1} width="100%" display="flex">
        <Box width="20%">
          <Button variant="contained" onClick={onClick}>
            Add Control
          </Button>
          {/* <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            {renderTreeItems(data.control)}
          </TreeView> */}
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            onDragOver={(e) => e.preventDefault()} // Prevent the default behavior of dropping
            onDrop={(e) => {
              e.preventDefault();

              const draggedItemId = e.dataTransfer.getData("text/plain");
              const targetItemId = e.target.getAttribute("data-nodeid");

              // Implement the logic to handle the drop here
              handleDrop(draggedItemId, targetItemId);
            }}
          >
            {renderTreeItems(data.control)}
          </TreeView>
          {open && (
            <PopUp
              open={open}
              onClose={onClose}
              setChoice={(value) => {
                setChoice(value);
                addChildToSelectedItem(selectedItem, value);
              }}
            />
          )}
          <Button color="secondary" variant="contained" onClick={onSaveClick}>
            Save
          </Button>
        </Box>
        <Box width="50%">
          <DynamicPage items={data.control} />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default RecursiveTreeView;
