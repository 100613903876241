import React from "react";
import { SplitScreen } from "./SplitScreen";
import LeftRegistrationFormComponent from "./LeftRegistrationFormComponent";
import FormBusiness from "./rightRegistrationForm/FormBusiness";

function RegistrationForm({ shortForm }) {
  return (
    <SplitScreen leftWeight={1} rightWeight={2}>
      <LeftRegistrationFormComponent
        mainHeading={window.mainHeadingForLeftForm}
        listItems={window.listItemsforLeftForm}
        image={window.imageLeft}
      />
      <FormBusiness shortForm={shortForm} />
    </SplitScreen>
  );
}

export default RegistrationForm;
