// import APIProviders from "../data/data.json";
import { Container, Box, Typography } from "@mui/material";
import theme from "../Theme";

// const AQUARIUS_IFRAME_INDEX = 1;
// const AquariusIFrame = APIProviders[AQUARIUS_IFRAME_INDEX];

let IframeProvider = window.IframeProvider;

function InformationPage() {
  return (
    <Container
      maxWidth="lg"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Box
        sx={{
          flexGrow: 1,
          padding: "1rem",
          paddingTop: "5rem",
          marginRight: "2rem",
        }}
      >
        <Typography
          className="text-center"
          style={{
            color: theme.palette.primary.main,
            fontWeight: "bold",
            fontSize: "2rem",
          }}
        >
          Information Page
        </Typography>
        <iframe
          style={{ minHeight: "50rem", minWidth: "100%" }}
          src={IframeProvider.baseURL + process.env.REACT_APP_IFRAME_GRID}
          title="form"
        ></iframe>
      </Box>
    </Container>
  );
}
export default InformationPage;
