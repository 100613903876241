import React, { useEffect } from "react";
import { Card, CardContent, CardHeader, TextField } from "@mui/material";
import { StyledCard, TitleTypography } from "./ContactFormStyles";
import { FormField, FormLabel } from "../../formComponent/FormStyles";

function ContactFormMobileView({
  firstRecord,
  handleChange,
  handleMobileChange,
  error,
  contactFormErrors,

}) {

  useEffect(() => {
    localStorage.setItem("firstRecord", JSON.stringify(firstRecord));
  }, [firstRecord]);


  return (
    <StyledCard>
      <CardHeader
        title={<TitleTypography>Contact Info</TitleTypography>}
        sx={{ padding: 0, paddingTop: "20px" }}
      ></CardHeader>
      <CardContent>
        <FormField>
          <TextField
            required
            fullWidth
            size="small"
            value={firstRecord.contactPerson}
            label="Contact Person"
            name="contactPerson"
            variant="outlined"
            onChange={handleChange}
            style={FormLabel}
            error={error && !firstRecord.contactPerson}
          ></TextField>
        </FormField>
        <FormField>
          <TextField
            required
            fullWidth
            size="small"
            value={firstRecord.mobileNumber}
            label="Mobile Number"
            name="mobileNumber"
            variant="outlined"
            type="tel"
            onChange={handleMobileChange}
            style={FormLabel}
            error={
              (error && !firstRecord.mobileNumber) ||
              (error && contactFormErrors.mobileNumberError !== "")
            }
            // helperText={contactFormErrors.mobileNumberError}
          ></TextField>
        </FormField>
        <FormField>
          <TextField
            required
            fullWidth
            size="small"
            value={firstRecord.email}
            onChange={handleChange}
            label="Email"
            type="email"
            variant="outlined"
            name="email"
            style={FormLabel}
            error={
              (error && !firstRecord.email) ||
              (error && contactFormErrors.emailError !== "")
            }
            // helperText={contactFormErrors.emailError}
          />
        </FormField>
        <FormField>
          <TextField
            fullWidth
            required
            size="small"
            label="Confirm Email"
            type="email"
            variant="outlined"
            name="confirmEmail"
            style={FormLabel}
            value={firstRecord.confirmEmail}
            // helperText={error && confirmEmailError}
            error={
              (error && !firstRecord.confirmEmail) ||
              (error && contactFormErrors.confirmEmailError !== "")
            }
            onChange={handleChange}
          />
        </FormField>
        <FormField>
          <TextField
            fullWidth
            required
            size="small"
            label="Address"
            variant="outlined"
            name="address"
            style={FormLabel}
            value={firstRecord.address}
            error={error && !firstRecord.address}
            onChange={handleChange}
          />
        </FormField>
      </CardContent>
    </StyledCard>
  );
}
export default ContactFormMobileView;
