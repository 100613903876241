import React from "react";
import CustomPopUpForm from "../../layout/CustomPopUpForm";
import { Typography, Button } from "@mui/material";
import { Checkbox, FormControlLabel } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
function CheckoutPopup({
  open,
  handlePopup,
  confirmationMessage,
  checkedItems,
  setCheckedItems,
  firstRecord,
  checkoutPopupSubmit,
  setCheckoutPopupSubmit,
  handleNext,
  handleContinueClick,

}) {
  // const handleSubmit = () => {
  //   setCheckoutPopupSubmit(true);
  //   handlePopup();
  //   handleContinueClick();
  // };

  const handleSubmit = () => {

    if (checkedItems.every(item => item)) {
      handlePopup();
      handleContinueClick(); 
    } else {
      toast.error('Please confirm all items.');
    }
  };





  // Replace placeholders with actual values from firstRecord
  const processedMessages = confirmationMessage.map((message) => {
    let processedMessage = message.replace(
      "$phone$",
      `${firstRecord.areaCode} ${firstRecord.mobileNumber}`
    );
    processedMessage = processedMessage.replace("$email$", firstRecord.confirmEmail);
    return processedMessage;
  });


  const handleCheckboxChange = (index) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = !newCheckedItems[index];
    setCheckedItems(newCheckedItems);
  };
  
  return (
    <>
      <ToastContainer position="bottom-right" />
    <CustomPopUpForm open={open} onClose={handlePopup} title="Confimation">
      <Typography className="wertyert popup" sx={{ fontWeight: "bold", color: "darkgray"}}>
        Changes can no longer be made once you proceed to the next page
      </Typography>
      <div>
        {processedMessages.map((message, index) => (
          <div key={index} style={{ marginBottom: "1rem" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedItems[index]}
                  onChange={() => handleCheckboxChange(index)}
                />
              }
              label={<div dangerouslySetInnerHTML={{ __html: message }} />}
            />
          </div>
        ))}
        <div
          style={{
            marginBottom: "1rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
           className="popup-align"
            color="primary"
            aria-label="search"
            onClick={handleSubmit}
            variant="outlined"
          >
          Approve
          </Button>
         
        </div>
      </div>
    </CustomPopUpForm>
  
    </>
  );
}

export default CheckoutPopup;
