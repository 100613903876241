import React from "react";

function ThankYou() {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img
        src="https://www.mhmandassociates.com/wp-content/uploads/2019/07/logo-1.png"
        alt="Logo"
        className="MHM Logo"
      />
      <p
        style={{
          fontSize: "1.5rem",
          fontWeight: "bold",
          textWrap: "pretty",
          margin: "0 0.5rem",
        }}
      >
        Thank you for Registering You should receive an email shortly
      </p>
    </div>
  );
}

export default ThankYou;
