import React from "react";
import HeaderComponent from "../../grantsComponents/HeaderComponent";
import RegistrationForm from "../../grantsComponents/RegistrationForm";
import Footer from "../../grantsComponents/Footer";
function GrantsRegistrationPage({ shortForm }) {
  return (
    <div style={{ height: "100%" }}>
      <HeaderComponent />
      <RegistrationForm shortForm={shortForm} />
      <Footer />
    </div>
  );
}

export default GrantsRegistrationPage;
