import React from "react";
import {
  ThemeProvider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import theme from "../../Theme";
export default function MenuNavigationComponent({
  pageNames,
  pagePaths,
  pageIcons,
}) {
  const handleListClick = (pageIndex) => {
    if (pagePaths[pageIndex]) {
      // If a link is provided in data, navigate to that link
      window.location.href = pagePaths[pageIndex];
    }
  };


  return (
    <ThemeProvider theme={theme}>
      {pageNames.map((pageName, pageIndex) => (
        <ListItem disablePadding>
          <ListItemButton onClick={() => handleListClick(pageIndex)}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              {pageIcons[pageIndex]}
            </ListItemIcon>
            <ListItemText primary={pageName} />
          </ListItemButton>
        </ListItem>
      ))}
    </ThemeProvider>
  );
}
