//import APIProviders from "../data/data.json";

let APIProvider = window.APIProvider;

const DEFAULT_USERID = "-1";
let POST_HEADER = {
  method: "POST",
  headers: {
    "Content-type": "application/json",
    Accept: "application/json, text/plain, */*",
  },
  mode: "cors",
};

let GET_HEADER = {
  method: "GET",
  headers: {},
  mode: "cors",
};
function GetRequestHeader() {
  return GET_HEADER;
}

function PostRequestHeader() {
  return POST_HEADER;
}

// function GetAPI() {
//   return AquariusAPI;
// }

function GetDefaultUserID() {
  return DEFAULT_USERID;
}

async function PostGrantsRegistration(registryRecord) {
  console.log("registryRecord ", registryRecord);
  const request = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json, text/plain, */*",
    },
    mode: "cors",
    body: JSON.stringify(registryRecord),
  };
  const response = await fetch(
    APIProvider.baseURL + APIProvider.recordPath.postGrantsRegistration,
    request
  );
  console.log("Post Grants Registry Response: ", response);
  return response;
}

async function CheckEmailIsOnFile(email) {
  const response = await fetch(
    APIProvider.baseURL + APIProvider.recordPath.CheckEmail + "?email=" + email,
    GET_HEADER
  );
  const exists = await response;
  return await exists.json();
}

async function CheckEINIsOnFile(ein) {
  const response = await fetch(
    APIProvider.baseURL + APIProvider.recordPath.CheckEIN + "?ein=" + ein,
    GET_HEADER
  );
  const exists = await response;
  return await exists.json();
}
export { PostGrantsRegistration, CheckEmailIsOnFile, CheckEINIsOnFile };
