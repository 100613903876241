import { styled } from "@mui/material/styles";
import theme from "../../../Theme";
import { Typography, Card } from "@mui/material";

export const StyledCard = styled(Card)({
  marginBottom: "2rem",
});

export const TitleTypography = styled(Typography)({
  fontWeight: "bold",
  fontSize: "20px",
  display: "flex",
  justifyContent: "center",
  color: theme.palette.primary.main,
});
export const TotalTypography = styled(Typography)({
  fontSize: "20px",
  fontWeight: "bold",
  color: theme.palette.primary.main,
});
export const InvoiceLayout = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginRight: "1rem",
});
