import React, { useState } from "react";
import RightRegistrationFormComponentCityOfLA from "./RightRegistrationFormComponentCityOfLA";
import {
  PostGrantsRegistration,
  CheckEmailIsOnFile,
  CheckEINIsOnFile,
} from "../../apiCalls/GrantsAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const requiredFields = [
  "email",
  "password",
  "confirmPassword",
  "firstName",
  "lastName",
  "title",
  "interestArea",
  "organizationType",
  "country",
  "ein",
];

function FormBusiness() {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileError, setFileError] = useState("");
  const [submit, setSubmit] = React.useState(false);
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    alias: "",
    dob: "",
    lastKnownAddress: "",
    motherFirstName: "",
    motherLastName: "",
    fatherFirstName: "",
    fatherLastName: "",
    genderIdentity: "",
    isYoungAdult: false,
    nativeLanguage: "",
    otherLanguage: "",
    homelessDuration: "",
    experiencedHomelessnessBefore: false,
    numTimesHomeless: 0,
    sleepLocation: "",
    hasShelterAccess: false,
    isCouchSurfing: false,
    dependents: "No",
    educationLevel: "",
    isEnrolledInSchool: false,
    educationChallenges: "",
    incomeSource: "",
    employmentStatus: "",
    employmentBarriers: "",
    wantToLearnTrade: false,
    internshipDate: "",
    potentialJobClassification: "",
    chronicConditions: "",
    isReceivingMedicalCare: false,
    mentalHealthIssues: "",
    supportNetwork: "",
    accessedSupportServices: false,
    awareOfResources: false,
    transportationAccess: false,
    experiencedViolence: false,
    feelsSafe: false,
    legalIssues: "",
    shortTermGoals: "",
    longTermGoals: "",
    aspirations: "",
    goalChallenges: "",
    helpfulSupportServices: "",
    suggestions: "",
    improvementSuggestions: "",
    outreachImprovement: "",
  });
  const [errors, setErrors] = React.useState({
    firstName: "",
    lastName: "",
    alias: "",
    dob: "",
    lastKnownAddress: "",
    motherFirstName: "",
    motherLastName: "",
    fatherFirstName: "",
    fatherLastName: "",
    genderIdentity: "",
    isYoungAdult: false,
    nativeLanguage: "",
    otherLanguage: "",
    homelessDuration: "",
    experiencedHomelessnessBefore: false,
    numTimesHomeless: 0,
    sleepLocation: "",
    hasShelterAccess: false,
    isCouchSurfing: false,
    dependents: "No",
    educationLevel: "",
    isEnrolledInSchool: false,
    educationChallenges: "",
    incomeSource: "",
    employmentStatus: "",
    employmentBarriers: "",
    wantToLearnTrade: false,
    internshipDate: "",
    potentialJobClassification: "",
    chronicConditions: "",
    isReceivingMedicalCare: false,
    mentalHealthIssues: "",
    supportNetwork: "",
    accessedSupportServices: false,
    awareOfResources: false,
    transportationAccess: false,
    experiencedViolence: false,
    feelsSafe: false,
    legalIssues: "",
    shortTermGoals: "",
    longTermGoals: "",
    aspirations: "",
    goalChallenges: "",
    helpfulSupportServices: "",
    suggestions: "",
    improvementSuggestions: "",
  });

  function convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Check if the file was successfully read
        if (reader.result) {
          resolve(reader.result.split(",")[1]); // Extract the base64 data (after the comma)
        } else {
          reject(new Error("Failed to read the file as base64."));
        }
      };

      reader.readAsDataURL(file);
    });
  }

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setState({
      ...state,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleMultiSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setState({
      ...state,
      [event.target.name]: typeof value === "string" ? value.split(",") : value,
    });
  };

  console.log("state ", state);
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <RightRegistrationFormComponentCityOfLA
        header={window.headerRightFormCityOfLA}
        state={state}
        errors={errors}
        handleChange={handleChange}
        handleMultiSelectChange={handleMultiSelectChange}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
        fileError={fileError}
        setFileError={setFileError}
      />
    </>
  );
}

export default FormBusiness;
