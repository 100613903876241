import React from "react";
import { SplitScreen } from "./splitScreenTopBottom";
import LeftRegistrationFormComponentCityOfLA from "./LeftRegistrationFormComponentCityOfLA";
import FormBusiness from "./rightRegistrationForm/FormBusinessCityOfLA";

function RegistrationFormCityOfLA() {
  return (
    <SplitScreen leftWeight={1} rightWeight={2}>
      <LeftRegistrationFormComponentCityOfLA
        mainHeading={window.mainHeadingForLeftFormCityOfLA}
        listItems={window.listItemsforLeftFormCityOfLA}
        image={window.imageLeftCityOfLA}
      />
      <FormBusiness />
    </SplitScreen>
  );
}

export default RegistrationFormCityOfLA;
