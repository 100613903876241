import React from "react";
import { useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Box,
  Badge,
  Collapse,
} from "@mui/material";
import { Link } from "react-router-dom";
import theme from "../../Theme";
import { v4 as uuid } from "uuid";
import MoreIcon from "@mui/icons-material/MoreVert";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";

const buttonStyles = {
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.white.dark,
  margin: "5px",
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
};

function MobileIconMenu({ pages, pagesPath }) {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [profileMenuExpanded, setProfileMenuExpanded] = useState(false);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleProfileMenuExpand = () => {
    setProfileMenuExpanded(!profileMenuExpanded);
  };

  const handleLogoutSubmit = (e) => {
    e.preventDefault();
    console.log("log out");
    // auth.signout(() => navigate("/Login"));
  };

  let loginButton = (
    <MenuItem key={uuid()} onClick={handleLogoutSubmit}>
      Logout
    </MenuItem>
  );

  const chatMobileMenuItems = (
    <Link to="/Chat" style={{ textDecoration: "none", color: "inherit" }}>
      <MenuItem onClick={handleMobileMenuClose}>
        <IconButton size="large" aria-label="show 4 new chats">
          <Badge badgeContent={4} color="error">
            <ChatBubbleIcon />
          </Badge>
        </IconButton>
        <p>Chat</p>
      </MenuItem>
    </Link>
  );

  return (
    // <Box sx={{ display: { xs: "flex", md: "none" } }}>
    <Box sx={{ display: { xs: "flex", md: "none" } }}>
      <IconButton
        size="large"
        aria-label="show more"
        // aria-controls={mobileMenuId}
        aria-haspopup="true"
        onClick={handleMobileMenuOpen}
        sx={buttonStyles}
      >
        <MoreIcon />
      </IconButton>

      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        // id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        {/* {auth.user !== null ? chatMobileMenuItems : null} */}
        {chatMobileMenuItems}
        <MenuItem onClick={handleProfileMenuExpand}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
          {profileMenuExpanded ? <ExpandLess /> : <ExpandMore />}
        </MenuItem>
        <Collapse in={profileMenuExpanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {pages.map((submenuText, submenuIndex) => (
              <Link
                key={uuid()}
                to={pagesPath[submenuIndex]}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <MenuItem>
                  <ListItemText
                    primary={submenuText}
                    onClick={handleMobileMenuClose}
                  />
                </MenuItem>
              </Link>
            ))}
            {/* {loginButton} */}
          </List>
        </Collapse>
      </Menu>
    </Box>
  );
}
export default MobileIconMenu;
