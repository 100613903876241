import React, { useState } from "react";
import ContactFormView from "./ContactFormView";
import ContactFormMobileView from "./ContactFormMobileView";
import { ThemeProvider } from "@mui/material";
import theme from "../../../Theme";
import useMediaQuery from "@mui/material/useMediaQuery";
function ContactFormBusiness({
  firstRecord,
  setFirstRecord,
  submit,
  contactFormErrors,
  handleChangeFRecor,
  handleMobileChangeFRecord,
  fRecord,
  error
}) {
  //check if mobile
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  function handleChange(event) {
    const value = event.target.value;
    setFirstRecord({
      ...firstRecord,
      [event.target.name]: value,
    });
  }

  function handleMobileChange(event) {
    const { name, value } = event.target;
    // Allow only numeric input
    if (!isNaN(value)) {
      setFirstRecord((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  }

  return (
    <ThemeProvider theme={theme}>
      {isSmallScreen ? (
        <ContactFormMobileView
          firstRecord={firstRecord}
          handleChange={handleChange}
          handleMobileChange={handleMobileChange}
          submit={submit}
          contactFormErrors={contactFormErrors}
          error={error}
        />
      ) : (
        <ContactFormView
          firstRecord={firstRecord}
          handleChange={handleChange}
          handleMobileChange={handleMobileChange}
          submit={submit}
          contactFormErrors={contactFormErrors}
          handleChangeFRecor={handleChangeFRecor}
          handleMobileChangeFRecord={handleMobileChangeFRecord}
          fRecord={fRecord}
          error={error}
        />
      )}
    </ThemeProvider>
  );
}
export default ContactFormBusiness;