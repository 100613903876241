import React from "react";
import {
  Typography,
  Grid,
  Container,
  useMediaQuery,
  Box,
  ThemeProvider,
} from "@mui/material";
import theme from "../Theme";

const Footer = () => {
  // Use the useMediaQuery hook to check screen width
  const isSmallScreen = useMediaQuery("(max-width: 1000px)");

  return (
    <ThemeProvider theme={theme}>
      <Container
        style={{
          maxWidth: "100%",
          backgroundColor: theme.palette.grants.primary,
          marginTop: "1rem",
          paddingTop: "1rem",
          paddingBottom: "1rem",
          paddingLeft: isSmallScreen ? "1rem" : "10rem",
          paddingRight: isSmallScreen ? "1rem" : "10rem",
        }}
      >
        <Grid
          container
          spacing={2}
          direction={isSmallScreen ? "column" : "row"}
        >
          {/* Left Section */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" fontWeight="bold" color="#ffffff">
              {window.footerLeftHeader}
            </Typography>
            {/* Add a Box component to create space between Typography components */}
            <Box mt={1}>
              <Typography color="#FFFFFF">{window.footerLeftBody}</Typography>
            </Box>
          </Grid>

          {/* Center Section */}
          <Grid
            item
            xs={12}
            sm={4}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography color="#a5c4cd">{window.footerMiddleHeader}</Typography>
            <Typography color="#a5c4cd">{window.footerMiddleBody}</Typography>
          </Grid>

          {/* Right Section */}
          <Grid
            item
            xs={12}
            sm={4}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography color="#FFFFFF">{window.footerPhone}</Typography>
            <Typography color="#FFFFFF">{window.footerEmail}</Typography>
            <Typography color="#FFFFFF">{window.footerWebsite}</Typography>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default Footer;
