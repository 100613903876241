export const PostTicketModel = (state, from, person) => {
  // let seatType = "";
  // if (
  //   (from && state.seatType === "Standard") ||
  //   (!from && state.seatTypeReturn === "Standard")
  // ) {
  //   seatType = "Standard A";
  // } else {
  //   seatType = state.seatType;
  // }
  let postTicketModel = {
    TGUID: state.TGUID,
    request: state.request,
    classID:
      state.request === "Passenger" ? 1 : state.request === "Ferry" ? 2 : 3,
    information: person.firstName + " " + person.lastName,
    // information: state.contactPerson,
    // countUnit: state.unit ? state.countUnit.toString() : "1.00",
    countUnit:"1.00",

    pricePaid: from
      ? (
          state.seatPrice +
          state.terminalFees +
          state.otherFees +
          state.other
        ).toString()
      : (
          state.seatPriceReturn +
          state.terminalFeesReturn +
          state.otherFeesReturn +
          state.otherReturn
        ).toString(),
    purchased: "1",
    tripID: from ? state.tripID : state.returnTripID,
    ClassName: from ? state.seatType : state.seatTypeReturn,
    // ClassName: from ? state.seatType : state.seatTypeReturn,
    RACMID: from ? state.acmID.toString() : state.acmIDReturn.toString(),
    createdBy: "web",
    // createdDate: new Date().toISOString(),
  };
  return postTicketModel;
};
export const PostTicketModelTest = () => {
  let postTicketModel = {
    TGUID: "A1079E9C-4F60-45FF-BB23-0580A8049B4D",
    request: "Passenger",
    classID: 1,
    information: "Out Field",
    countUnit: "1.00",
    pricePaid: 50.0,
    purchased: "1",
    tripID: 170,
    ClassName: "Standard A",
    // ClassName: from ? state.seatType : state.seatTypeReturn,
  };
  return postTicketModel;
};
export const PostTicketComplementModel = (
  TGUID,
  TicketID,
  ParamName,
  ParamValue,
  index
) => {
  let postTicketComplementModel = {
    TicketID: TicketID.toString(),
    TGUID: TGUID,
    ParamName: ParamName,
    ParamValue: ParamValue,
    SeqID: index,
  };
  return postTicketComplementModel;
};

export const PostPassengerContactsModel = (
  TicketID,
  state,
  peopleIndex,
  i,
  from
) => {
  let postTicketComplementModel = {
    TicketID: TicketID.toString(),
    TGUID: state.TGUID,
    FirstName: state.persons[peopleIndex].firstName,
    LastName: state.persons[peopleIndex].lastName,
    Address: "",
    Mobile: "",
    Email: "",
    DOB: state.persons[peopleIndex].DOB,
    Gender: state.persons[peopleIndex].gender,
    Nationality: state.persons[peopleIndex].nationality,
    isMain: `${i + 1}`,
    isReturn: from ? "0" : "1",
    PxTempID: from ? state.pxtempid : state.pxtempidReturn,
    RACMID: from ? state.acmID.toString() : state.acmIDReturn.toString(),
    TempID: from ? state.tempID.toString() : state.returnTempID.toString(),
    createdBy: "web",
    // createdDate: new Date().toISOString(),
  };

  return postTicketComplementModel;
};

export const PostMainPassengerContactsModel = (state) => {
  let postMainPassengerContact = {
    RouteID: state.routeID.toString(),
    TGUID: state.TGUID.toString(),
    TripID: state.tripID.toString(),
    TempID: state.tempID.toString(),
    RTripID: state.returnTripID.toString(),
    RTempID: state.returnTempID.toString(),
    ContactName: state.contactPerson.toString(),
    address: state.address.toString(),
    Mobile: state.mobileNumber.toString(),
    Email: state.email.toString(),
    CartStatus: state.CartStatus.toString(),
    CreatedBy: "web",
    totalBill: state.totalAmount,
    totalPaid: state.totalPaid,
    totalBalance: state.totalBalance,
    returnFlag: state.returnFlag,
  };
  return postMainPassengerContact;
};
