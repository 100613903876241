import React from "react";
// import { healthAuthProvider } from "./Auth";
import { fakeAuthProvider } from "./Auth";
let AuthContext = React.createContext();

// function AuthProvider({ children }) {
//   let [user, setUser] = React.useState(null);

//   let signin = async (username, password, callback) => {
//     let response = await healthAuthProvider.signin(username, password, () => {
//       let userInfo = JSON.parse(localStorage.getItem("userInfo"));
//       setUser(userInfo);
//       callback();
//     });
//     console.log("Response ", response);
//     return response;
//   };

//   let signout = async (callback) => {
//     let response = await healthAuthProvider.signout(() => {
//       setUser(null);
//       callback();
//     });
//     return response;
//   };

//   let value = { user, signin, signout };

//   return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
// }

function useAuth() {
  return React.useContext(AuthContext);
}

//export { AuthProvider, useAuth };
export { AuthProvider, useAuth };

function AuthProvider({ children }) {
  let [user, setUser] = React.useState(null);

  let signin = (newUser, callback) => {
    return fakeAuthProvider.signin(() => {
      setUser(newUser);
      callback();
    });
  };

  let signout = (callback) => {
    return fakeAuthProvider.signout(() => {
      setUser(null);
      callback();
    });
  };

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
