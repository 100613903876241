import React, { lazy, Suspense } from "react";
import { useEffect } from "react";
import jwtDecode from "jwt-decode";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import RequireAuth from "./components/RequireAuth";
import { AuthProvider } from "./components/AuthProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import TopBarLayout from "./components/layouts/TopBarLayout";
import NotFound from "./pages/NotFound";
import Addons from "./pages/Addons";
import Chat from "./pages/Chat";
import TreeViewComponent from "./visual editor/TreeViewComponent";
import RecursiveTreeView from "./visual editor/RecursiveTreeView";
import jsonData from "./data/treeView.json";
import { ReadArray } from "./apiCalls/EditorAPI";
import InfoEditPage from "./pages/InfoEditPage";
import InformationPage from "./pages/InformationPage";
import GrantsRegistrationPage from "./pages/registrationPages/GrantsRegistrationPage";
import Terms from "./pages/registrationPages/Terms";
import ThankYou from "./pages/registrationPages/ThankYou";
import CityOfLARegistrationPage from "./pages/registrationPages/CityOfLARegistrationPage";
// import FormComponent from "./components/formComponent/FormComponent";
import Success from "./Status";
import TripMessage from "./TripMessage";
import FirstStepViewEdit from "./components/Stepper/FirstStep/FirstStepViewEdit";
// import LandingPage from "./pages/landingPage/Landing";
const LandingPage = React.lazy(() => import("./pages/landingPage/Landing"));
const TestLanding = React.lazy(() => import("./pages/testLanding/TestLanding"));
const FormComponent = React.lazy(() =>
  import("./components/formComponent/FormComponent")
);
const TripsFormBusiness = React.lazy(() =>
  import("./pages/tripsForm/TripsFormBusiness")
);
const PrintablePage = React.lazy(() => import("./pages/PrintablePage"));
const Test = React.lazy(() => import("./pages/test"));
const TestConfig = React.lazy(() => import("./pages/TestConfig"));
const LoginBusiness = React.lazy(() => import("./pages/Login/LoginBusiness"));
const Go = React.lazy(() => import("./pages/goPage/Go"));
const Refresh = React.lazy(() => import("./pages/refreshPage/Refresh"));
const ProfilePage = React.lazy(() => import("./pages/profilePage/ProfilePage"));
const Hello = React.lazy(() => import("./pages/Hello"));

// import googleClientID
const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              minHeight: "100vh",
            }}
          >
            <div className="spinner"></div>
          </div>
        }
      >
        <BrowserRouter basename="/">
          <Routes>
            {/* Private Routes */}
            {/* <Route element={<RequireAuth />}> */}
            <Route path="Hello" element={<Hello />} />
            <Route
              path="GrantsRegistrationPage"
              element={<GrantsRegistrationPage shortForm={false} />}
            />
            <Route
              path="GrantsShortForm"
              element={<GrantsRegistrationPage shortForm={true} />}
            />
            <Route
              path="RegistrationPage"
              element={<CityOfLARegistrationPage />}
            />
            <Route path="Terms" element={<Terms />} />
            <Route path="ThankYou" element={<ThankYou />} />
            <Route element={<TopBarLayout />}>
              {/* <Route path="Profile" element={<Profile />} /> */}
              <Route index element={<LandingPage />} />
              <Route path="/testLanding" element={<TestLanding />} />

              {/* Experimental route */}
              <Route path="/search" element={<LandingPage />} />
              <Route path="/Go" element={<Go />} />
              <Route path="/Refresh" element={<Refresh />} />
              <Route path="Addons" element={<Addons />} />
              <Route path="Chat" element={<Chat />} />
              <Route path="BookTicket" element={<FormComponent />} />
              <Route path="TripsForm" element={<TripsFormBusiness />} />
              <Route path="Profile" element={<ProfilePage />} />
              {/* <Route path="InformationPage" element={<InformationPage />} /> */}
              {/* <Route path="InfoEditPage" element={<InfoEditPage />} /> */}
              <Route path="Login" element={<LoginBusiness />} />
              <Route path="PrintablePage" element={<PrintablePage />} />
              <Route path="Test" element={<Test />} />
              <Route path="TestConfig" element={<TestConfig />} />
              <Route path="InfoEditPage" element={<InfoEditPage />} />
              <Route path="InformationPage" element={<InformationPage />} />
              <Route path="Success" element={<Success />} />
              <Route path="TripMessage" element={<TripMessage />} />
              <Route path="TripsFormstep=1" element={<FirstStepViewEdit />} />

              <Route
                path="/TreeView"
                element={<TreeViewComponent jsonData={jsonData} />}
              />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Suspense>
    </QueryClientProvider>
  );
}

export default App;
