import { styled } from "@mui/material/styles";
import theme from "../../Theme";
import { Box, ListItemButton } from "@mui/material";
// export const FormField = styled("div")(({ theme }) => ({
//   marginBottom: theme.spacing(2),
//   display: "flex",
//   alignItems: "center",
//   height: "",
// }));
const shouldForwardProp = (prop) => prop !== "isMobile";

export const StyledContainerBox = styled(Box)({
  width: "100%",
  marginTop: "4.5rem",
  display: "flex",
});
export const listFlexBox = {
  flex: "1 0 15%",
  [theme.breakpoints.down("lg")]: {
    flex: "1 0 20%",
  },
};
export const StyledBoxForCard = styled(Box, { shouldForwardProp })(
  ({ isMobile }) => ({
    display: "flex",
    flex: "1 0 80%",
    [theme.breakpoints.down("lg")]: {
      flex: "1 0 70%",
    },
    backgroundColor: theme.palette.gray.light,
    padding: "1rem",
    flexWrap: "wrap",
    justifyContent: isMobile ? "center" : "flex-start",
  })
);

export const cardMobileContainer = {
  backgroundColor: theme.palette.gray.light,
  width: "100%",
  marginTop: "4.5rem",
};

//List Styles
export const StyledListItemButton = styled(ListItemButton)({
  "&:hover": {
    backgroundColor: theme.palette.gray.main, // Change this to your desired hover color
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.gray.light, // Change this to your desired selected color
    "&:hover": {
      backgroundColor: theme.palette.gray.main, // Change this to your desired hover color
    },
  },
});
