import React from "react";
import {
  ThemeProvider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import theme from "../../Theme";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { StyledListItemButton } from "./MenuComponentStyles";

export default function MenuListComponent({
  section,
  sectionIndex,
  handleListClick,
  sectionClickedIndex,
}) {
  return (
    <ThemeProvider theme={theme}>
      <ListItem disablePadding>
        <StyledListItemButton
          selected={sectionClickedIndex === sectionIndex ? true : false}
          onClick={() => handleListClick(sectionIndex)}
        >
          <ListItemIcon sx={{ minWidth: 40 }}>
            {sectionIndex % 2 === 0 ? <InboxIcon /> : <MailIcon />}
          </ListItemIcon>
          <ListItemText primary={section.title} />
        </StyledListItemButton>
      </ListItem>
    </ThemeProvider>
  );
}
