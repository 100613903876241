import { styled } from "@mui/material/styles";
import theme from "../../../Theme";
import { Card, Typography } from "@mui/material";

export const StyledCard = styled(Card)({
  marginBottom: "2rem",
});

export const TitleTypography = styled(Typography)({
  color: theme.palette.primary.main,
  fontWeight: "bold",
  fontSize: "20px",
  display: "flex",
  justifyContent: "center",
});
// export const TitleTypography = styled(Typography)({
//   fontWeight: "bold",
//   fontSize: "20px",
//   display: "flex",
//   justifyContent: "center",
//   color: theme.palette.primary.main,
// });
