import React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { ThemeProvider } from "@mui/material";
import NRDCLogo from "../assets/NRDCLogo.png";
import { Button, Divider } from "@mui/material";
import "./headerLayout.css";
import { useMediaQuery } from "@mui/material";
import theme from "../Theme";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: "flex-start",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  // Override media queries injected by theme.mixins.toolbar
  "@media all": {
    minHeight: 100,
  },
}));

function HeaderComponent() {
  function handleGoToSignIn() {
    window.open(window.loginLink, "_blank");
  }
  const isSmallScreen = useMediaQuery("(max-width:900px)");
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ backgroundColor: "white" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="container">
              {/* Container 1 - Logo */}
              <div
                className="item"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: isSmallScreen ? "flex-start" : "flex-end ",
                }}
              >
                {window.HeaderLogo && (
                  <img
                    src={NRDCLogo}
                    alt="Logo"
                    style={{ alignSelf: "flex-start" }}
                  />
                )}
              </div>

              {/* Container 2 - Text */}

              <div
                className="item"
                // variant="h6"
                // component="div"
                // sx={{
                //   flexGrow: 1,
                //   alignSelf: "flex-end",
                //   color: "#004a93",
                //   height: "100%",
                //   alignItems: "center",
                //   fontSize: "15px",
                // }}
              >
                <p
                  style={{
                    color: theme.palette.grants.primary,
                    padding: isSmallScreen ? "0 1rem" : 0,
                  }}
                >
                  Building the Nation's First Accelerator for Granting Funds for
                  Black and Minority Women-Owned Businesses
                </p>
              </div>

              {/* Container 3 - Button */}
              <div
                className="item"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: isSmallScreen ? "flex-end" : "flex-start",
                  padding: isSmallScreen ? "0 1rem" : 0,
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    color: theme.palette.grants.secondary,
                    borderColor: theme.palette.grants.secondary,
                    borderRadius: "20px",
                  }}
                  onClick={handleGoToSignIn}
                >
                  Sign In
                </Button>
              </div>
            </div>
            <Divider sx={{ width: isSmallScreen ? "100%" : "70%" }} />

            {/* About Button */}
            <Button>About</Button>
          </div>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
}

export default HeaderComponent;
