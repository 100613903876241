//import APIProviders from "../data/data.json";

let APIProvider = window.APIProvider;

const DEFAULT_USERID = "-1";
let POST_HEADER = {
  method: "POST",
  headers: {
    "Content-type": "application/json",
    Accept: "application/json, text/plain, */*",
  },
  mode: "cors",
};

let GET_HEADER = {
  method: "GET",
  headers: {},
  mode: "cors",
};
function GetRequestHeader() {
  return GET_HEADER;
}

function PostRequestHeader() {
  return POST_HEADER;
}

// function GetAPI() {
//   return AquariusAPI;
// }

function GetDefaultUserID() {
  return DEFAULT_USERID;
}
async function WriteArray(array) {
  try {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json, text/plain, */*",
      },
      mode: "cors",
      body: JSON.stringify(array),
    };
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.WriteArray,
      request
    );
    return response;
  } catch (error) {
    console.error("Error ", error);
  }
}

async function ReadArray(arID) {
  try {
    const response = await fetch(
      APIProvider.baseURL + APIProvider.recordPath.ReadArray + "?arID=" + arID,
      GET_HEADER
    );
    const array = await response.json();

    return array;
  } catch (error) {
    console.error("Error ", error);
  }
}

export { WriteArray, ReadArray };
