import React from "react";
import theme from "../../Theme";
import { Navigate, Link } from "react-router-dom";
import { Card, Button, CardContent, CardMedia } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useAuth } from "../../components/AuthProvider";
import { Action } from "./ActionList";
const MiniCardComponent = ({
  image,
  title,
  textSize,
  position,
  size,
  isMobile,
  itemID,
  admin,
  handleDialogToggle,
}) => {
  const EDIT_URL =
    "https://aiworksqa.agiline.com//global/index?globalurlid=787048E1-D78C-4E6F-BEB2-D9ED05BD6266&param1=";
  const screenHeight = window.innerHeight;
  const cardHeightLeft = size * 0.4;
  const style = {
    position: "relative",
    width: position === "left" ? `${size}px` : `${size}px`,
    height: position === "left" ? `${cardHeightLeft}px` : `${size}px`,
    backgroundColor: "white",
    display: "flex",
    justifyContent: position === "left" ? "flex-start" : "flex-start",
    marginTop: "1rem",
    marginBottom: "1rem",
    marginRight: !isMobile ? "2rem" : "0",
  };

  const imgStyle = {
    maxWidth: position === "left" ? `${size / 2}px` : `${size}px`,
    maxHeight: position === "left" ? `${cardHeightLeft}px` : `${size / 2}px`,
    minWidth: position === "left" ? `${size / 2}px` : `${size}px`,
    minHeight: position === "left" ? `${cardHeightLeft}px` : `${size / 2}px`,
    objectFit: "cover",
    objectPosition: "center",
    marginRight: position === "left" ? "10px" : "0",
    marginBottom: position === "left" ? "0" : "10px",
  };

  const titleStyle = {
    fontSize: `${textSize}px`,
    fontWeight: "bold",
    color: theme.palette.primary.main,
  };

  const handleEditClick = () => {
    <Navigate to={EDIT_URL + itemID} target="_blank" />;
  };

  let auth = useAuth();

  // const isAdmin = () => {
  //   let result =
  //     auth.user !== null &&
  //     auth.user !== undefined &&
  //     auth.user.isAdmin === true;
  //   return result;
  // };

  const isAdmin = () => {
    return admin;
  };
  var myfun = function (ele) {
    let offsetTop = document.getElementById(ele).offsetTop;
    window.scrollTo({
      top: offsetTop - 100,
      behavior: "smooth",
    });
  };

  function handleCardClick(event) {
    myfun(itemID);
  }
  return (
    <Card style={style} onClick={handleCardClick}>
      <CardMedia
        component="img"
        style={imgStyle}
        image={image}
        alt="landing page image"
      />
      {/* <img src={image} alt="" style={imgStyle} /> */}
      <CardContent>
        <div>
          <p style={titleStyle}>{title}</p>
        </div>
        {isAdmin() && (
          <Button
            onClick={(event) =>
              handleDialogToggle(event, itemID, title, Action.EDIT_ITEM)
            }
            style={{
              position: "absolute",
              top: 1,
              right: 1,
              zIndex: 1,
              "&:hover": {
                boxShadow: "none", // Remove hover shadow
                borderRadius: "50%",
              },
            }}
          >
            <EditIcon
              style={{
                borderColor: "#e8e8e8",
                borderWidth: "2px",
                borderRadius: "25px",
                height: "1.3rem",
                padding: "0.5rem",
                marginLeft: "5%",
                color: theme.palette.black.light,
              }}
            />
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default MiniCardComponent;
