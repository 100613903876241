import React, { useEffect } from "react";
import { StyledCard, TitleTypography } from "./PassengerInfoStyles";
import {
  CardContent,
  CardHeader,
  TextField,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { FormField, FormLabel } from "../../formComponent/FormStyles";
// import { nationalities } from "../../../data/listOfNationalities";
import { countries } from "../../../data/listOfNationalities";
import { getCurrentDate } from "../../../functions/DateManipulationFunctions";

function PassengerInfoMobileView({
  index,
  firstRecord,
  handleChange,
  handleNationalityChange,
  error,
}) {

  useEffect(() => {
    localStorage.setItem("firstRecord", JSON.stringify(firstRecord));
  }, [firstRecord]);

  return (
    <StyledCard>
      <CardHeader
        title={<TitleTypography>Passenger: {index + 1}</TitleTypography>}
        sx={{ padding: 0, paddingTop: "20px" }}
      />
      <CardContent>
        <FormField>
          <TextField
            required
            fullWidth
            size="small"
            value={firstRecord.persons[index]?.firstName || ""}
            label="First Name"
            name={`firstName`}
            variant="outlined"
            onChange={(event) => handleChange(event, index)}
            style={FormLabel}
            error={error && !firstRecord.persons[index]?.firstName}
          />
        </FormField>
        <FormField>
          <TextField
            required
            fullWidth
            size="small"
            value={firstRecord.persons[index]?.lastName || ""}
            label="Last Name"
            name={`lastName`}
            variant="outlined"
            onChange={(event) => handleChange(event, index)}
            style={FormLabel}
            error={error && !firstRecord.persons[index]?.lastName}
          />
        </FormField>
        <FormField>
          <FormControl
            required
            fullWidth
            size="small"
            error={error && !firstRecord.persons[index]?.gender}
          >
            <InputLabel>Gender</InputLabel>
            <Select
              required
              value={firstRecord.persons[index]?.gender}
              label="Gender"
              name={`gender`}
              variant="outlined"
              style={FormLabel}
              onChange={(event) => handleChange(event, index)}
            >
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Male">Male</MenuItem>
              {/* <MenuItem value="Other">Other</MenuItem> */}
            </Select>
          </FormControl>
        </FormField>
        <FormField>
          <TextField
            required
            fullWidth
            size="small"
            type="date"
            value={firstRecord.persons[index]?.DOB || ""}
            label="DOB"
            name={`DOB`}
            variant="outlined"
            onChange={(event) => handleChange(event, index)}
            style={FormLabel}
            error={error && !firstRecord.persons[index]?.DOB}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: getCurrentDate(), // Set the maximum date to the current date
            }}
          />
        </FormField>
        <FormField>
          <Autocomplete
            required
            fullWidth
            size="small"
            name={`nationality`}
            style={FormLabel}
            options={countries}
            getOptionLabel={(option) => option}
            value={firstRecord.persons[index]?.nationality || ""}
            onChange={(event, value) =>
              handleNationalityChange(event, value, index)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                required
                fullWidth
                label="Country"
                name={`nationality`}
                variant="outlined"
                style={FormLabel}
                value={firstRecord.persons[index]?.nationality || ""}
                onChange={(event, value) =>
                  handleNationalityChange(event, value, index)
                }
                error={error && !firstRecord.persons[index]?.nationality}
              />
            )}
          />
        </FormField>
      </CardContent>
    </StyledCard>
  );
}
export default PassengerInfoMobileView;
