import React from "react";
import { Outlet } from "react-router-dom";
import TopBar from "../TopBar";
import Box from "@mui/material/Box";

const TopBarLayout = () => {
  return (
    <>
      <Box sx={{ display: "flex", marginTop: "5.313rem" }}>
        <TopBar />
        <Outlet />
      </Box>
    </>
  );
};

export default TopBarLayout;
