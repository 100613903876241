import React, { useEffect, useState } from "react";
import {
  CardContent,
  CardHeader,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { StyledCard, TitleTypography } from "./ContactFormStyles";
import { FormField, FormLabel } from "../../formComponent/FormStyles";
import { areaCodes } from "../../../data/listOfAreaCodes";

function ContactFormView({
  firstRecord,
  handleChange,
  handleMobileChange,
  error,
  contactFormErrors,

}) {
  
 
  useEffect(() => {
    localStorage.setItem("firstRecord", JSON.stringify(firstRecord));
  }, [firstRecord]);

  return (
    <>
        <StyledCard>
          <CardHeader
            title={<TitleTypography>Contact Info</TitleTypography>}
            sx={{ padding: 0, paddingTop: "20px" }}
          ></CardHeader>
          <CardContent>
            <FormField>
              <TextField
                required
                fullWidth
                value={firstRecord.contactPerson}
                label="Contact Person"
                name="contactPerson"
                variant="outlined"
                onChange={handleChange}
                style={FormLabel}
                error={error && !firstRecord.contactPerson}
              ></TextField>
            </FormField>
            <FormField>
              <TextField
                required
                fullWidth
                value={firstRecord.email}
                onChange={handleChange}
                label="Email"
                type="email"
                variant="outlined"
                name="email"
                style={FormLabel}
                error={
                  (error && !firstRecord.email) ||
                  (error && contactFormErrors.emailError !== "")
                }
                // helperText={contactFormErrors.emailError}
              />
              <TextField
                fullWidth
                required
                label="Confirm Email"
                type="email"
                variant="outlined"
                name="confirmEmail"
                style={FormLabel}
                value={firstRecord.confirmEmail}
                // helperText={error && confirmEmailError}
                error={
                  (error && !firstRecord.confirmEmail) ||
                  (error && contactFormErrors.confirmEmailError !== "")
                }
                onChange={handleChange}
              />
            </FormField>
            <FormField>
              <TextField
                fullWidth
                required
                label="Address"
                variant="outlined"
                name="address"
                style={FormLabel}
                value={firstRecord.address}
                error={error && !firstRecord.address}
                onChange={handleChange}
              />
            </FormField>
            <FormField>
              <Select
                value={firstRecord.areaCode}
                onChange={handleChange}
                name="areaCode"
                variant="outlined"
                error={error && !firstRecord.areaCode}
                style={{ width: "100px" }}
              >
                {areaCodes.map((code, index) => (
                  <MenuItem key={index} value={code.prefix}>
                    {code.flag && (
                      <img
                        src={code.flag}
                        style={{ width: 20, marginRight: 5 }}
                      />
                    )}
                    {code.prefix}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                required
                fullWidth
                value={firstRecord.mobileNumber}
                label="Mobile Number"
                name="mobileNumber"
                variant="outlined"
                type="tel"
                style={FormLabel}
                onChange={handleMobileChange}
                error={
                  (error && !firstRecord.mobileNumber) ||
                  (error && contactFormErrors.mobileNumberError !== "")
                }
              ></TextField>
            </FormField>
          </CardContent>
        </StyledCard>
   
    </>
  );
}
export default ContactFormView;