import React, { createContext, useState } from 'react';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [state1, setState1] = useState({ firstRecord: null });

  return (
    <GlobalContext.Provider value={{ state1, setState1 }}>
      {children}
    </GlobalContext.Provider>
  );
};
