import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import theme from "../Theme";
import { ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "../assets/logo.png";
import IconButton from "@mui/material/IconButton";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import Badge from "@mui/material/Badge";
import { Button } from "@mui/material";
import MobilePopUpMenu from "./NavigationBar/MobilePopUpMenu";
import DesktopPagesMenu from "./NavigationBar/DesktopPagesMenu";
import DesktopIconMenu from "./NavigationBar/DesktopIconMenu";
import MobileIconMenu from "./NavigationBar/MobileIconMenu";
import MobileDrawerMenu from "./NavigationBar/MobileDrawerMenu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuComponent from "./dynamicNavBar/MenuComponent";

// const useStyles = makeStyles((theme) => ({
//   container: {
//     display: "flex",
//     alignItems: "center",
//   },
//   link: {
//     marginLeft: theme.spacing(1),
//   },
// }));
// const buttonStyles = {
//   backgroundColor: theme.palette.primary.main,
//   color: theme.palette.white.dark,
//   margin: "5px",
//   "&:hover": {
//     backgroundColor: theme.palette.primary.light,
//   },
// };



export default function TopBar(props) {
  const RIGHT_MENU_VALUE = ["My Profile", "Login", "Register"];
  const RIGHT_MENU_PATH = ["/Profile", "/Login", "/GrantsRegistrationPage"];
  const NAV_BAR_PAGES = window.navBarInfo.navBarPages;
  const NAV_BAR_PAGES_PATH = window.navBarInfo.navBarPagesPath;
  // const classes = useStyles();



  const handleLogoutSubmit = (e) => {
    e.preventDefault();
 
    // auth.signout(() => navigate("/Login"));
  };
  
  const modifyLink = localStorage.getItem("modifyLink");

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const pathname = currentUrl.pathname;

    if (NAV_BAR_PAGES_PATH.includes('/BookTicket') && pathname === '/BookTicket') {
      if (modifyLink !== 'true' && modifyLink !== null) {
        localStorage.clear();
      }
    }
  }, [NAV_BAR_PAGES_PATH, modifyLink]);

  const handleLoginClick = (e) => {
    e.preventDefault();
    console.log("log in");
    // navigate("/Login");
  };

  // Varibles for top-right menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  // Function to handle the resize event
  const handleResize = () => {
    // Set mobile menu anchor element to null if the screen size is larger
    if (window.innerWidth >= 900) {
      setMobileMoreAnchorEl(null);
    }
    // Set menu anchor element to null if the screen size is smaller
    if (window.innerWidth < 900) {
      setAnchorEl(null);
    }
  };

  // Add event listener on component mount and remove it on unmount
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePatientSignUpClick = (event) => {
    event.preventDefault();
    console.log("SIGN UP");
    // navigate("/SignUp");
  };
  const handlePatientLogInClick = (event) => {
    event.preventDefault();
    console.log("Login");
    // navigate("/Login");
  };
  const chatIconButton = (
    <Link to="/Chat">
      <IconButton
        size="large"
        aria-label="show 4 new messages"
        // sx={buttonStyles}
      >
        <Badge badgeContent={4} color="error">
          <ChatBubbleIcon />
        </Badge>
      </IconButton>
    </Link>
  );

  const LandingPageRightMenu = (
    <>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        {/* <Button color="primary" onClick={handleProviderSignUpClick}>
          Provider Sign Up
        </Button> */}
        <Button
          color="primary"
          variant="outlined"
          onClick={handlePatientLogInClick}
          sx={{ marginRight: "10px" }}
        >
          Log In
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handlePatientSignUpClick}
        >
          Sign Up
        </Button>
      </Box>
    </>
  );

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const handleOpenDrawer = (event) => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <ThemeProvider theme={theme}>
      {/* <CssBaseline /> */}
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* <MobilePopUpMenu
                pages={NAV_BAR_PAGES}
                pagesPath={NAV_BAR_PAGES_PATH}
              /> */}
              {/* <MobileDrawerMenu
                pages={NAV_BAR_PAGES}
                pagesPath={NAV_BAR_PAGES_PATH}
              /> */}
              <IconButton onClick={handleOpenDrawer} color="primary">
                <MenuIcon />
              </IconButton>
              {openDrawer && (
                <MenuComponent
                  open={openDrawer}
                  pageNames={NAV_BAR_PAGES}
                  pagePaths={NAV_BAR_PAGES_PATH}
                />
              )}
              <Link to="/">
                <img src={Logo} height={70} width={150} alt="aquarius icon" />
              </Link>
            </Box>

            <DesktopPagesMenu
              pages={NAV_BAR_PAGES}
              pagesPath={NAV_BAR_PAGES_PATH}
            />
            <Box sx={{ flexGrow: 1 }} />
            <DesktopIconMenu
              pages={RIGHT_MENU_VALUE}
              pagesPath={RIGHT_MENU_PATH}
            />
            <MobileIconMenu
              pages={RIGHT_MENU_VALUE}
              pagesPath={RIGHT_MENU_PATH}
            />
          </Toolbar>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
}
