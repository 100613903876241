import { styled } from "@mui/material/styles";
// import theme from "../Theme";

export const ContainerDiv = styled("div")(
  ({ customBgColor, customWidth, customHeight }) => ({
    display: "flex",
    alignItems: "center",
    backgroundColor: customBgColor,
    width: customWidth,
    height: customHeight,
  })
);
