import React, { useEffect , useState} from "react";
import PassengerInfoView from "./PassengerInfoView";
import PassengerInfoMobileView from "./PassengerInfoMobileView";
import { ThemeProvider } from "@mui/material";
import theme from "../../../Theme";
import useMediaQuery from "@mui/material/useMediaQuery";
function PassengerInfoBusiness({ index, firstRecord, setFirstRecord, submit ,   postData ,error,
  handleNationalityChangeFRecord ,
  handleChangeFRecord ,
  handleChangeFRecor,
  fRecord,  }) {
  //check if mobile
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // Initialize persons array when firstRecord changes
  React.useEffect(() => {
    // Ensure that the persons array is initialized properly
    if (
      !firstRecord.persons ||
      firstRecord.persons.length !== firstRecord.numberOfPassengers
    ) {
      const updatedPersons = Array.from(
        { length: firstRecord.numberOfPassengers },
        () => ({
          firstName: "",
          lastName: "",
          gender: "",
          type: "Adult",
          DOB: "1980-01-01",
          nationality: "Philippines",
        })
      );
      setFirstRecord((prev) => ({
        ...prev,
        persons: updatedPersons,
      }));
    }
  }, []);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  

  useEffect(() => {
    let nameParts = firstRecord.contactPerson.split(" ");
    setFirstName(nameParts[0] || "");
    setLastName(nameParts.length > 1 ? nameParts.slice(1).join(" ") : "");
  }, [firstRecord.contactPerson]);

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    const updatedPersons = [...firstRecord.persons];

    if (name === "DOB") {
      const personDOB = new Date(value);
      const currentDate = new Date();
      const age = Math.floor(
        (currentDate - personDOB) / (365.25 * 24 * 60 * 60 * 1000)
      );

      if (age >= 18) {
        updatedPersons[index] = {
          ...updatedPersons[index],
          type: "Adult",
          [name]: value,
        };
      } else {
        updatedPersons[index] = {
          ...updatedPersons[index],
          type: "Minor",
          [name]: value,
        };
      }
    } else {
      updatedPersons[index] = {
        ...updatedPersons[index],
        [name]: value,
      };
    }

    setFirstRecord({
      ...firstRecord,
      persons: updatedPersons,
    });
  };

  const handleNationalityChange = (event, value, index) => {
    const updatedPersons = [...firstRecord.persons];
    updatedPersons[index] = {
      ...updatedPersons[index],
      nationality: value,
    };
    setFirstRecord({
      ...firstRecord,
      persons: updatedPersons,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      {isSmallScreen ? (
        <PassengerInfoMobileView
          index={index}
          firstRecord={firstRecord}
          handleChange={handleChange}
          handleNationalityChange={handleNationalityChange}
          submit={submit}
          error={error}
        />
      ) : (
        <PassengerInfoView
          index={index}
          firstRecord={firstRecord}
          handleChange={handleChange}
          handleNationalityChange={handleNationalityChange}
          submit={submit}
          error={error}
          postData={postData}
          handleNationalityChangeFRecord={handleNationalityChangeFRecord}
          handleChangeFRecor={handleChangeFRecor}
          fRecord={fRecord}
      
        />
      )}
    </ThemeProvider>
  );
}
export default PassengerInfoBusiness;
