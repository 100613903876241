import theme from "../../Theme";

const useNavItemStyles = (isSmallScreen) => {
    // const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const navItemStyle = {
    display: "flex",
    alignItems: "center",
  };

  const navLabelStyle = {
    paddingLeft: "1rem",
    marginRight: "5px",
    fontWeight: "bold",
    color: theme.palette.primary.dark,
  };

  const navValueStyle = {
    marginRight: "10px",
    color: theme.palette.primary.light,
  };

  const navLinkStyle = {
    color: "blue",
    textDecoration: "underline",
    paddingRight: "2rem",
  };

  const dividerStyle = {
    width: "1px",
    height: "2.5rem",
    backgroundColor: "lightgray",
    margin: "0 5px",
  };

  const mobileBarStyle = {
    backgroundColor: "white",
    marginTop: "75px",
    height: "auto",
  };

  const MobileContainerDiv = {
    flex: 1,
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
  };
  const mobileValueStyle = {
    fontWeight: "bold",
    fontSize: isSmallScreen ? "12px":"16px",
    margin: "0 8px",
    color: theme.palette.primary.light,
  };
  const mobileDateStyle = {
    fontSize: isSmallScreen ? "10px":"14px",
    margin: "0 8px",
    color: theme.palette.primary.dark,
  };
  const mobileLinkStyle = {
    display: "flex",
    alignItems: "center",
    width: "2rem",
  };

  return {
    navItemStyle,
    navLabelStyle,
    navValueStyle,
    navLinkStyle,
    dividerStyle,
    mobileBarStyle,
    MobileContainerDiv,
    mobileValueStyle,
    mobileDateStyle,
    mobileLinkStyle,
  };
};

export default useNavItemStyles;
