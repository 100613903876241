import React, { useContext, useEffect, useState } from "react";
import { Button, useMediaQuery } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import theme from "../../../Theme";

import { FormField } from "../../../components/formComponent/FormStyles";
import ContactForm from "../../../components/infoComponents/contact/ContactFormBusiness";
import PassengerInfoForm from "../../../components/infoComponents/passengerInfo/PassengerInfoBusiness";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from "react-router-dom";
import CheckoutPopup from "../../../components/scheduleComponents/CheckoutPopup";
import CheckoutPopup2 from "../../../CheckoutPopup2";
import { GetCheckoutPopup } from "../../../apiCalls/FormAPI";
import { GlobalContext } from "../../../GlobalState";
import MobileSecondNavBar from "../../../components/SecondNav/MobileSecondNavBar";
import SecondNavBar from "../../../components/SecondNav/SecondNavBar";
import { Stepper, StepLabel } from "@mui/material";

import {
  ContainerDiv,
  StepperDiv,
  ContentDiv,
  HiddenLabelStep,
} from "../../../pages/tripsForm/TripsFormStyles";
import { validateFirstRecord } from "../../../functions/ValidationFunctions";

function FirstStepViewEdit({ handleBack, handleContinueClick }) {
  const location = useLocation();
  const state = location.state?.state || { passenger: 1 };
  let APIProvider = window.APIProvider;

  const steps = ["Schedules", "Passenger Info", "Payment", "Complete"];

  const initialPostData = {
    ReferenceNumber: "",
    PaymentType: 1,
    CheckoutId: "",
    RedirectURL: "",
    PaymentStatus: null,
    Message: "",
    OnLoad: 0,
    OnUpdate: 0,
    OnDelete: 0,
    CartID: "",
    Param2Value: "",
    SuccessCode: "",
    FailureCode: "",
    CancelCode: "",
    Amount: "",
  };

  const initialFirstRecord = {
    numberOfPassengers: "",
    contactPerson: "",
    areaCode: "+63",
    mobileNumber: "",
    email: "",
    confirmEmail: "",
    address: "",
    persons: [
      {
        firstName: "",
        lastName: "",
        gender: "",
        type: "Adult",
        DOB: "1980-01-01",
        nationality: "Philippines",
      },
    ],
  };

  const initialZeroRecord = {
    ...state,
    routeID: "",
    returnRouteID: "",
    tempID: "",
    returnTempID: "",
    tripID: "",
    returnTripID: "",
    seatType: "",
    seatTypeReturn: "",
    seatPrice: "",
    seatPriceReturn: "",
    terminalFees: "",
    terminalFeesReturn: "",
    otherFees: "",
    otherFeesReturn: "",
    other: "", // fees
    otherReturn: "", // fees
    acmID: "",
    acmIDReturn: "",
    pxtempid: "",
    pxtempidReturn: "",
  };

  const formData = JSON.parse(localStorage.getItem("formData"));
  const extractCodeFromUrl = (url) => {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    return params.get("code");
  };

  const successCode = extractCodeFromUrl(formData.redirectUrl.success);
  const failureCode = extractCodeFromUrl(formData.redirectUrl.failure);
  const cancelCode = extractCodeFromUrl(formData.redirectUrl.cancel);

  const storedPostData =
    JSON.parse(localStorage.getItem("postData")) || initialPostData;
  const storedFirstRecord =
    JSON.parse(localStorage.getItem("firstRecord")) || initialFirstRecord;
  const storedZeroRecord =
    JSON.parse(localStorage.getItem("zeroRecord")) || initialZeroRecord;
  localStorage.setItem("postData", JSON.stringify(storedPostData));
  localStorage.setItem("firstRecord", JSON.stringify(storedFirstRecord));
  localStorage.setItem("zeroRecord", JSON.stringify(storedZeroRecord));

  const firstRecordData = JSON.parse(localStorage.getItem("firstRecord"));
  const zeroRecord = JSON.parse(localStorage.getItem("zeroRecord"));

  const generateRequestReferenceNumber = () => uuidv4();

  const totalPrice = zeroRecord.oneWay
    ? (zeroRecord.seatPrice + zeroRecord.terminalFees) * zeroRecord.passenger
    : (zeroRecord.seatPrice + zeroRecord.terminalFees) * zeroRecord.passenger +
      (zeroRecord.seatPriceReturn + zeroRecord.terminalFeesReturn) *
        zeroRecord.passenger;

  let nameParts = firstRecordData.contactPerson.split(" ");
  let firstName = nameParts[0] || "";
  let lastName = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";
  let numberOfPassengers = zeroRecord.passenger;

  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const seconds = now.getSeconds();
  const formattedDateTime = `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;

  const formDataeb = {
    totalAmount: {
      currency: "PHP",
      value: totalPrice,
    },
    buyer: {
      contact: {
        phone: firstRecordData.mobileNumber,
        email: firstRecordData.confirmEmail,
      },
      firstName,
      lastName,
      tguid: storedPostData.CartID,
      PaymentStatus: storedPostData.PaymentStatus,
    },
    redirectUrl: {
      success: `${APIProvider.redirectURL}TripsForm?step=3&code=${successCode}`,
      failure: `${APIProvider.redirectURL}TripsForm?step=3&code=${failureCode}`,
      cancel: `${APIProvider.redirectURL}TripsForm?step=3&code=${cancelCode}`,
    },
    items: [
      {
        name: zeroRecord.request,
        quantity: numberOfPassengers,
        DepartDate: zeroRecord.depart,
        ReturnDate: zeroRecord.return,
        From: zeroRecord.fromName,
        To: zeroRecord.toName,
        nationality: firstRecordData.persons[0].nationality,
        BookingDate: formattedDateTime,
        amount: { value: totalPrice },
        totalAmount: { value: totalPrice },
      },
    ],
    requestReferenceNumber: generateRequestReferenceNumber(),
  };

  localStorage.setItem("NEWpostData", JSON.stringify(formDataeb));
  const strJsonData = encodeURIComponent(JSON.stringify(formDataeb));

  const handleSubmitEB = async () => {
    try {
      const response = await fetch(
        `${APIProvider.baseURL}${APIProvider.recordPath.GetMayPayCheckOutURL}?strJsonData=${strJsonData}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(
          `HTTP error! Status: ${response.status}, ${response.statusText}`
        );
      }

      const data = await response.json();


      const responseData = JSON.parse(data.apiResponse);
      localStorage.setItem("CheckoutId", responseData.checkoutId);
      localStorage.setItem("redirectUrl", responseData.redirectUrl);

      window.open(responseData.redirectUrl, "_self");
    } catch (error) {
      console.error("Error in handleSubmitTickets:", error);
    }
  };

  // State for Checkout Popup
  const [checkoutPopupOpen, setCheckoutPopupOpen] = useState(false);
  const [checkoutPopupOpen2, setCheckoutPopupOpen2] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState([]);
 
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedItems2, setCheckedItems2] = useState([]);

  const [firstRecord, setFirstRecord] = useState(
    JSON.parse(localStorage.getItem("firstRecord")) || {
      numberOfPassengers: state.passenger,
      contactPerson: "",
      areaCode: "+63",
      mobileNumber: "",
      email: "",
      confirmEmail: "",
      address: "",
      persons: [
        {
          firstName: "",
          lastName: "",
          gender: "",
          type: "Adult",
          DOB: "1980-01-01",
          nationality: "Philippines",
        },
      ],
    }
  );

  let fetched = false;

  const [contactFormErrors, setContactFormErrors] = useState({
    numberOfPassengers: "",
    mobileNumberError: "",
    emailError: "",
    confirmEmailError: "",
    requiredError: "",
  });

  // Alert vars
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState("");

  const handleContinueClickPopup = async () => {
    const { isValid, errors } = validateFirstRecord(firstRecord);
    setContactFormErrors(errors);
    const firstError = Object.values(errors).find((error) => error !== "");

    setSubmit(true);

    if (isValid) {
      if (!fetched) {
        const response = await GetCheckoutPopup();
        fetched = true;
        setConfirmationMessage(response);
        setCheckedItems(Array(response.length).fill(false));
      }
      // setCheckedItems(new Array(messages.length).fill(false));
      setCheckoutPopupOpen(true);
    } else {
      setError(firstError);
    }
  };

  const handleContinueClickPopup2 = async () => {
    const { isValid, errors } = validateFirstRecord(firstRecord);
    setContactFormErrors(errors);
    const firstError = Object.values(errors).find((error) => error !== "");

    setSubmit(true);

    if (isValid) {
      if (!fetched) {
        const response = await GetCheckoutPopup();

        fetched = true;
       
        setConfirmationMessage(response);
        setCheckedItems2(Array(response.length).fill(false));
      }
      // setCheckedItems(new Array(messages.length).fill(false));
      setCheckoutPopupOpen2(true);
    } else {
      setError(firstError);
    }
  };

  // const firstRecord = JSON.parse(localStorage.getItem("firstRecord"));

  const initialData = JSON.parse(localStorage.getItem("firstRecord")) || {
    numberOfPassengers: "",
    contactPerson: "",
    email: "",
    confirmEmail: "",
    address: "",
    areaCode: "",
    mobileNumber: "",
    persons: [
      {
        firstName: "",
        lastName: "",
        gender: "",
        DOB: "",
        nationality: "",
        type: "",
      },
    ],
  };

  const { state1 } = useContext(GlobalContext);
  const initialDatsa = state1?.firstRecord;



  const [fRecord, setFRecord] = useState(initialDatsa);

  const handleChangeFRecor = (event, index) => {
    const { name, value } = event.target;
    const updatedPersons = [...fRecord.persons];

    if (name === "DOB") {
      const personDOB = new Date(value);
      const currentDate = new Date();
      const age = Math.floor(
        (currentDate - personDOB) / (365.25 * 24 * 60 * 60 * 1000)
      );

      if (age >= 18) {
        updatedPersons[index] = {
          ...updatedPersons[index],
          type: "Adult",
          [name]: value,
        };
      } else {
        updatedPersons[index] = {
          ...updatedPersons[index],
          type: "Minor",
          [name]: value,
        };
      }
    } else {
      updatedPersons[index] = {
        ...updatedPersons[index],
        [name]: value,
      };
    }

    setFRecord({
      ...fRecord,
      [name]: value,
      persons: updatedPersons,
    });
  };

  useEffect(() => {
    localStorage.setItem("firstRecord", JSON.stringify(fRecord));
  }, [fRecord]);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));

  

  const modifyLink = localStorage.getItem("modifyLink") === "true";
  useEffect(() => {
    if (modifyLink) {
      const storedFirstRecord = JSON.parse(localStorage.getItem("firstRecord")) || initialFirstRecord;
      setFirstRecord(storedFirstRecord);
    }
  }, [modifyLink, setFirstRecord]);

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      {isSmallScreen ? (
        <MobileSecondNavBar state={zeroRecord} />
      ) : (
        <SecondNavBar state={zeroRecord} />
      )}
      <div
        style={{
          marginTop: "1rem",
          width: isSmallScreen ? "90%" : isMediumScreen ? "60%" : "40%",
        }}
      >
        <ContainerDiv>
          <StepperDiv>
            <Stepper
              activeStep={1}
              alternativeLabel
              // sx={{ width: "100%" }}
            >
              {steps.map((label, index) => (
                <HiddenLabelStep key={label}>
                  <StepLabel>{label}</StepLabel>
                </HiddenLabelStep>
              ))}
            </Stepper>
          </StepperDiv>
        </ContainerDiv>

        <ContactForm
          firstRecord={firstRecord}
          setFirstRecord={setFirstRecord}
          submit={submit}
          contactFormErrors={contactFormErrors}
          fRecord={fRecord}
          handleChangeFRecor={handleChangeFRecor}
          error={error}
        />

        {[...Array(parseInt(firstRecord.numberOfPassengers, 10))].map(
          (_, index) => (
            <PassengerInfoForm
              key={index}
              index={index}
              firstRecord={firstRecord}
              setFirstRecord={setFirstRecord}
              submit={submit}
              style={{ marginBottom: "2rem" }}
              fRecord={fRecord}
              handleChangeFRecor={handleChangeFRecor}
              error={error}
            />
          )
        )}

        <FormField>
          <Button
            fullWidth
            color="primary"
            aria-label="search"
            onClick={handleBack}
            variant="outlined"
          >
            Back
          </Button>
          {storedPostData.PaymentStatus !== 2 &&
          storedPostData.PaymentStatus !== 3 ? (
            <Button
              sx={{ marginLeft: "0.5rem" }}
              fullWidth
              color="primary"
              aria-label="search"
              onClick={handleContinueClickPopup}
              variant="contained"
            >
              Continue
            </Button>
          ) : (
            <Button
              sx={{ marginLeft: "0.5rem" }}
              fullWidth
              color="primary"
              aria-label="search"
              onClick={handleContinueClickPopup2}
              variant="contained"
            >
              Continue
            </Button>
          )}
        </FormField>

        <CheckoutPopup
          open={checkoutPopupOpen}
          handlePopup={() => setCheckoutPopupOpen(false)}
          confirmationMessage={confirmationMessage}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          firstRecord={firstRecord}
          checkoutPopupSubmit={false}
          setCheckoutPopupSubmit={() => {}}
          handleContinueClick={handleContinueClick}
        />

        <CheckoutPopup2
          open={checkoutPopupOpen2}
          handlePopup={() => setCheckoutPopupOpen2(false)}
          confirmationMessage={confirmationMessage}
          checkedItems={checkedItems2}
          setCheckedItems={setCheckedItems2}
          firstRecord={firstRecord}
          checkoutPopupSubmit={false}
          setCheckoutPopupSubmit={() => {}}
          handleSubmitEB={handleSubmitEB}
        />
      </div>
    </div>
  );
}

export default FirstStepViewEdit;
