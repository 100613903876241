import React, { useEffect, useState, useRef, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import WarningBox from "./components/infoComponents/warning/WarningBox";
import Logo from "./assets/logo.png";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  Grid,
  Button,
} from "@mui/material";
import theme from "./Theme";
import {
  PostMainPassengerContacts,
  PostPassengerContacts,
  PostTickets,
} from "./apiCalls/FormAPI";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import {
  PostMainPassengerContactsModel,
  PostPassengerContactsModel,
  PostTicketModel,
} from "./pages/tripsForm/TripsFormModel";
import { GlobalContext } from "./GlobalState";
import { useReactToPrint } from "react-to-print";

const Status = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let APIProvider = window.APIProvider;

  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");
  const successKey = localStorage.getItem("SUCCESS");
  const failureKey = localStorage.getItem("FAILURE");
  const cancelKey = localStorage.getItem("CANCEL");
  const CheckoutId = localStorage.getItem("CheckoutId");
  const redirectUrl = localStorage.getItem("redirectUrl");
  const Referncenumber = localStorage.getItem("Referncenumber");
  const zeroRecord = JSON.parse(localStorage.getItem("zeroRecord"));
  const fromTrip = JSON.parse(localStorage.getItem("fromTrip"));
  const record = JSON.parse(localStorage.getItem("record"));
  const firstRecord = JSON.parse(localStorage.getItem("firstRecord"));
  const formData = JSON.parse(localStorage.getItem("formData"));
  const postDatanew = JSON.parse(localStorage.getItem("postData"));
  const departureDateTime = fromTrip ? fromTrip.departure : "";
  const normalizeTime = (timeString) => {
    const date = new Date(timeString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };
  const normalizedTime = normalizeTime(departureDateTime);

  const [expanded, setExpanded] = useState({
    departure: true,
    return: true,
  });

  const [showHelloWorld, setShowHelloWorld] = useState(false);

  const [tguidUUID] = useState(uuidv4());
  const generateRequestReferencetguid = () => {
    localStorage.setItem("tguid", tguidUUID);
    return tguidUUID;
  };

  const newPOST = JSON.parse(localStorage.getItem("NEWpostData"));

  const totalPrice = zeroRecord.oneWay
    ? (zeroRecord.seatPrice + zeroRecord.terminalFees) * zeroRecord.passenger
    : (zeroRecord.seatPrice + zeroRecord.terminalFees) * zeroRecord.passenger +
      (zeroRecord.seatPriceReturn + zeroRecord.terminalFeesReturn) *
        zeroRecord.passenger;

  const initialTguid =
    newPOST?.buyer?.PaymentStatus === 2 || newPOST?.buyer?.PaymentStatus === 3
      ? newPOST.buyer.tguid
      : generateRequestReferencetguid();

  const initialpayamount =
    newPOST?.buyer?.PaymentStatus === 2 || newPOST?.buyer?.PaymentStatus === 3
      ? String(newPOST.totalAmount.value)
      : String(formData.totalAmount.value);
  console.log("record", record);

  const [secondRecord, setSecondRecord] = useState({
    totalPrice: 0,
    nameOnCard: "",
    cardNumber: "",
    expirationMonth: "",
    expirationYear: "",
    securityCode: "",
    creditCardAddress: "",
    TGUID: initialTguid,
    CartStatus:
      code === failureKey
        ? "failed"
        : code === successKey
        ? "paid"
        : code === cancelKey
        ? "cancelled"
        : "unknown",
    totalAmount: String(totalPrice),
    totalPaid:
      code === failureKey ? "0" : code === cancelKey ? "0" : String(totalPrice),
    totalBalance:
      code === failureKey
        ? String(totalPrice)
        : code === cancelKey
        ? String(totalPrice)
        : String(totalPrice - totalPrice),
    returnFlag: record.roundTrip === true ? "1" : "0",
    isReturn: "",
  });

  if (postDatanew) {
    if (postDatanew.hasOwnProperty("ReferenceNumber")) {
      postDatanew.ReferenceNumber = Referncenumber;
    }
    if (postDatanew.hasOwnProperty("CheckoutId")) {
      postDatanew.CheckoutId = CheckoutId;
    }
    if (postDatanew.hasOwnProperty("PaymentStatus")) {
      postDatanew.PaymentStatus = null;
    }
    if (postDatanew.hasOwnProperty("RedirectURL")) {
      postDatanew.RedirectURL = redirectUrl;
    }
    if (postDatanew.hasOwnProperty("Message")) {
      postDatanew.Message = "";
    }
    if (postDatanew.hasOwnProperty("CartID")) {
      postDatanew.CartID = initialTguid;
    }
    if (postDatanew.hasOwnProperty("SuccessCode")) {
      postDatanew.SuccessCode = successKey;
    }
    if (postDatanew.hasOwnProperty("FailureCode")) {
      postDatanew.FailureCode = failureKey;
    }
    if (postDatanew.hasOwnProperty("CancelCode")) {
      postDatanew.CancelCode = cancelKey;
    }
    if (postDatanew.hasOwnProperty("Amount")) {
      postDatanew.Amount = initialpayamount;
    }

    if (code === successKey) {
      postDatanew.PaymentStatus = 1;
      postDatanew.Message = "Payment processed successfully";
    } else if (code === failureKey) {
      postDatanew.PaymentStatus = 2;
      postDatanew.Message = "Payment Failed";
    } else if (code === cancelKey) {
      postDatanew.PaymentStatus = 3;
      postDatanew.Message = "Payment Cancelled";
    } else {
      postDatanew.PaymentStatus = null;
      postDatanew.Message = "";
    }

    localStorage.setItem("postData", JSON.stringify(postDatanew));
  } else {
    // console.log("postDatanew object does not exist");
  }

  const handleSubmitPostQuickInfoPayment = async () => {
    try {
      const response = await fetch(
        `${APIProvider.baseURL}${APIProvider.recordPath.PostQuickInfoPayment}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postDatanew),
        }
      );

      if (!response.ok) {
        console.error("Network response was not ok", response);
        const text = await response.text();
        throw new Error(text);
      }

      if (postDatanew.PaymentStatus === 2 || postDatanew.PaymentStatus === 3) {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = async () => {
    let tripInfo = { ...zeroRecord, ...firstRecord, ...secondRecord };

    let mainPassenger = [];

    mainPassenger.push(PostMainPassengerContactsModel(tripInfo));
    try {
      await PostMainPassengerContacts(mainPassenger);
      // console.log("PostMainPassengerContacts Success");
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  };

  const handleSubmitPostPayment = () => {
    const postPayment = [
      {
        cartType: zeroRecord.request,
        payMethod: "Maya Pay",
        tguid: initialTguid,
        payee: firstRecord.contactPerson,
        payAmount: initialpayamount,
        billing_Address: firstRecord.address,
        createdBy: "web",
        modifiedBy: "admin",
      },
    ];

    fetch(`${APIProvider.baseURL}${APIProvider.recordPath.PostPayment}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postPayment),
    })
      .then((response) => {
        if (!response.ok) {
          console.error("Network response not ok", response);
          return response.text().then((text) => {
            throw new Error(text);
          });
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded({
      ...expanded,
      [panel]: isExpanded,
    });
  };

  let tripInfo = { ...zeroRecord, ...firstRecord, ...secondRecord };

  const TGUID = tripInfo.TGUID;

  const [From, setFrom] = useState([]);
  const [To, setTo] = useState([]);

  const [loading, setLoading] = useState(false);

  const handlegetTicketDetails = async () => {
    try {
      const response = await fetch(
        APIProvider.baseURL +
          APIProvider.recordPath.GetTicketDetails +
          `?TGUID=${TGUID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      const ticketDetails = JSON.parse(responseData[0]);
      console.log("responseData ", responseData);

      const ticketNosFrom = [];
      const ticketNosTo = [];

      ticketDetails.forEach((ticket, index) => {
        const ticketInfo = `${
          ticket.TicketNo
        } - ${ticket.PassengerName.trim()}`;

        if (tripInfo.roundTrip === false) {
          ticketNosFrom.push(ticketInfo);
        } else {
          if (index % 2 === 0) {
            ticketNosFrom.push(ticketInfo);
          } else {
            ticketNosTo.push(ticketInfo);
          }
        }
      });

      localStorage.setItem("TicketNosFrom:", JSON.stringify(ticketNosFrom));

      localStorage.setItem("TicketNosTo:", JSON.stringify(ticketNosTo));
      setFrom(ticketNosFrom);
      setTo(ticketNosTo);

      const mergedTicketNos = [];
      for (
        let i = 0;
        i < Math.max(ticketNosFrom.length, ticketNosTo.length);
        i++
      ) {
        if (ticketNosFrom[i]) {
          mergedTicketNos.push(ticketNosFrom[i]);
        }
        if (ticketNosTo[i]) {
          mergedTicketNos.push(ticketNosTo[i]);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  console.log("from ", From);
  console.log("to ", To);

  const TicketNumFrom =
    JSON.parse(localStorage.getItem("TicketNosFrom:")) || [];

  const TicketNumTo = JSON.parse(localStorage.getItem("TicketNosTo:")) || [];

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmitTickets = async () => {
    setLoading(true);
    let persons = firstRecord.persons;
    let postTicketInfo = [];

    persons.forEach((person) => {
      const oneWayTicket = PostTicketModel(tripInfo, true, person);

      const roundTripTicket = zeroRecord.roundTrip
        ? PostTicketModel(tripInfo, false, person)
        : null;

      if (zeroRecord.oneWay) {
        postTicketInfo.push(oneWayTicket);
      } else {
        postTicketInfo.push(oneWayTicket, roundTripTicket);
      }
    });

    try {
      const response = await PostTickets(postTicketInfo);

      if (!response.isError) {
        const tickets = response.outfields.match(/\d+/g).map(Number);
        console.log("tickets ", tickets);

        let postTicketComplementInfo = [];
        let mainPassenger = [];

        mainPassenger.push(PostMainPassengerContactsModel(tripInfo));

        for (let i = 0; i < tickets.length; i++) {
          if (tripInfo.roundTrip) {
            postTicketComplementInfo.push(
              PostPassengerContactsModel(
                tickets[i],
                tripInfo,
                Math.floor(i / 2),
                i,
                i % 2 === 0 ? true : false
              )
            );
          } else {
            postTicketComplementInfo.push(
              PostPassengerContactsModel(tickets[i], tripInfo, i, i, true)
            );
          }
        }

        console.log("postTicketInfo", postTicketInfo);
        console.log("mainPassenger  ", mainPassenger);
        console.log("postTicketComplementInfo  ", postTicketComplementInfo);

        setSecondRecord((prevState) => ({ ...prevState, isReturn: "0" }));

        await PostMainPassengerContacts(mainPassenger);

        await PostPassengerContacts(postTicketComplementInfo);
        handleSubmitPostPayment();
        handlegetTicketDetails();

        setIsSubmitted(true);
      }
    } catch (error) {
      console.error("Error in handleSubmitTickets:", error);
    }
  };

  const postRequestMadeRef = useRef(false);

  const vesselName = fromTrip ? fromTrip.vesselName : "";
  const fromName = record ? record.fromName : "";
  const toName = record ? record.toName : "";
  const passenger = record ? record.passenger : "";

  const renderAccordion = (id, label, vesselName, date) => {
    const seatTypeToDisplay =
      label === "Departure" ? record.seatType : record.seatTypeReturn;

    const priceToDisplay =
      label === "Departure"
        ? (record.seatPrice + record.terminalFees) * record.passenger
        : (record.seatPriceReturn + record.terminalFeesReturn) *
          record.passenger;

    const price =
      label === "Departure" ? record.seatPrice : record.seatPriceReturn;

    const TicketNumber = label === "Departure" ? TicketNumFrom : TicketNumTo;

    const formattedTickets = TicketNumber.map(
      (ticket, index) => `${index + 1}. ${ticket}`
    );

    const getPaymentStatus = () => {
      let statusText = "";
      let statusColor = "";
      let statusBold = "";

      if (code === successKey) {
        statusText = "Payment Success";
        statusColor = "green";
        statusBold = "bold";
      } else if (code === failureKey) {
        statusText = "Payment Failed";
        statusColor = "red";
        statusBold = "bold";
      } else if (code === cancelKey) {
        statusText = "Payment Cancelled";
        statusColor = "red";
        statusBold = "bold";
      } else {
        statusText = "Unknown";
      }

      return (
        <span style={{ color: statusColor, fontWeight: statusBold }}>
          {statusText}
        </span>
      );
    };
    return (
      <Accordion
        className="accordian-summ-ht"
        expanded={expanded[label.toLowerCase()]}
        onChange={handleAccordionChange(label.toLowerCase())}
        square={false}
        sx={{
          flex: 1,
          "&.Mui-expanded": {
            margin: "0px",
            borderRadius: "4px",
          },
        }}
        key={id}
      >
        <AccordionSummary
          className="accordian-summ-ht"
          // expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
          sx={{
            borderRadius: "5px",
            backgroundColor: theme.palette.primary.main,
            height: "30px !important",
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
              height: "30px !important",
            },
          }}
        >
          <Typography color="common.white">{label}</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Grid item>
            <div className="value-name">
              <div className="item-name">Vessel</div>
              <div className="item-label-name">{vesselName}</div>
            </div>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Grid item>
            <div className="value-name">
              <div className="item-name">
                {label === "Departure" ? "Departure Date" : "Return Date"}
              </div>
              <div className="item-label-name">
                {date} &nbsp;
                {normalizedTime}
              </div>
            </div>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Grid item>
            <div className="value-name">
              <div className="item-name">Accomodation</div>
              <div className="item-label-name">{seatTypeToDisplay}</div>
            </div>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Grid item>
            <div className="value-name">
              <div className="item-name">From</div>
              <div className="item-label-name">{fromName}</div>
            </div>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Grid item>
            <div className="value-name">
              <div className="item-name">To</div>
              <div className="item-label-name">{toName}</div>
            </div>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Grid item>
            <div className="value-name">
              <div className="item-name">Number OF Tickets</div>
              <div className="item-label-name">{passenger}</div>
            </div>
          </Grid>
          {postDatanew.PaymentStatus !== 2 &&
            postDatanew.PaymentStatus !== 3 && (
              <>
                <Divider sx={{ my: 2 }} />

                <Grid item>
                  <div className="value-name">
                    <div className="item-name">Ticket Number</div>
                    {loading ? (
                      <div>Loading...</div>
                    ) : (
                      <div className="item-label-name">
                        {formattedTickets.map((ticket, index) => (
                          <div key={index}>
                            <div>{ticket}</div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </Grid>
              </>
            )}

          <Divider sx={{ my: 2 }} />
          <Grid item>
            <div className="value-name">
              <div className="item-name">Payment Status</div>
              <div className="item-label-name">{getPaymentStatus()}</div>
            </div>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Grid item>
            <div className="value-name">
              <div className="item-name">Price</div>
              <div className="item-label-name">₱{price}</div>
            </div>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Grid item>
            <div className="value-name">
              <div className="item-name">Total Price</div>
              <div className="item-label-name">₱{priceToDisplay}</div>
            </div>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  const { setState1, state1 } = useContext(GlobalContext);

  const handleBackClick = (event) => {
    event.preventDefault();
    setState1((prevState) => ({ ...prevState, firstRecord }));
    navigate("/TripsFormstep=1");
  };

  const handleBackClose = () => {
    navigate("/");
    localStorage.clear();
  };

  useEffect(() => {
    if (!postRequestMadeRef.current) {
      if (code === successKey && !localStorage.getItem("dataPosted")) {
        toast.success("Payment processed successfully");
        handleSubmitPostQuickInfoPayment();
        handleSubmitTickets();

        localStorage.setItem("modifyLink", "false");
        localStorage.setItem("dataPosted", "true");

        postRequestMadeRef.current = true;
      } else if (code === failureKey) {
        toast.error("Payment Failed");
        handleSubmitPostQuickInfoPayment();
        handleSubmit();
        postRequestMadeRef.current = true;
      } else if (code === cancelKey) {
        toast.warn("Payment Cancelled");
        handleSubmitPostQuickInfoPayment();
        handleSubmit();
        postRequestMadeRef.current = true;
      }
    }
  }, [code]);

  // const oneWayMessage = `Your One way ticket from  ${record.fromName} to ${record.toName} will be emailed to ${formData.buyer.contact.email}

  // const roundTripMessage = `Your departure ticket from  ${record.fromName} to ${record.toName} and your return ticket from ${record.toName} to ${record.fromName} will be emailed to ${formData.buyer.contact.email}

  const oneWayMessage = (
    <>
      Your One way ticket from {record.fromName} to {record.toName} will be
      emailed to {formData.buyer.contact.email} shortly.
      <br />
      To print your ticket, go to{" "}
      <a
        href={`https://aiworksdev.agiline.com//global/index?globalurlid=E19C6E43-2D42-4128-8559-DDA4C884DC9C&param1=${localStorage.getItem(
          "tguid"
        )}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        this link
      </a>
      .
    </>
  );
  const roundTripMessage = (
    <>
      Your departure ticket from {record.fromName} to {record.toName} and your
      return ticket from {record.toName} to {record.fromName} will be emailed to{" "}
      {formData.buyer.contact.email} shortly.
      <br />
      To print your ticket, go to{" "}
      <a
        href={`https://aiworksdev.agiline.com//global/index?globalurlid=E19C6E43-2D42-4128-8559-DDA4C884DC9C&param1=${localStorage.getItem(
          "tguid"
        )}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        this link
      </a>
      .
    </>
  );

  const messages = {
    oneWayMessage,
    roundTripMessage,
  };

  const dataPosted = localStorage.getItem("dataPosted");
  useEffect(() => {
    if (dataPosted) {
      setIsSubmitted(true);
    }
  }, []);

  const Container = styled.section`
    display: flex;
    width: 90%;
    margin: 0 auto;
    max-width: 700px;
    flex-direction: column;
    margin-top: 10px;
    @media (max-width: 700px) {
      max-width: 500px;
    }
    @media (max-width: 600px) {
      max-width: 300px;
    }
  `;
  const DepRetPrty = styled.div`
    display: flex;
    @media (max-width: 700px) {
      flex-direction: column;
    }
    .return-down {
      margin-top: 10px;
    }
  `;
  const Oneway = styled.div`
    display: block;
    borderradius: "10px" @media (max-width: 700px) {
      flex-direction: column;
    }
    // .return-down {
    //   margin-top: 10px;
    // }
  `;

  const FailedTicket = styled.div`
    display: flex;
    width: 700px;
    margin: auto;
  `;
  const OneFailedTicket = styled.div`
    display: block;
    width: 700px;
    margin: auto;
  `;
  const Wrapper = styled.div`
    width: 100%;
  `;

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <section style={{ width: "100%" }}>
        <ToastContainer position="bottom-right" />
        {code === successKey ? (
          <div>
            <Container>
              <div>
                <div style={{ display: "flex" }}>
                  <Wrapper>
                    <div>
                      {messages ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {record.roundTrip === true ? (
                            <WarningBox
                              message={messages.roundTripMessage}
                              type={1}
                            />
                          ) : (
                            <WarningBox
                              message={messages.oneWayMessage}
                              type={1}
                            />
                          )}
                        </div>
                      ) : (
                        <h1>No message received</h1>
                      )}
                    </div>
                  </Wrapper>
                </div>
                <div ref={componentRef} className="print">
                  <div
                    className="print-only"
                    style={{ display: "none", margin: "0.25in" }}
                  >
                    <Link to="/">
                      <img
                        src={Logo}
                        height={70}
                        width={150}
                        alt="aquarius icon"
                      />
                    </Link>
                  </div>
                  {/* <DepRetPrty>
                {!showHelloWorld &&
                  renderAccordion(1, "Departure", vesselName, record.depart)}
                <div className="return-down" style={{ width: "10px" }} />
                {!showHelloWorld &&
                  record.roundTrip &&
                  renderAccordion(2, "Return", vesselName, record.return)}
              </DepRetPrty> */}

                  {record.roundTrip ? (
                    <DepRetPrty>
                      {!showHelloWorld &&
                        renderAccordion(
                          1,
                          "Departure",
                          vesselName,
                          record.depart
                        )}
                      <div className="return-down" style={{ width: "10px" }} />
                      {!showHelloWorld &&
                        record.roundTrip &&
                        renderAccordion(2, "Return", vesselName, record.return)}
                    </DepRetPrty>
                  ) : (
                    <Oneway>
                      {!showHelloWorld &&
                        renderAccordion(
                          1,
                          "Departure",
                          vesselName,
                          record.depart
                        )}
                      <div className="return-down" style={{ width: "10px" }} />
                      {!showHelloWorld &&
                        record.roundTrip &&
                        renderAccordion(2, "Return", vesselName, record.return)}
                    </Oneway>
                  )}
                </div>
              </div>
              <div className="btn-div-print-cls">
                <Button
                  className="cls-pnt-btn"
                  variant="outlined"
                  onClick={handleBackClose}
                  // fullWidth
                  disabled={!isSubmitted}
                >
                  Close
                </Button>
                <Button
                  className="cls-pnt-btn btn-print"
                  variant="outlined"
                  onClick={handlePrint}
                  style={{ backgroundColor: "#11375C", color: "#fff" }}
                >
                  Print
                </Button>
              </div>
            </Container>
          </div>
        ) : code === failureKey ? (
          <div>
            {record.roundTrip ? (
              <FailedTicket>
                {renderAccordion(1, "Departure", vesselName, record.depart)}
                <div style={{ width: "10px" }} />
                {record.roundTrip &&
                  renderAccordion(2, "Return", vesselName, record.return)}
              </FailedTicket>
            ) : (
              <OneFailedTicket>
                {renderAccordion(1, "Departure", vesselName, record.depart)}
                <div style={{ width: "10px" }} />
                {record.roundTrip &&
                  renderAccordion(2, "Return", vesselName, record.return)}
              </OneFailedTicket>
            )}
            <div style={{ display: "flex", width: "700px", margin: "auto" }}>
              <Button
                variant="outlined"
                onClick={handleBackClick}
                fullWidth
                style={{ flex: 1, marginTop: "15px" }}
              >
                BACK TO ITINERARY
              </Button>
            </div>
          </div>
        ) : code === cancelKey ? (
          <div>
            <div style={{ display: "flex", width: "700px", margin: "auto" }}>
              {renderAccordion(1, "Departure", vesselName, record.depart)}
              <div style={{ width: "10px" }} />
              {record.roundTrip &&
                renderAccordion(2, "Return", vesselName, record.return)}
            </div>
            <div style={{ display: "flex", width: "680px", margin: "auto" }}>
              <Button
                variant="outlined"
                onClick={handleBackClick}
                fullWidth
                style={{ flex: 1, marginTop: "15px", marginLeft: "-11px" }}
              >
                BACK TO ITINERARY
              </Button>
            </div>
          </div>
        ) : null}
      </section>
      <style>{`
      .print-only {
        display: none;
      }
      @media print {
        .print-only {
          display: block !important;
        }
      }
        @media print{
        .print{
        padding : 20px
        }}
    `}</style>
    </>
  );
};

export default Status;
