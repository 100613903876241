import React from "react";
import { useState } from "react";
import { IconButton, Menu, MenuItem, Box, Badge } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { Link } from "react-router-dom";
import theme from "../../Theme";
import { v4 as uuid } from "uuid";
import MenuComponent from "../dynamicNavBar/MenuComponent";

const buttonStyles = {
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.white.dark,
  margin: "5px",
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
};

function DesktopIconMenu({ pages, pagesPath }) {

  // Varibles for top-right menu
  const [anchorEl, setAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = (event) => {
    setAnchorEl(null);
  };

  const handleLogoutSubmit = (e) => {
    e.preventDefault();

    // auth.signout(() => navigate("/Login"));
  };

  let loginButton = <MenuItem onClick={handleLogoutSubmit}>Logout</MenuItem>;

  return (
    <Box sx={{ display: { xs: "none", md: "flex" } }}>
      <Link to="/Chat">
        <IconButton
          size="large"
          aria-label="show 4 new messages"
          sx={buttonStyles}
        >
          <Badge badgeContent={4} color="error">
            <ChatBubbleIcon />
          </Badge>
        </IconButton>
      </Link>
      <IconButton
        size="large"
        edge="end"
        aria-label="account of current user"
        // aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        sx={buttonStyles}
      >
        <AccountCircle />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        // id={menuId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMenuOpen}
        onClose={handleProfileMenuClose}
      >
        {pages.map((text, index) => (
          <MenuItem key={uuid()} onClick={handleProfileMenuClose}>
            <Link
              to={pagesPath[index]}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {text}
            </Link>
          </MenuItem>
        ))}
        {/* {loginButton} */}
      </Menu>
    </Box>
  );
}
export default DesktopIconMenu;
