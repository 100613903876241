import React from "react";
import { Link, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import EditIcon from "@mui/icons-material/Edit";
import theme from "../../Theme";
import useNavItemStyles from "./SecondNavBarStyle";
import { useMediaQuery } from "@mui/material";

const SecondNavBar = ({ state }) => {
  const initialRecord = {
    roundTrip: "",
    oneWay: "",
    from: "",
    fromName: "",
    to: "",
    toName: "",
    depart: "",
    return: "",
    passenger: "",
    TGUID: "",
    request: "",
    routeID: "",
    returnRouteID: "",
    tempID: "",
    returnTempID: "",
    tripID: "",
    returnTripID: "",
    seatType: "",
    seatTypeReturn: "",
    seatPrice: "",
    seatPriceReturn: "",
    terminalFees: "",
    terminalFeesReturn: "",
    otherFees: "",
    otherFeesReturn: "",
    other: "",
    otherReturn: "",
    acmID: "",
    acmIDReturn: "",
    withDriver: true,
  };

  const record = JSON.parse(localStorage.getItem("record")) || initialRecord;
  const styles = useNavItemStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const stepParam = queryParams.get("step");

  const hideModifyLink = stepParam === "3";

  const modifyLink = `/BookTicket?roundTrip=${
    state.roundTrip || record.roundTrip
  }&origin=${state.from || record.from}&destination=${
    state.to || record.to
  }&passenger=${state.passenger || record.passenger}&request=${
    state.request || record.request
  }&departDate=${state.depart || record.depart}&returnDate=${
    state.return || record.return
  }&request=${state.request || record.request}&withDriver=${
    state.withDriver || record.withDriver
  }`;

  const redirectToModifyLink = () => {
    localStorage.setItem("modifyLink", true);
    window.location.href = modifyLink;
  };

  return (
    <>
      {!hideModifyLink && (
        <AppBar
          position="fixed"
          // color="white"
          elevation={0}
          sx={{
            backgroundColor: "white",
            marginTop: "75px",
            height: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "black",
              padding: "10px",
            }}
          >
            <div style={styles.navItemStyle}>
              <span style={styles.navLabelStyle}>From:</span>
              <span style={styles.navValueStyle}>
                {state.fromName || record.fromName}
              </span>
            </div>
            <div style={styles.dividerStyle} />
            <div style={styles.navItemStyle}>
              <span style={styles.navLabelStyle}>To:</span>
              <span style={styles.navValueStyle}>
                {state.toName || record.toName}
              </span>
            </div>
            <div style={styles.dividerStyle} />
            <div style={styles.navItemStyle}>
              <span style={styles.navLabelStyle}>Passenger:</span>
              <span style={styles.navValueStyle}>
                {state.passenger || record.passenger}
              </span>
            </div>
            <div style={styles.dividerStyle} />
            <div style={styles.navItemStyle}>
              <span style={styles.navLabelStyle}>Depart:</span>
              <span style={styles.navValueStyle}>
                {state.depart || record.depart}
              </span>
            </div>
            {state.roundTrip && (
              <>
                <div style={styles.dividerStyle} />
                <div style={styles.navItemStyle}>
                  <span style={styles.navLabelStyle}>Return:</span>
                  <span style={styles.navValueStyle}>
                    {state.return || record.return}
                  </span>
                </div>
              </>
            )}

            <>
              <div style={styles.dividerStyle} />
              <Link
                to={modifyLink}
                style={styles.navLinkStyle}
                onClick={redirectToModifyLink}
              >
                <EditIcon
                  sx={{
                    height: "1rem",
                    width: "1rem",
                    paddingRight: "0.3rem",
                  }}
                />
                Modify Itinerary
              </Link>
            </>
          </div>
        </AppBar>
      )}
    </>
  );
};

export default SecondNavBar;
