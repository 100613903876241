import React from "react";
import { styled } from "@mui/material/styles";

const shouldForwardProp = (prop) => prop !== "weight";

export const Container = styled("div")({
  display: "flex",
  flexDirection: "row", // Default direction is row
  marginInline: "auto",
  width: "50%",
  flexDirection: "column",
  "@media (max-width: 1200px)": {
    width: "70%",
  },
  "@media (max-width: 900px)": {
    flexDirection: "column",
    width: "90%",
  },
});

export const Pane = styled("div", { shouldForwardProp })((props) => ({
  flex: props.weight ? props.weight : 1,
}));

export const SplitScreen = ({ children, leftWeight = 1, rightWeight = 1 }) => {
  const [left, right] = children;

  return (
    <Container>
      <Pane weight={leftWeight}>{left}</Pane>
      <Pane weight={rightWeight}>{right}</Pane>
    </Container>
  );
};
