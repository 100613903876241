import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles"; // Import styled from Material-UI
import { Link } from "react-router-dom"; // Assuming you're using React Router for navigation
import theme from "../Theme";
import Grid from "@mui/material/Grid";

const HeaderComponentCityOfLA = () => {
  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: theme.palette.cityLA.primary,
        marginBottom: "1rem",
      }}
    >
      <Toolbar>
        <Grid container alignItems="center">
          <Grid item md={12} lg={4}>
            <>
              <StyledTypography variant="h6">
                {" "}
                LAHSA | Los Angeles Homeless Services Authority
              </StyledTypography>
            </>
          </Grid>
          <Grid item md={12} lg={8}>
            <Navigation>
              <Link to="/">Home</Link>
              <Link to="/about">About</Link>
              <Link to="/ces">CES</Link>
              <Link to="/get-help">Get Help</Link>
              <Link to="/get-involved">Get Involved</Link>
              <Link to="/providers">Providers</Link>
              <Link to="/news">News</Link>
              <Link to="/documents">Documents</Link>
              <Link to="/calendar">Calendar</Link>
              <Link to="/data">Data</Link>
              <Link to="/jobs">Jobs</Link>
              <Link to="/accessibility">Accessibility</Link>
              <Link to="/tools">Tools</Link>
            </Navigation>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

// Define Logo component using styled
const StyledTypography = styled(Typography)({
  flexGrow: 1,
  display: "flex",
  justifyContent: "flex-start",
  flex: 0.1,
});
// Define Navigation component using styled
// Define Navigation component using styled
const Navigation = styled(Typography)({
  flexGrow: 1,
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center", // Align items vertically
  flexWrap: "wrap", // Allow items to wrap to the next line
  "& a": {
    color: "#fff", // White text color
    margin: "8px", // Add margin around each link
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  // Media query for smaller screens
  "@media (max-width: 600px)": {
    justifyContent: "center", // Center items horizontally
    "& a": {
      margin: "4px", // Reduce margin for smaller screens
    },
  },
});

export default HeaderComponentCityOfLA;
