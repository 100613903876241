// export const nationalities = [
//   "",
//   "Afghan",
//   "Albanian",
//   "Algerian",
//   "American",
//   "Andorran",
//   "Angolan",
//   "Argentine",
//   "Armenian",
//   "Australian",
//   "Austrian",
//   "Azerbaijani",
//   "Bahamian",
//   "Bahraini",
//   "Bangladeshi",
//   "Barbadian",
//   "Belarusian",
//   "Belgian",
//   "Belizean",
//   "Beninese",
//   "Bhutanese",
//   "Bolivian",
//   "Bosnian",
//   "Brazilian",
//   "British",
//   "Bruneian",
//   "Bulgarian",
//   "Burkinabe",
//   "Burundian",
//   "Cambodian",
//   "Cameroonian",
//   "Canadian",
//   "Cape Verdean",
//   "Central African",
//   "Chadian",
//   "Chilean",
//   "Chinese",
//   "Colombian",
//   "Comoran",
//   "Congolese (Democratic Republic of the Congo)",
//   "Congolese (Republic of the Congo)",
//   "Costa Rican",
//   "Croatian",
//   "Cuban",
//   "Cypriot",
//   "Czech",
//   "Danish",
//   "Djiboutian",
//   "Dominican (Dominican Republic)",
//   "Dutch",
//   "East Timorese",
//   "Ecuadorian",
//   "Egyptian",
//   "Emirati",
//   "Equatorial Guinean",
//   "Eritrean",
//   "Estonian",
//   "Ethiopian",
//   "Fijian",
//   "Filipino",
//   "Finnish",
//   "French",
//   "Gabonese",
//   "Gambian",
//   "Georgian",
//   "German",
//   "Ghanaian",
//   "Greek",
//   "Grenadian",
//   "Guatemalan",
//   "Guinean",
//   "Guyanese",
//   "Haitian",
//   "Honduran",
//   "Hungarian",
//   "Icelandic",
//   "Indian",
//   "Indonesian",
//   "Iranian",
//   "Iraqi",
//   "Irish",
//   "Israeli",
//   "Italian",
//   "Ivorian",
//   "Jamaican",
//   "Japanese",
//   "Jordanian",
//   "Kazakhstani",
//   "Kenyan",
//   "Kiribati",
//   "Korean (North)",
//   "Korean (South)",
//   "Kosovar",
//   "Kuwaiti",
//   "Kyrgyz",
//   "Laotian",
//   "Latvian",
//   "Lebanese",
//   "Liberian",
//   "Libyan",
//   "Liechtensteiner",
//   "Lithuanian",
//   "Luxembourgish",
//   "Macedonian",
//   "Malagasy",
//   "Malawian",
//   "Malaysian",
//   "Maldivian",
//   "Malian",
//   "Maltese",
//   "Marshallese",
//   "Mauritanian",
//   "Mauritian",
//   "Mexican",
//   "Micronesian",
//   "Moldovan",
//   "Monegasque",
//   "Mongolian",
//   "Montenegrin",
//   "Moroccan",
//   "Mozambican",
//   "Namibian",
//   "Nauruan",
//   "Nepalese",
//   "New Zealander",
//   "Nicaraguan",
//   "Nigerien",
//   "Nigerian",
//   "Niuean",
//   "Norwegian",
//   "Omani",
//   "Pakistani",
//   "Palauan",
//   "Palestinian",
//   "Panamanian",
//   "Papua New Guinean",
//   "Paraguayan",
//   "Peruvian",
//   "Polish",
//   "Portuguese",
//   "Qatari",
//   "Romanian",
//   "Russian",
//   "Rwandan",
//   "Saint Kitts and Nevis",
//   "Saint Lucian",
//   "Saint Vincent and the Grenadines",
//   "Samoan",
//   "San Marinese",
//   "Sao Tomean",
//   "Saudi Arabian",
//   "Senegalese",
//   "Serbian",
//   "Seychellois",
//   "Sierra Leonean",
//   "Singaporean",
//   "Slovak",
//   "Slovenian",
//   "Solomon Islander",
//   "Somali",
//   "South African",
//   "South Sudanese",
//   "Spanish",
//   "Sri Lankan",
//   "Sudanese",
//   "Surinamese",
//   "Swazi",
//   "Swedish",
//   "Swiss",
//   "Syrian",
//   "Taiwanese",
//   "Tajik",
//   "Tanzanian",
//   "Thai",
//   "Togolese",
//   "Tongan",
//   "Trinidadian or Tobagonian",
//   "Tunisian",
//   "Turkish",
//   "Turkmen",
//   "Tuvaluan",
//   "Ugandan",
//   "Ukrainian",
//   "Uruguayan",
//   "Uzbek",
//   "Vanuatuan",
//   "Vatican (Holy See)",
//   "Venezuelan",
//   "Vietnamese",
//   "Yemeni",
//   "Zambian",
//   "Zimbabwean",
// ];

export const countries = [
  "",
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Brazil",
  "United Kingdom",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Democratic Republic of the Congo",
  "Republic of the Congo",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominican Republic",
  "Netherlands",
  "East Timor",
  "Ecuador",
  "Egypt",
  "United Arab Emirates",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "North Korea",
  "South Korea",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Nauru",
  "Nepal",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Eswatini",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];
