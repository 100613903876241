import { styled } from "@mui/material";
import theme from "../../Theme";
import { Step } from "@mui/material";

export const ContainerDiv = styled("div")({
  marginTop: "7rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
});
export const StepperDiv = styled("div")({
  // display: "grid",
  // justifyContent: "center",
  // alignItems: "center",
  // width: "30rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "30%",

  [theme.breakpoints.down("md")]: {
    width: "50%",
  },
});
export const ContentDiv = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "1rem",
  width: "100%",

  [theme.breakpoints.down("sm")]: {
    marginBottom: "0",
    // marginTop: "-1rem",
  },
});

export const HiddenLabelStep = styled(Step)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    "& .MuiStepLabel-label": {
      display: "none", // Hide the step label in mobile mode
    },
  },
}));
