import React from "react";
import {
  Container,
  FormControl,
  InputLabel,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  Grid,
  FormHelperText,
  ThemeProvider,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  country,
  states,
  stateAbbreviations,
  organizationType,
  interestArea,
  legalStatus,
  subjectArea,
  populationServed,
  NTEE,
} from "../DropDown";
import theme from "../../Theme";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import UploadDocument from "../UploadDocument";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function RightRegistrationFormComponent({
  header,
  state,
  errors,
  handleChange,
  handleMultiSelectChange,
  checkEmailExists,
  checkEINExists,
  emailExists,
  einExists,
  uploadedFiles,
  setUploadedFiles,
  fileError,
  setFileError,
  handleSubmit,
  shortForm,
}) {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Typography variant="h3" gutterBottom>
          {header}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Already have an account?
          <Link to={window.loginLink} target="_blank">
            Login
          </Link>
        </Typography>
        <FormControl fullWidth margin="normal">
          <TextField
            multiline
            required
            type="email"
            name="email"
            value={state.email}
            onChange={handleChange}
            variant="standard"
            label="Email"
            onBlur={checkEmailExists}
            error={errors.email || emailExists}
            helperText={
              errors.email ? errors.email : emailExists ? "Email exists" : ""
            }
            // InputLabelProps={{ shrink: true }}
          />
        </FormControl>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <TextField
                multiline={state.showPassword ? true : false}
                required
                type={state.showPassword ? "text" : "password"}
                name="password"
                value={state.password}
                onChange={handleChange}
                variant="standard"
                label="Password"
                error={errors.password ? true : false}
                helperText={errors.password}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <TextField
                multiline={state.showPassword ? true : false}
                required
                type={state.showPassword ? "text" : "password"}
                name="confirmPassword"
                value={state.confirmPassword}
                onChange={handleChange}
                variant="standard"
                label="Confirm Password"
                error={errors.confirmPassword ? true : false}
                helperText={errors.confirmPassword}
              />
            </FormControl>
          </Grid>
        </Grid>
        <FormControlLabel
          control={
            <Checkbox
              name="showPassword"
              checked={state.showPassword}
              onChange={handleChange}
            />
          }
          label="Show password"
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                required
                name="firstName"
                value={state.firstName}
                onChange={handleChange}
                variant="standard"
                label="First Name"
                error={errors.firstName ? true : false}
                helperText={errors.firstName}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                required
                name="lastName"
                value={state.lastName}
                onChange={handleChange}
                variant="standard"
                label="Last Name"
                error={errors.lastName ? true : false}
                helperText={errors.lastName}
              />
            </FormControl>
          </Grid>
        </Grid>
        <FormControl fullWidth margin="normal">
          <TextField
            multiline
            required
            name="title"
            value={state.title}
            onChange={handleChange}
            variant="standard"
            label="Title"
            error={errors.title ? true : false}
            helperText={errors.title}
          />
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              name="isIndividual"
              checked={state.isIndividual}
              onChange={handleChange}
            />
          }
          label="I'm registering as an individual"
        />
        {!state.isIndividual && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <TextField
                  multiline
                  required
                  name="organizationName"
                  value={state.organizationName}
                  onChange={handleChange}
                  variant="standard"
                  label="Organization Name"
                  error={errors.organizationName ? true : false}
                  helperText={errors.organizationName}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <TextField
                  multiline
                  required
                  name="ein"
                  value={state.ein}
                  onChange={handleChange}
                  variant="standard"
                  label="EIN"
                  onBlur={checkEINExists}
                  error={errors.ein || einExists}
                  helperText={
                    errors.ein
                      ? errors.ein
                      : einExists
                      ? "EIN exists"
                      : "9 digit number with a dash"
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        )}
        {shortForm ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <TextField
                  multiline
                  name="city"
                  value={state.city}
                  onChange={handleChange}
                  variant="standard"
                  label="City"
                  error={errors.city ? true : false}
                  helperText={errors.city}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <TextField
                  multiline
                  name="webPage"
                  value={state.webPage}
                  onChange={handleChange}
                  variant="standard"
                  label="WebPage"
                  error={errors.webPage ? true : false}
                  helperText={errors.webPage}
                />
              </FormControl>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl required fullWidth margin="normal">
                  <InputLabel>Interest area</InputLabel>
                  <Select
                    name="interestArea"
                    value={state.interestArea}
                    onChange={handleChange}
                    // variant="standard"
                    label="Interest area"
                    error={errors.interestArea ? true : false}
                    helperText={errors.interestArea}
                    MenuProps={MenuProps}
                  >
                    <MenuItem disabled>Select</MenuItem>
                    {interestArea.map((area) => (
                      <MenuItem
                        key={area}
                        value={area}
                        style={{ textWrap: "wrap" }}
                      >
                        {area}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl required fullWidth margin="normal">
                  <InputLabel>Organization type</InputLabel>
                  <Select
                    name="organizationType"
                    value={state.organizationType}
                    onChange={handleChange}
                    // variant="standard"
                    label="Organization type"
                    error={errors.organizationType ? true : false}
                    helperText={errors.organizationType}
                    MenuProps={MenuProps}
                  >
                    <MenuItem disabled>Select</MenuItem>
                    {organizationType.map((type) => (
                      <MenuItem
                        key={type}
                        value={type}
                        style={{ textWrap: "wrap" }}
                      >
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={state.country === "United States" ? 6 : 12}
              >
                <FormControl required fullWidth margin="normal">
                  <InputLabel>Country</InputLabel>
                  <Select
                    name="country"
                    value={state.country}
                    onChange={handleChange}
                    // variant="standard"
                    label="Country"
                    error={errors.country ? true : false}
                    helperText={errors.country}
                    MenuProps={MenuProps}
                  >
                    <MenuItem disabled>Select</MenuItem>
                    {country.map((type) => (
                      <MenuItem
                        key={type}
                        value={type}
                        style={{ textWrap: "wrap" }}
                      >
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {state.country === "United States" && (
                <Grid item xs={12} sm={6}>
                  <FormControl required fullWidth margin="normal">
                    <InputLabel>State/Province</InputLabel>
                    <Select
                      name="state"
                      value={state.state}
                      onChange={handleChange}
                      // variant="standard"
                      label="State/Province"
                      error={errors.state ? true : false}
                      helperText={errors.state}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="SelectState" disabled>
                        Select State
                      </MenuItem>
                      {states.map((state, index) => (
                        <MenuItem
                          key={state}
                          value={stateAbbreviations[index]}
                          // value={state}
                          style={{ textWrap: "wrap" }}
                        >
                          {state}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>

            <FormControl required fullWidth margin="normal">
              <TextField
                multiline
                name="address"
                value={state.address}
                onChange={handleChange}
                variant="standard"
                label="Address Line 1"
                error={errors.address ? true : false}
                helperText={errors.address}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="addressComp"
                value={state.addressComp}
                onChange={handleChange}
                variant="standard"
                label="Address Line 2"
                error={errors.addressComp ? true : false}
                helperText={errors.addressComp}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="city"
                value={state.city}
                onChange={handleChange}
                variant="standard"
                label="City"
                error={errors.city ? true : false}
                helperText={errors.city}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="zipCode"
                value={state.zipCode}
                onChange={handleChange}
                variant="standard"
                label="ZipCode"
                error={errors.zipCode ? true : false}
                helperText={errors.zipCode}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="yearEstablished"
                value={state.yearEstablished}
                onChange={handleChange}
                variant="standard"
                label="Year Established"
                error={errors.yearEstablished ? true : false}
                helperText={errors.yearEstablished}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="percentageOfBudgetForServices"
                value={state.percentageOfBudgetForServices}
                onChange={handleChange}
                variant="standard"
                label="Percentage of Budget for Services"
                error={errors.percentageOfBudgetForServices ? true : false}
                helperText={errors.percentageOfBudgetForServices}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="missionStatement"
                value={state.missionStatement}
                onChange={handleChange}
                variant="standard"
                label="Mission Statement"
                error={errors.missionStatement ? true : false}
                helperText={errors.missionStatement}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="visionStatement"
                value={state.visionStatement}
                onChange={handleChange}
                variant="standard"
                label="Vision Statement"
                error={errors.visionStatement ? true : false}
                helperText={errors.visionStatement}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="organizationFocus"
                value={state.organizationFocus}
                onChange={handleChange}
                variant="standard"
                label="Organization Focus"
                error={errors.organizationFocus ? true : false}
                helperText={errors.organizationFocus}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="purposeOfOrganization"
                value={state.purposeOfOrganization}
                onChange={handleChange}
                variant="standard"
                label="Purpose of Organization"
                error={errors.purposeOfOrganization ? true : false}
                helperText={errors.purposeOfOrganization}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="strategicGoals"
                value={state.strategicGoals}
                onChange={handleChange}
                variant="standard"
                label="Strategic Goals"
                error={errors.strategicGoals ? true : false}
                helperText={errors.strategicGoals}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="accreditations"
                value={state.accreditations}
                onChange={handleChange}
                variant="standard"
                label="Accreditations"
                error={errors.accreditations ? true : false}
                helperText={errors.accreditations}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="targetPopulations"
                value={state.targetPopulations}
                onChange={handleChange}
                variant="standard"
                label="Target Populations"
                error={errors.targetPopulations ? true : false}
                helperText={errors.targetPopulations}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="servicesProvided"
                value={state.servicesProvided}
                onChange={handleChange}
                variant="standard"
                label="Services Provided"
                error={errors.servicesProvided ? true : false}
                helperText={errors.servicesProvided}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="geographicCoverage"
                value={state.geographicCoverage}
                onChange={handleChange}
                variant="standard"
                label="Geographic Coverage"
                error={errors.geographicCoverage ? true : false}
                helperText={errors.geographicCoverage}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="ageGroupsServed"
                value={state.ageGroupsServed}
                onChange={handleChange}
                variant="standard"
                label="Age Groups Served"
                error={errors.ageGroupsServed ? true : false}
                helperText={errors.ageGroupsServed}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="incomeLevelServed"
                value={state.incomeLevelServed}
                onChange={handleChange}
                variant="standard"
                label="Income Level Served"
                error={errors.incomeLevelServed ? true : false}
                helperText={errors.incomeLevelServed}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="householdServed"
                value={state.householdServed}
                onChange={handleChange}
                variant="standard"
                label="Household Served"
                error={errors.householdServed ? true : false}
                helperText={errors.householdServed}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="challenges"
                value={state.challenges}
                onChange={handleChange}
                variant="standard"
                label="Challenges"
                error={errors.challenges ? true : false}
                helperText={errors.challenges}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="annualBudget"
                value={state.annualBudget}
                onChange={handleChange}
                variant="standard"
                label="Annual Budget"
                error={errors.annualBudget ? true : false}
                helperText={errors.annualBudget}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="grossReceipts"
                value={state.grossReceipts}
                onChange={handleChange}
                variant="standard"
                label="Gross Receipts"
                error={errors.grossReceipts ? true : false}
                helperText={errors.grossReceipts}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="keyFundingSources"
                value={state.keyFundingSources}
                onChange={handleChange}
                variant="standard"
                label="Key Funding Sources"
                error={errors.keyFundingSources ? true : false}
                helperText={errors.keyFundingSources}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="additionalInformation"
                value={state.additionalInformation}
                onChange={handleChange}
                variant="standard"
                label="Additional Information"
                error={errors.additionalInformation ? true : false}
                helperText={errors.additionalInformation}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="congressionalDistrict"
                value={state.congressionalDistrict}
                onChange={handleChange}
                variant="standard"
                label="Congressional District"
                error={errors.congressionalDistrict ? true : false}
                helperText={errors.congressionalDistrict}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="stateSenateDistrict"
                value={state.stateSenateDistrict}
                onChange={handleChange}
                variant="standard"
                label="State Senate District"
                error={errors.stateSenateDistrict ? true : false}
                helperText={errors.stateSenateDistrict}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="linkedin"
                value={state.linkedin}
                onChange={handleChange}
                variant="standard"
                label="LinkedIn"
                error={errors.linkedin ? true : false}
                helperText={errors.linkedin}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="facebook"
                value={state.facebook}
                onChange={handleChange}
                variant="standard"
                label="Facebook"
                error={errors.facebook ? true : false}
                helperText={errors.facebook}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="instagram"
                value={state.instagram}
                onChange={handleChange}
                variant="standard"
                label="Instagram"
                error={errors.instagram ? true : false}
                helperText={errors.instagram}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                multiline
                name="webPage"
                value={state.webPage}
                onChange={handleChange}
                variant="standard"
                label="WebPage"
                error={errors.webPage ? true : false}
                helperText={errors.webPage}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel>Legal Status</InputLabel>
              <Select
                name="legalStatus"
                value={state.legalStatus}
                onChange={handleChange}
                // variant="standard"
                label="Legal Status"
                error={errors.legalStatus ? true : false}
                helperText={errors.legalStatus}
                MenuProps={MenuProps}
              >
                <MenuItem disabled>Select</MenuItem>
                {legalStatus.map((status) => (
                  <MenuItem
                    key={status}
                    value={status}
                    style={{ textWrap: "wrap" }}
                  >
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel>Subject area</InputLabel>
              <Select
                name="subjectArea"
                label="Subject Area"
                multiple
                value={state.subjectArea}
                onChange={handleMultiSelectChange}
                input={<OutlinedInput label="Subject area" />}
                renderValue={(selected) => selected.join(", ")}
                error={errors.subjectArea ? true : false}
                helperText={errors.subjectArea}
                MenuProps={MenuProps}
              >
                {subjectArea.map((area) => (
                  <MenuItem key={area} value={area}>
                    <Checkbox checked={state.subjectArea.indexOf(area) > -1} />
                    <ListItemText primary={area} style={{ textWrap: "wrap" }} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel>Population served</InputLabel>
              <Select
                name="populationServed"
                label="Population served"
                multiple
                value={state.populationServed}
                onChange={handleMultiSelectChange}
                input={<OutlinedInput label="Population served" />}
                renderValue={(selected) => selected.join(", ")}
                error={errors.populationServed ? true : false}
                helperText={errors.populationServed}
                MenuProps={MenuProps}
              >
                {populationServed.map((population) => (
                  <MenuItem key={population} value={population}>
                    <Checkbox
                      checked={state.populationServed.indexOf(population) > -1}
                    />
                    <ListItemText
                      primary={population}
                      style={{ textWrap: "wrap" }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>NTEE Code</InputLabel>
              <Select
                name="NTEE"
                label="NTEE Code"
                multiple
                value={state.NTEE}
                onChange={handleMultiSelectChange}
                input={<OutlinedInput label="NTEE Code" />}
                renderValue={(selected) => selected.join(", ")}
                error={errors.NTEE ? true : false}
                helperText={errors.NTEE}
                MenuProps={MenuProps}
              >
                {NTEE.map((code) => (
                  <MenuItem key={code} value={code}>
                    <Checkbox checked={state.NTEE.indexOf(code) > -1} />
                    <ListItemText primary={code} style={{ textWrap: "wrap" }} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  name="agreeTerms"
                  checked={state.agreeTerms}
                  onChange={handleChange}
                />
              }
              label={
                <>
                  I agree to these{" "}
                  <Link
                    to="/Terms"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "blue",
                      borderBottom: "1px solid transparent",
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.borderBottom = "1px solid blue")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.borderBottom = "1px solid transparent")
                    }
                  >
                    Terms and Conditions
                  </Link>
                </>
              }
            />
            <FormHelperText style={{ color: "red" }}>
              {errors.agreeTerms}
            </FormHelperText>

            <FormControlLabel
              control={
                <Checkbox
                  name="receiveUpdates"
                  checked={state.receiveUpdates}
                  onChange={handleChange}
                />
              }
              label="I would like to receive periodic news and product updates"
            />
          </>
        )}

        {window.withUploadDocument && (
          <UploadDocument
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            fileError={fileError}
            setFileError={setFileError}
          />
        )}
        <Button
          variant="contained"
          fullWidth
          style={{ backgroundColor: theme.palette.grants.secondary }}
          onClick={handleSubmit}
        >
          Sign up for free
        </Button>
      </Container>
    </ThemeProvider>
  );
}

export default RightRegistrationFormComponent;
